import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

export default function useMatchURLParams() {
  const match = useRouteMatch();

  return useMemo(() => {
    return match.params;
  }, [match]);
}
