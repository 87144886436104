import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { api as apiSlice } from './api';
import requestToBook from './view/requestToBook.slice';

export const rootReducer = combineReducers({
  requestToBook,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([apiSlice.middleware]),
    devTools: process.env.NODE_ENV !== 'production' && {
      name: 'booking-request',
    },
  });
};

export default setupStore();
