import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    propertyNameOrAddress: '',
    accountBrandIds: [],
    status: undefined,
    skip: 0,
    sort: 'nickname',
  },
};

export const listingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    setPropertyNameOrAddressFilter: (state, action) => {
      state.filters.propertyNameOrAddress = action.payload;
    },
    setAccountBrandIdsFilter: (state, action) => {
      state.filters.accountBrandIds = action.payload;
    },
    setStatusFilter: (state, action) => {
      state.filters.status = action.payload;
    },
    setSkip: (state, action) => {
      state.filters.skip = action.payload;
    },
  },
});

export const {
  setPropertyNameOrAddressFilter,
  setAccountBrandIdsFilter,
  setStatusFilter,
  setSkip,
} = listingsSlice.actions;

export default listingsSlice.reducer;
