import http from 'axios';

import localStorage from 'utils/localStorage';

const baseUrl = `${
  process.env.REACT_APP_BASE_ORIGIN || '//localhost:3000'
}/api`;

const getRequestHeaders = () => ({
  Authorization: `Bearer ${localStorage.getAuthToken()}`,
  'g-aid-cs': 'G-89C7E-9FB65-B6F69',
});

const methods = {
  GET: http.get,
  POST: http.post,
  PUT: http.put,
};

// for any new functionality use agni, request() below is obsolete
const request = (method, url, params = {}, data = {}) => {
  if (['POST', 'PUT'].includes(method)) {
    return methods[method](
      `${baseUrl}${url}`,
      { ...data, apiKey: process.env.REACT_APP_API_KEY },
      { params, headers: getRequestHeaders() }
    ).then((response) => response.data);
  }

  return methods[method](`${baseUrl}${url}`, {
    params,
    headers: getRequestHeaders(),
  }).then((response) => response.data);
};

export const get = (url, params = {}, data = {}) =>
  request('GET', url, params, data);

export const post = (url, data = {}) => request('POST', url, {}, data);

export const put = (url, data = {}) => request('PUT', url, {}, data);
