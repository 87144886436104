import { createSelector } from 'reselect';
import get from 'lodash/get';

// xs 0   - 575
// sm 576 - 767
// md 768 - 991
// lg 992 - 1199
// xl 1200+
export const isSmallScreen = (state) => state.ui.winWidth < 576;

export const isMediumScreen = (state) => state.ui.winWidth < 768;

export const getHasNavbar = (state) => state.ui.hasNavbar;

export const getOwnersPortalMeta = (state) => state.ui.meta;

export const getOwnersPortalShowReservationTooltipFullName = createSelector(
  [getOwnersPortalMeta],
  (meta) => get(meta, ['showReservationTooltipFullName'], true)
);

export const getPoweredByVisibility = (state) =>
  state.ui.meta?.whiteLabel?.active
    ? !state.ui.meta.whiteLabel.poweredByGuesty
    : true;

export const getCompanyLogo = (state) =>
  state.ui.meta && state.ui.meta.accountId.companyLogo;
