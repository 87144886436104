import { connect } from 'react-redux';

import LoginButton from 'components/login/LoginButton';

function mapStateToProps(state) {
  return {
    logging: state.user.authenticated,
  };
}

export default connect(mapStateToProps)(LoginButton);
