import { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import Avatar from '@guestyci/foundation/Avatar';
import Button from '@guestyci/foundation/Button';
import Popover from '@guestyci/foundation/Popover';
import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import { nameToInitials } from '@guestyci/foundation/utils/commonUtility';
import navigationUtils from '@guestyci/history/utils';
import t from '@guestyci/localize/t.macro';

import { useAppSelector, useAppDispatch } from 'store';
import { logout } from 'user/user.actions';
import { resetAll } from 'property/properties.actions';

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.white,
    color: theme.palette.text.default,
    padding: 0,
    boxShadow: '0px 10px 30px rgba(20, 73, 255, 0.15)',
  },
  list: {
    width: 250,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 4,
    backgroundColor: theme.palette.background.white,
    color: theme.palette.text.primary,
  },
  avatar: {
    height: 34,
    width: 34,
    objectFit: 'cover',
    borderRadius: '50%',
    background: '#E3EAFF',
  },
  big: {
    width: 52,
    height: 52,
  },
  listItem: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  link: {
    color: theme.palette.text.primary,

    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  },
  divider: {
    width: '100%',
    height: 1,
    background: '#E3EAFF',
  },
  initials: {
    color: theme.palette.text.primary,
  },
  width100: {
    width: '100%',
    justifyContent: 'start',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const AvatarWithInitials = ({ avatar, avatarClassName, initials, setOpen }) => {
  const classes = useStyles();

  return (
    <Avatar
      className={avatarClassName}
      size="large"
      src={avatar}
      alt={t('Profile picture')}
      onClick={() => setOpen(true)}
    >
      <TextField bold className={classes.initials}>
        {initials}
      </TextField>
    </Avatar>
  );
};

const NavBarAvatar = ({ accountBrand }) => {
  const classes = useStyles();
  const appDispatch = useAppDispatch();

  const userName = useAppSelector((state) => state.user.fullName);
  const avatar = useAppSelector((state) => state.user.picture?.thumbnail);

  const [open, setOpen] = useState(false);

  const initials = useMemo(
    () => (userName ? nameToInitials(userName) : '-'),
    [userName]
  );

  const handleLogout = () => {
    appDispatch(logout())
      .then(() => appDispatch(resetAll()))
      .then(() => appDispatch(() => navigationUtils.push({ pathname: '/' })));

    setOpen(false);
  };

  return (
    <Popover
      offset={13}
      open={open}
      placement="bottom"
      fullWidth
      onClose={() => setOpen(false)}
      className={classes.root}
      body={
        <Col className={classes.list} spacing={2}>
          <Row spacing={2} align="center" justify="start">
            <AvatarWithInitials
              avatar={avatar}
              avatarClassName={cn([classes.avatar, classes.big])}
              initials={initials}
              setOpen={setOpen}
            />
            <Col justify="center" align="start">
              <TextField bold>{accountBrand.name || '-'}</TextField>
              <TextField>{userName || '-'}</TextField>
            </Col>
          </Row>

          <div className={classes.divider} />

          <Col spacing={2} className={classes.listItem}>
            <NavLink
              to="/my-profile"
              onClick={() => setOpen(false)}
              className={cn([classes.link, classes.width100])}
            >
              {t('My profile')}
            </NavLink>
          </Col>

          <div className={classes.divider} />

          <Col spacing={2} className={classes.listItem}>
            <Button className={classes.width100} onClick={handleLogout}>
              {t('Logout')}
            </Button>
          </Col>
        </Col>
      }
    >
      <AvatarWithInitials
        avatar={avatar}
        avatarClassName={cn([classes.avatar, classes.pointer])}
        initials={initials}
        setOpen={setOpen}
      />
    </Popover>
  );
};

export default NavBarAvatar;
