import cn from 'classnames';

import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';
import Icon from 'components/common/Icon';

const ButtonWithIcon = (props) => {
  const { className, spinner, children, icon } = props;
  const iconContainer = spinner ? (
    <div className="button-with-icon-icon">
      <Spinner size={16} color="#fff" />
    </div>
  ) : icon ? (
    <Icon className="button-with-icon-icon" icon={icon} />
  ) : null;

  return (
    <Button {...props} className={cn(className, 'button-with-icon')}>
      {iconContainer}
      {children}
    </Button>
  );
};

export default ButtonWithIcon;
