import React from 'react';

import t from '@guestyci/localize/t.macro';

import LoginLayout from 'components/login/LoginLayout';
import PasswordPair from 'components/common/PasswordPair';
import LoginButton from 'containers/LoginButton.container';

export default class NewPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: false,
      newPassword: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(valid, newPassword) {
    this.setState({ valid, newPassword });
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onResetPassword(
      this.props.hash,
      this.props.email,
      this.state.newPassword
    );
  }

  render() {
    const { logo } = this.props;
    return (
      <LoginLayout
        logo={logo}
        title={t("Let's set a new password")}
        subtitle={t('Minimum 6 characters, no spaces')}
      >
        <form onSubmit={this.onSubmit}>
          <PasswordPair
            password={{ id: 'pass-1', label: t('Password') }}
            retypePassword={{
              id: 'pass-2',
              label: t('Retype password'),
            }}
            onChange={this.onChange}
          />
          <LoginButton
            isDisabled={!this.state.valid}
            text={t('Change password')}
          />
        </form>
      </LoginLayout>
    );
  }
}
