import dio, { DioTypes } from 'utils/dio';

export default {
  onPageView: (pathname, search, hash) => {
    switch (pathname.toLowerCase()) {
      case '/my-properties':
        return dio?.track('my_properties_pageview', DioTypes.PAGEVIEW, {
          pathname,
          search,
          hash,
        });
      case '/analytics':
        return dio?.track('reports_pageview', DioTypes.PAGEVIEW, {
          pathname,
          search,
          hash,
        });
      case '/statements':
        return dio?.track('statements_pageview', DioTypes.PAGEVIEW, {
          pathname,
          search,
          hash,
        });
      case '/reservation-report':
        return dio?.track('guest_reports_pageview', DioTypes.PAGEVIEW, {
          pathname,
          search,
          hash,
        });
      case '/documents':
        return dio?.track('documents_page', DioTypes.PAGEVIEW, {
          pathname,
          search,
          hash,
        });
      default:
        break;
    }
  },
};
