import React from 'react';
import ClickOutside from 'react-click-outside';
import cn from 'classnames';

import Icon from '@guestyci/foundation/Icon';
import { ReactComponent as BtnRight } from '@guestyci/icons/BtnRight.svg';
import { ReactComponent as BtnLeft } from '@guestyci/icons/BtnLeft.svg';

import Button from 'components/common/Button';
import MonthPicker from 'components/MonthPicker';
import CalendarLegend from 'components/CalendarLegend';
import CalendarStats from 'containers/CalendarStats.container';
import { addMonth } from 'utils/calendar';

const CalendarHeader = ({
  listing,
  initialMonth,
  onMonthChange,
  loadingCalendar,
  fetchingMiniData,
  showReservedReservations,
}) =>
  class _Navbar extends React.Component {
    constructor() {
      super();
      this.state = {
        open: false,
      };
    }

    onClick = () => {
      this.setState(({ open }) => ({ open: !open }));
    };

    onHide = () => {
      this.setState({
        open: false,
      });
    };

    onChange = (month, year) => {
      onMonthChange(new Date(year, month, 1));
      this.onHide();
    };

    render() {
      const {
        className,
        previousMonth,
        onPreviousClick,
        onNextClick,
        localeUtils,
        locale,
      } = this.props;
      const curr = localeUtils.formatMonthTitle(
        addMonth(previousMonth),
        locale
      );

      // wasnt able to made a functional component from this class
      // not sure where onNextClick and onPreviousClick are defined
      const _onNextClick = () => onNextClick();
      const _onPreviousClick = () => onPreviousClick();

      const shouldShowSpinner = loadingCalendar ? false : fetchingMiniData;

      return (
        <div>
          <div
            className={cn(
              className,
              'd-flex',
              'justify-content-between',
              'align-items-center',
              'mb-lg-5',
              'flex-sm-row',
              'flex-column'
            )}
          >
            <div>
              <Button
                btnStyle="transparent"
                shape="circle"
                onClick={_onPreviousClick}
              >
                <Icon svg={BtnLeft} />
              </Button>
              <span
                aria-hidden="true"
                className="font-size-h4 font-color-primary align-middle d-inline-block text-center curr-month-title"
                onClick={this.onClick}
              >
                {curr}
                {this.state.open && (
                  <ClickOutside onClickOutside={this.onHide}>
                    <MonthPicker
                      month={initialMonth}
                      onChange={this.onChange}
                    />
                  </ClickOutside>
                )}
              </span>
              <Button
                btnStyle="transparent"
                shape="circle"
                onClick={_onNextClick}
              >
                <Icon svg={BtnRight} />
              </Button>
            </div>
            <CalendarStats listing={listing} loading={shouldShowSpinner} />
          </div>
          <div className={cn('mb-lg-3')}>
            <CalendarLegend
              showReservedReservations={showReservedReservations}
            />
          </div>
        </div>
      );
    }
  };

export default CalendarHeader;
