import { connect } from 'react-redux';

import { hideModal } from 'modal/redux/modal.actions';
import RootModal from 'modal/components/RootModal';

const mapDispatchToProps = (dispatch) => ({
  close(onClose) {
    dispatch(hideModal());
    if (typeof onClose === 'function') onClose();
  },
});

const mapStateToProps = (state) => ({
  modalType: state.modal.present.modalType,
  modalProps: state.modal.present.modalProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(RootModal);
