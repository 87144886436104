const AUTH_TOKEN_STORAGE_KEY = 'token';
const OWNER_LANGUAGE_STORAGE_KEY = 'lang';

const isJSON = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

const dispatchStorageEvent = (key, oldValue, newValue) => {
  window.dispatchEvent(
    new StorageEvent('storage', {
      storageArea: window.localStorage,
      key,
      oldValue,
      newValue,
      url: window.location.href,
    })
  );
};

const getItem = (key) => {
  const item = window.localStorage.getItem(key);

  if (item) {
    return isJSON(item) ? JSON.parse(item) : item;
  }

  return undefined;
};

const setItem = (key, value, asJSON = true) => {
  const oldValue = getItem(key);
  const newValue = asJSON ? JSON.stringify(value) : value;

  window.localStorage.setItem(key, newValue);
  dispatchStorageEvent(key, oldValue, newValue);
};

const removeItem = (key) => {
  const oldValue = getItem(key);
  const newValue = null;

  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, oldValue, newValue);
};

const localStorage = {
  AUTH_TOKEN_STORAGE_KEY,
  getAuthToken: () => getItem(AUTH_TOKEN_STORAGE_KEY),
  setAuthToken: (token) => setItem(AUTH_TOKEN_STORAGE_KEY, token),
  removeAuthToken: () => removeItem(AUTH_TOKEN_STORAGE_KEY),

  getLanguage: () => getItem(OWNER_LANGUAGE_STORAGE_KEY) || 'en-US',
  setLanguage: (language) =>
    setItem(OWNER_LANGUAGE_STORAGE_KEY, language, false),
  removeLanguage: () => removeItem(OWNER_LANGUAGE_STORAGE_KEY),
};

export default localStorage;
