import cn from 'classnames';

import { Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';

import PoweredBy from 'containers/PoweredBy.container';

const useStyles = createStyles(({ breakpoints: { down } }) => ({
  layout: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#f5f9ff',
  },
  header: {
    [down('sm')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  title: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  content: {
    padding: '73px 30px 0px 30px', // 58px navbar height + 15px padding
    flexGrow: 1,
    overflow: 'auto',
    [down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  contentInner: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
}));

const Header = ({ title, subTitle }) => {
  const classes = useStyles();

  return (
    <Col className={classes.header}>
      {title && (
        <TextField variant="h3" color="primary" bold className={classes.title}>
          {title}
        </TextField>
      )}

      {subTitle && (
        <TextField variant="h5" color="secondary">
          {subTitle}
        </TextField>
      )}
    </Col>
  );
};

const Layout = ({
  children,
  hasNavbar,
  title,
  subTitle,
  layoutContentClassName,
  layoutContentInnerClassName,
}) => {
  const classes = useStyles();

  return (
    <Col className={classes.layout}>
      <Col
        spacing={3}
        fullWidth
        className={cn([classes.content], {
          [layoutContentClassName]: layoutContentClassName,
        })}
      >
        {hasNavbar && <Header title={title} subTitle={subTitle} />}

        <Col
          fullWidth
          className={cn([classes.contentInner], {
            [layoutContentInnerClassName]: layoutContentInnerClassName,
          })}
        >
          {children}
          <PoweredBy hasNavbar={hasNavbar} />
        </Col>
      </Col>
    </Col>
  );
};

export default Layout;
