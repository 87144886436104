/* eslint-disable no-dupe-class-members */
import React from 'react';

import t from '@guestyci/localize/t.macro';

import LoginLayout from 'components/login/LoginLayout';
import FormField from 'components/common/FormField';
import Button from 'components/common/Button';
import Password from 'components/common/Password';
import ButtonLoading from 'components/common/ButtonLoading';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      valid: false,
      emailError: false,
      logging: false,
    };
  }

  onPasswordChange(valid) {
    this.setState({ valid });
  }

  onEmailBlur(e) {
    const valid = e.target.checkValidity();
    this.setState({
      emailError: valid ? false : 'Invalid email',
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ logging: true });

    const { username, password } = e.target.elements;
    this.props.onLogin(username.value, password.value).then(() =>
      this.setState({
        logging: false,
      })
    );
  }

  onForgotPassword() {
    this.props.changeMode('FORGOT_PASSWORD');
  }

  onPasswordChange = this.onPasswordChange.bind(this);

  onEmailBlur = this.onEmailBlur.bind(this);

  onSubmit = this.onSubmit.bind(this);

  onForgotPassword = this.onForgotPassword.bind(this);

  render() {
    const { logo, email, error } = this.props;
    const { logging, valid, emailError } = this.state;
    const disableSubmit = !valid || emailError;
    const subtitle = error
      ? t('Incorrect email/password, please try again')
      : `${t('Please enter your details')}:`;

    return (
      <LoginLayout
        logo={logo}
        title={t('Welcome back!')}
        subtitle={subtitle}
        error={error}
      >
        <form onSubmit={this.onSubmit}>
          <FormField
            id="username"
            label={t('Email')}
            inputType="email"
            value={email}
            onBlur={this.onEmailBlur}
            error={this.state.emailError}
          />
          <Password
            id="password"
            label={t('Password')}
            onChange={this.onPasswordChange}
          />
          <ButtonLoading
            size="lg"
            className="w-100 mb-15"
            isDisabled={disableSubmit}
            loading={logging}
            noCheck
          >
            {t('Log in')}
          </ButtonLoading>
        </form>
        <Button
          btnStyle="transparent"
          className="text-capitalize"
          onClick={this.onForgotPassword}
        >
          {t('Forgot password?')}
        </Button>
      </LoginLayout>
    );
  }
}

export default Login;
