import { Redirect } from 'react-router-dom';

import Login from 'containers/Login.container';

const ForgotPasswordRoute = ({ authenticated }) => {
  if (authenticated) {
    return <Redirect to="/" />;
  }
  return <Login mode="FORGOT_PASSWORD" />;
};

export default ForgotPasswordRoute;
