import navigationUtils from '@guestyci/history/utils';
import createStyles from '@guestyci/foundation/createStyles';
import { Col, Row, Section } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import t from '@guestyci/localize/t.macro';

const useStyles = createStyles((theme) => ({
  main: {
    height: '100%',
    backgroundColor: theme.palette.background.system,
  },

  row: {
    height: 'calc(100% - 15px)',
    flexGrow: 1,
    paddingTop: 100,
    paddingBottom: 100,
    background: theme.palette.background.white,
  },
}));

const Error404 = () => {
  const classes = useStyles();

  const clickHandler = () => {
    navigationUtils.replace({ pathname: '/' });
  };

  return (
    <Section gutter="0 6 12 6" className={classes.main}>
      <Row align="center" justify="center" className={classes.row}>
        <Col spacing={3}>
          <TextField variant="h4" align="center">
            {t('Page not found.')}
          </TextField>

          <RaisedButton onClick={clickHandler}>{t('Go to Home')}</RaisedButton>
        </Col>
      </Row>
    </Section>
  );
};

export default Error404;
