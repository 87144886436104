import { useState } from 'react';
import { toMomentObject } from 'react-dates';
import PropTypes from 'prop-types';
import { values, mapKeys, merge, size, omit, pick } from 'lodash';

import RaisedButton from '@guestyci/foundation/RaisedButton';
import OutlineButton from '@guestyci/foundation/OutlineButton';
import TextField from '@guestyci/foundation/TextField';
import { uuid } from '@guestyci/foundation/utils';
import StatusIndication from '@guestyci/foundation/StatusIndication';
import t from '@guestyci/localize/t.macro';

import Icon from 'components/common/Icon';
import RentalPeriodItem from 'components/RentalPeriodItem';

import Spinner from './common/Spinner';

const RentalPeriod = (props) => {
  const {
    listing,
    list,
    updateRentalPeriod,
    onShowRentalPeriodModal,
    onCancel,
  } = props;

  const [rentalPeriodsCollectionState, setRentalPeriodsCollection] = useState(
    mapKeys(list, '_id')
  );
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [updatingErrorMessageState, setUpdatingErrorMessage] = useState('');

  const add = (period) => {
    const _id = uuid();
    const newPeriod = { [_id]: { ...period, _localGenerated: true, _id } };

    setRentalPeriodsCollection(({ rentalPeriodsCollection, ...state }) => {
      const updated = merge(rentalPeriodsCollection, newPeriod);

      return {
        ...state,
        rentalPeriodsCollection: updated,
      };
    });
  };

  const remove = (id) => () => {
    setRentalPeriodsCollection(({ rentalPeriodsCollection }) => {
      const updatedCollection = omit(rentalPeriodsCollection, id);

      return {
        rentalPeriodsCollection: updatedCollection,
      };
    });
  };

  const update = (period) => {
    const updatedPeriod = { [period._id]: { ...period } };

    setRentalPeriodsCollection(({ rentalPeriodsCollection }) => {
      const updatedCollection = merge(rentalPeriodsCollection, updatedPeriod);

      return {
        rentalPeriodsCollection: updatedCollection,
      };
    });
  };

  const getDTO = () => {
    const result = {
      listingId: listing._id,
      data: {},
    };

    result.data.rentalPeriods = values(rentalPeriodsCollectionState).map(
      ({ _localGenerated, ...rentalPeriod }) =>
        _localGenerated ? omit(rentalPeriod, '_id') : rentalPeriod
    );

    if (size(list) === 0) {
      result.data.defaultAvailability = 'BLOCKED';
    }

    return result;
  };

  const rentalPeriodSaveChanges = async () => {
    setIsLoadingState(true);
    setUpdatingErrorMessage('');

    try {
      await updateRentalPeriod(getDTO());
    } catch (e) {
      setUpdatingErrorMessage(e.response.data.error.message);
    } finally {
      setIsLoadingState(false);
    }
  };

  const openCreateRentalPeriodModal = () => {
    onShowRentalPeriodModal({
      add,
      rentalPeriods: Object.values(rentalPeriodsCollectionState),
    });
  };

  const openEditRentalPeriod = (id) => () => {
    const period = pick(rentalPeriodsCollectionState, id);
    const { requestToBook, to, from, _localGenerated } = period[id];

    onShowRentalPeriodModal({
      rentalPeriodId: id,
      update,
      to,
      from,
      requestToBook,
      _localGenerated,
      rentalPeriods: Object.values(rentalPeriodsCollectionState),
    });
  };

  return (
    <div className="container-fluid px-2">
      {isLoadingState && <Spinner />}
      <div className="row">
        <div className="col-8">
          {values(rentalPeriodsCollectionState).map((period) => (
            <RentalPeriodItem
              key={period._id}
              from={toMomentObject(period.from)}
              to={toMomentObject(period.to)}
              requestToBook={period.requestToBook}
              onEdit={openEditRentalPeriod(period._id)}
              onDelete={remove(period._id)}
              disabled={isLoadingState}
            />
          ))}

          <button
            type="button"
            className="add-rental-period-button"
            onClick={openCreateRentalPeriodModal}
          >
            <Icon icon="plus-in-circle--add" />
            <span>{t('Add rental period')}</span>
          </button>
        </div>

        <div className="col-4 d-flex flex-column align-items-end">
          {updatingErrorMessageState && (
            <StatusIndication
              variant="redWarning"
              text={updatingErrorMessageState}
            />
          )}

          <div className="d-flex my-3">
            <div className="mr-3">
              <OutlineButton onClick={onCancel} disabled={isLoadingState}>
                {t('Cancel')}
              </OutlineButton>
            </div>
            <div>
              <RaisedButton
                onClick={rentalPeriodSaveChanges}
                disabled={isLoadingState}
              >
                {t('Save')}
              </RaisedButton>
            </div>
          </div>

          <div className="rental-period-information">
            <Icon icon="information-light-icon" />
            <TextField color="default">
              {t(
                'Your property manager will contact you to approve booking requests.'
              )}
            </TextField>
          </div>
        </div>
      </div>
    </div>
  );
};

RentalPeriod.propTypes = {
  onCancel: PropTypes.func.isRequired,
  listing: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  onShowRentalPeriodModal: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
      requestToBook: PropTypes.bool.isRequired,
    })
  ).isRequired,
  updateRentalPeriod: PropTypes.func.isRequired,
};

export default RentalPeriod;
