import { useSelector } from 'react-redux';

import { Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';
import createStyles from '@guestyci/foundation/createStyles';

import {
  getBookingRequestApproved,
  getBookingRequestApprovedByOwner,
  getBookingRequestApprovedByTeam,
  getBookingRequestDeclined,
  getBookingRequestPendingOwnerApproval,
} from '../view/requestToBook.slice';

const useStyles = createStyles({
  info: {
    width: '490px',
    paddingTop: '20px',
  },
});

export const RequestToBookSubHeader = ({ ownerName }) => {
  const classes = useStyles();
  const isPendingOwnerApproval = useSelector(
    getBookingRequestPendingOwnerApproval
  );
  const isDeclined = useSelector(getBookingRequestDeclined);
  const isApproved = useSelector(getBookingRequestApproved);
  const isApprovedByOwner = useSelector(getBookingRequestApprovedByOwner);
  const isApprovedByTeam = useSelector(getBookingRequestApprovedByTeam);

  const approvedReservationHeaderText = isApproved
    ? `${t('Reservation confirmed!')}`
    : '';
  const approvedReservationInfoText = isApproved
    ? t('This reservation is confirmed and has been added to your calendar.')
    : '';
  const approvedOwnerHeaderText = isApprovedByOwner
    ? t('Processing booking request...')
    : '';
  const approvedOwnerInfoText = !isApprovedByTeam
    ? t(
        'This reservation is being processed by your property manager. Once completed we will contact you with a reservation confirmation and it will be added to your calendar.'
      )
    : '';
  const approveHeaderText =
    approvedReservationHeaderText || approvedOwnerHeaderText;
  const approveInfoText = approvedReservationInfoText || approvedOwnerInfoText;
  const decline = isDeclined ? `${t('Reservation declined')}:` : '';
  const pendingHeaderText = isPendingOwnerApproval
    ? `${t('Hi')} ${ownerName}`
    : '';
  const pendingInfoText = isPendingOwnerApproval
    ? t('This booking request needs your approval')
    : '';

  return (
    <Col spacing={1} className={classes.info}>
      <TextField variant="h4" bold data-testid="text-h4">
        {decline || pendingHeaderText || approveHeaderText}
      </TextField>
      <TextField
        variant="h5"
        color="secondary"
        display="inline"
        data-testid="text-h5"
      >
        {!decline && (pendingInfoText || approveInfoText)}
      </TextField>
    </Col>
  );
};
