import { jwtDecode } from 'jwt-decode';

const decode = (token) => {
  if (!token) {
    return {};
  }

  try {
    return {
      ...jwtDecode(token),
    };
  } catch (err) {
    return {};
  }
};

const jwt = {
  decode,
};

export default jwt;
