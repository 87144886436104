import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { createSelector } from 'reselect';

import { api } from '../api';
import { getPriceStr } from '../../../utils/common';

const initialState = {
  isDirty: false,
  isDeclineRequested: false,
  requestToBook: {},
  listing: {},
  checkInDateLocalized: '',
  checkOutDateLocalized: '',
  status: '',
  eta: '',
  etd: '',
  money: {
    ownerRevenue: 0,
    currency: 'USD',
  },
  error: {},
};

const slice = createSlice({
  name: 'requestToBookSlice',
  initialState,
  reducers: {
    setDeclineRequestAction(state, { payload }) {
      state.isDeclineRequested = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getRequestToBook.matchFulfilled,
      (state, { payload }) => {
        const {
          listing,
          checkInDateLocalized,
          checkOutDateLocalized,
          status,
          eta,
          etd,
          money,
          requestToBook,
        } = payload;
        state.requestToBook = requestToBook;
        state.listing = listing;
        state.checkInDateLocalized = checkInDateLocalized;
        state.checkOutDateLocalized = checkOutDateLocalized;
        state.status = status;
        state.eta = eta;
        state.etd = etd;
        state.money.currency = money?.currency;
        state.money.ownerRevenue = money?.ownerRevenue;
      }
    );
    builder.addMatcher(
      api.endpoints.getRequestToBook.matchRejected,
      (state, { error }) => {
        state.error = error;
      }
    );
    builder.addMatcher(
      api.endpoints.approveRequestToBook.matchFulfilled,
      (state, { payload }) => {
        state.requestToBook = payload;
        state.isDirty = true;
        state.isDeclineRequested = false;
      }
    );
    builder.addMatcher(
      api.endpoints.approveRequestToBook.matchRejected,
      (state, { error }) => {
        state.error = error;
      }
    );
    builder.addMatcher(
      api.endpoints.declineRequestToBook.matchFulfilled,
      (state, { payload }) => {
        state.requestToBook = payload;
        state.isDirty = true;
        state.isDeclineRequested = false;
      }
    );
    builder.addMatcher(
      api.endpoints.declineRequestToBook.matchRejected,
      (state, { error }) => {
        state.error = error;
      }
    );
  },
});

export const { setDeclineRequestAction } = slice.actions;

export const selectRequestToBook = (state) => state.requestToBook;
export const selectIsActionApplied = (state) => state.requestToBook.isDirty;
export const selectIsDeclineRequested = (state) =>
  state.requestToBook.isDeclineRequested;
export const getListing = createSelector(
  selectRequestToBook,
  (state) => state.listing || {}
);
export const getReservationStatus = createSelector(
  selectRequestToBook,
  (state) => state.status
);
export const getListingName = createSelector(
  getListing,
  (listing) => listing.nickname
);
export const getListingTitle = createSelector(
  getListing,
  (listing) => listing.title
);
export const getListingAddress = createSelector(
  getListing,
  (listing) => listing.address
);
export const getOwnerRevenue = createSelector(selectRequestToBook, (state) =>
  getPriceStr(state.money?.ownerRevenue, state.money?.currency));
export const getCheckInDate = createSelector(selectRequestToBook, (state) =>
  moment.utc(state.checkInDateLocalized).format('MMM DD, YYYY'));
export const getCheckOutDate = createSelector(selectRequestToBook, (state) =>
  moment.utc(state.checkOutDateLocalized).format('MMM DD, YYYY'));
export const getETATime = createSelector(selectRequestToBook, (state) =>
  moment.utc(state.eta).format('hh:mm a'));
export const getETDTime = createSelector(selectRequestToBook, (state) =>
  moment.utc(state.etd).format('hh:mm a'));
export const getBookingRequest = createSelector(
  selectRequestToBook,
  (state) => state.requestToBook || {}
);
export const getBookingRequestStatus = createSelector(
  getBookingRequest,
  (requestToBook) => requestToBook.status
);
export const getSendQuote = createSelector(
  getBookingRequest,
  (requestToBook = {}) => {
    return requestToBook.sendQuote;
  }
);
export const getQuoteIsBlockingActions = createSelector(
  getSendQuote,
  (sendQuote = {}) => {
    return sendQuote.required && sendQuote.status === 'pending';
  }
);
export const getBookingRequestApproved = createSelector(
  getBookingRequestStatus,
  (status) => status === 'approved'
);
export const getBookingRequestDeclined = createSelector(
  getBookingRequestStatus,
  (status) => status === 'declined'
);
export const getBookingRequestExpired = createSelector(
  [getBookingRequestStatus, getReservationStatus],
  (status, reservationStatus) =>
    status === 'expired' || reservationStatus === 'expired'
);
export const getOwnerApprovalStatus = createSelector(
  getBookingRequest,
  (requestToBook) => requestToBook?.ownerApproval?.status
);
export const getBookingRequestPendingOwnerApproval = createSelector(
  [getOwnerApprovalStatus, getQuoteIsBlockingActions, getBookingRequestExpired],
  (status, isQuoteBlock, isExpired) =>
    status === 'pending' && !isQuoteBlock && !isExpired
);
export const getBookingRequestApprovedByOwner = createSelector(
  getOwnerApprovalStatus,
  (status) => status === 'approved'
);
export const getTeamApprovalStatus = createSelector(
  getBookingRequest,
  (requestToBook) => requestToBook?.teamApproval?.status
);
export const getBookingRequestApprovedByTeam = createSelector(
  getTeamApprovalStatus,
  (status) => status === 'approved'
);
export const getBookingRequestDeclinedByTeam = createSelector(
  getTeamApprovalStatus,
  (status) => status === 'declined'
);

export default slice.reducer;
