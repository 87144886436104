import React from 'react';

import ButtonLoading from 'components/common/ButtonLoading';

class LoginButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logging: props.logging,
    };

    this.onClick = this.onClick.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ logging }) {
    this.setState({ logging });
  }

  onClick() {
    this.setState({ logging: true });
  }

  render() {
    const { isDisabled, text } = this.props;
    return (
      <ButtonLoading
        size="lg"
        className="w-100"
        isDisabled={isDisabled}
        loading={this.state.logging}
        noCheck
        onClick={this.onClick}
      >
        {text}
      </ButtonLoading>
    );
  }
}

export default LoginButton;
