import { connect } from 'react-redux';

import LoginPage from 'pages/LoginPage';
import { isSmallScreen, getCompanyLogo } from 'ui/ui.selectors';
import {
  forgotPassword,
  login,
  register,
  resetPassword,
} from 'user/user.actions';
import { isPasswordReseted } from 'user/user.selectors';

function mapStateToProps(state, ownProps) {
  return {
    mode: ownProps.mode || state.ui.initState,
    logo: getCompanyLogo(state),
    email: state.ui.email,
    name: state.ui.name,
    isSmallScreen: isSmallScreen(state),
    hash: state.ui.hash,
    error: state.user.loginError,
    isPasswordReseted: isPasswordReseted(state),
    logging: state.user.authenticated,
    authenticated: state.user.authenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogin: (username, password) => dispatch(login(username, password)),
    onForgotPassword: (username) => dispatch(forgotPassword(username)),
    onResetPassword: (hash, username, password) =>
      dispatch(resetPassword(hash, username, password)),
    onRegister: (hash, username, password) =>
      dispatch(register(hash, username, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
