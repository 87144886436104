import dio, { DioTypes } from 'utils/dio';

export default {
  onShowListingCalendar: (listingId) => {
    dio?.track('my_properties_show_listing_calendar', DioTypes.CLICK, {
      listingId,
      action: 'open',
    });
  },
  onHideListingCalendar: (listingId) => {
    dio?.track('my_properties_show_listing_calendar', DioTypes.CLICK, {
      listingId,
      action: 'close',
    });
  },
  onAddReservation: (listingId) => {
    dio?.track('my_properties_add_reservation', DioTypes.CLICK, {
      listingId,
    });
  },
  onConfirmReservation: (listingId) => {
    dio?.track('my_properties_confirm_reservation', DioTypes.CLICK, {
      listingId,
    });
  },
  onModifyReservation: (listingId) => {
    dio?.track('my_properties_modify_reservation', DioTypes.CLICK, {
      listingId,
    });
  },
  onCancelReservation: (listingId) => {
    dio?.track('my_properties_cancel_reservation', DioTypes.CLICK, {
      listingId,
    });
  },
};
