import cn from 'classnames';
import { useSelector } from 'react-redux';

import createStyles from '@guestyci/foundation/createStyles';
import { darkBlue10, gray100, gray50 } from '@guestyci/foundation/theme/colors';
import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import StatusIndication from '@guestyci/foundation/StatusIndication';
import t from '@guestyci/localize/t.macro';

import AppSpinner from '../../components/AppSpinner';

import useMatchURLParams from './utils/useMatchURLParams';
import { ReservationDetails } from './components/ReservationDetails';
import { OwnerActions } from './components/OwnerActions';
import { RequestToBookHeader } from './components/RequestToBookHeader';
import { RequestToBookSubHeader } from './components/RequestToBookSubHeader';
import { useGetRequestToBookQuery } from './api';
import {
  getBookingRequestDeclined,
  getBookingRequestExpired,
  getBookingRequestPendingOwnerApproval,
  getQuoteIsBlockingActions,
  selectIsDeclineRequested,
} from './view/requestToBook.slice';

const useStyles = createStyles({
  app: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    paddingBottom: '20px',
    paddingTop: '35px',
    backgroundColor: gray50,
    borderBottom: `solid 1px ${darkBlue10}`,
  },
  content: {},
  footer: {
    marginTop: 'auto',
    backgroundColor: gray100,
    borderTop: `solid 1px ${darkBlue10}`,
    padding: '20px',
  },
  newTitle: {
    width: 270,
    marginLeft: 70,
  },
  warning: {
    width: '490px',
    margin: '20px 0',
  },
});

const App = ({ logo, ownerName, ownerId, history }) => {
  const match = useMatchURLParams();
  const classes = useStyles();
  const reservationId = match?.reservationId;
  const isDeclineRequested = useSelector(selectIsDeclineRequested);
  const isDeclined = useSelector(getBookingRequestDeclined);
  const isExpired = useSelector(getBookingRequestExpired);
  const isPendingOwnerApproval = useSelector(
    getBookingRequestPendingOwnerApproval
  );
  const isQuoteBlocking = useSelector(getQuoteIsBlockingActions);
  const { isSuccess, isLoading, isError } =
    useGetRequestToBookQuery(reservationId);
  if (isError) {
    history.push('/');
  }
  return (
    <Col span={12} className={cn('', classes.app)} align="center">
      <AppSpinner isVisible={!!isLoading} />
      {isSuccess && !isQuoteBlocking && (
        <>
          <RequestToBookHeader logo={logo} classes={classes} />
          <Row
            className={classes.content}
            fullWidth
            align="center"
            justify="center"
          >
            <Col spacing={1}>
              {!isDeclineRequested && !isExpired && (
                <RequestToBookSubHeader ownerName={ownerName} />
              )}
              <ReservationDetails />
              {isDeclined && (
                <StatusIndication
                  className={classes.warning}
                  variant="redWarning"
                  text={t(
                    "To improve future guest experiences, we recommend updating your calendar's availability to prevent declining requests you don't want to accommodate. Updating your calendar with newly available nights also helps increase revenue."
                  )}
                />
              )}
              {isPendingOwnerApproval && (
                <OwnerActions
                  reservationId={reservationId}
                  ownerId={ownerId}
                  ownerName={ownerName}
                  isDeclineRequested={isDeclineRequested}
                />
              )}
            </Col>
          </Row>
          {isPendingOwnerApproval && !isDeclineRequested && (
            <Row
              fullWidth
              className={classes.footer}
              justify="center"
              align="center"
            >
              <TextField variant="h5">
                {t(
                  "Have questions? We're happy to help. Reply to this email or contact support"
                )}
              </TextField>
            </Row>
          )}
        </>
      )}
    </Col>
  );
};

export default App;
