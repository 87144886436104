import { PLATFORMS, GCM_CHANNELS } from '@guestyci/shared-enums';
import { ReactComponent as IcoHopper } from '@guestyci/icons/IcoHopperCircle.svg';
import { ReactComponent as IcoTripCom } from '@guestyci/icons/IcoTripComCircle.svg';
import { ReactComponent as IcoAirbnb } from '@guestyci/icons/IcoAirbnbCircle.svg';
import { ReactComponent as IcoVrboCircle } from '@guestyci/icons/IcoVrboCircle.svg';
import { ReactComponent as IcoPlumGuide } from '@guestyci/icons/IcoPlumGuideCircle.svg';
import { ReactComponent as IcoHotelbeds } from '@guestyci/icons/IcoHotelBedsCircle.svg';
import { ReactComponent as IcoAgodaCircle } from '@guestyci/icons/IcoAgodaCircle.svg';
import { ReactComponent as IcoBookingcom } from '@guestyci/icons/IcoBookingcom.svg';
import { ReactComponent as IcoGuestyChannel } from '@guestyci/icons/IcoNotificationGuesty.svg';
import { ReactComponent as IcoExpediaCircle } from '@guestyci/icons/IcoExpediaCircle.svg';
import { ReactComponent as IcoSmilingHouse } from '@guestyci/icons/IcoSmilingHouseCircle.svg';
import { ReactComponent as IcoTripadvisorCircle } from '@guestyci/icons/IcoTripadvisorCircle.svg';
import { ReactComponent as IcoSiteminderCircle } from '@guestyci/icons/IcoSiteminderCircle.svg';
import { ReactComponent as IcoGoogleVacationRentals } from '@guestyci/icons/IcoGoogleVacationRentalsCircle.svg';
import { ReactComponent as IcoHomesVillasByMarriott } from '@guestyci/icons/IcoHomesVillasByMarriottCircle.svg';

const iconParams = {
  height: 20,
  width: 20,
};

export const channelsMapper = [
  {
    svg: IcoGuestyChannel,
    ...iconParams,
    label: 'Guesty Booking Website',
    value: PLATFORMS.MANUAL, // manual
  },
  {
    svg: IcoAirbnb,
    ...iconParams,
    label: 'Airbnb',
    value: PLATFORMS.AIRBNB, // airbnb
  },
  {
    svg: IcoAirbnb,
    ...iconParams,
    label: 'Airbnb',
    value: PLATFORMS.AIRBNB2, // airbnb2
  },
  {
    svg: IcoBookingcom,
    ...iconParams,
    label: 'Booking.com',
    value: PLATFORMS.BOOKING_COM, // bookingCom
  },
  {
    svg: IcoVrboCircle,
    ...iconParams,
    label: 'Vrbo',
    value: PLATFORMS.HOME_AWAY, // homeAway
  },
  {
    svg: IcoVrboCircle,
    ...iconParams,
    label: 'Vrbo',
    value: PLATFORMS.HOMEAWAY2, // homeaway2
  },
  {
    svg: IcoTripadvisorCircle,
    ...iconParams,
    label: 'Tripadvisor',
    value: PLATFORMS.TRIP_ADVISOR, // tripAdvisor
  },
  {
    svg: IcoAgodaCircle,
    ...iconParams,
    label: 'Agoda',
    value: PLATFORMS.AGODA, // agoda
  },
  {
    svg: IcoSiteminderCircle,
    ...iconParams,
    label: 'Siteminder',
    value: PLATFORMS.SITEMINDER, // siteMinder
  },
  {
    svg: IcoExpediaCircle,
    ...iconParams,
    label: 'Expedia',
    value: PLATFORMS.EXPEDIA, // expedia
  },
  {
    svg: IcoTripCom,
    ...iconParams,
    label: 'Trip.com',
    value: GCM_CHANNELS.TRIPCOM, // tripCom
  },
  {
    svg: IcoHopper,
    ...iconParams,
    label: 'Hopper',
    value: GCM_CHANNELS.HOPPER, // hopper
  },
  {
    svg: IcoHotelbeds,
    ...iconParams,
    label: 'Hotelbeds',
    value: GCM_CHANNELS.HOTELBEDS, // hotelbeds
  },
  {
    svg: IcoSmilingHouse,
    ...iconParams,
    label: 'Smiling House',
    value: GCM_CHANNELS.SMILING_HOUSE, // smilingHouse
  },
  {
    svg: IcoPlumGuide,
    ...iconParams,
    label: 'Plum Guide',
    value: GCM_CHANNELS.PLUM_GUIDE, // plumGuide
  },
  {
    svg: IcoGoogleVacationRentals,
    ...iconParams,
    label: 'Google Vacation Rentals',
    value: GCM_CHANNELS.GOOGLE_VR, // googleVacationRentals
  },
  {
    svg: IcoHomesVillasByMarriott,
    ...iconParams,
    label: 'IcoHomesVillasByMarriott',
    value: GCM_CHANNELS.HVBM, // homesVillasByMarriott
  },
  {
    svg: IcoGuestyChannel,
    ...iconParams,
    label: 'Rentals United',
    value: PLATFORMS.RENTALS_UNITED, // rentalsUnited
  },
];

export const getChannelIcon = (type) => {
  const DEFAULT_SOURCE = 'manual';
  const icon =
    channelsMapper.find(({ value }) => value === type) ||
    channelsMapper.find(({ value }) => value === DEFAULT_SOURCE);
  return icon;
};
