import React from 'react';
import { Redirect } from 'react-router-dom';

const withAuthentication = (WrappedComponent) => {
  class AuthenticatedComponent extends React.Component {
    isAuthenticated() {
      return this.props.authenticated;
    }

    render() {
      if (this.isAuthenticated()) {
        return <WrappedComponent {...this.props} />;
      }

      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: this.props.location },
          }}
        />
      );
    }
  }

  return AuthenticatedComponent;
};

export default withAuthentication;
