import warning from 'fbjs/lib/warning';

import iconMappper from 'icons/iconMap';

export default ({ icon, className }) => {
  const iconUrl = iconMappper[icon];
  if (process.env.NODE_ENV === 'development') {
    warning(
      typeof iconUrl !== 'undefined',
      `No icon found for '${icon}'. Did you forget to add it to iconMap.js?`
    );
  }

  return <img src={iconUrl} alt={icon} className={className} />;
};
