import { connect } from 'react-redux';
import { branch, compose } from 'recompose';

import Calendar from 'components/Calendar';
import { addReservation } from 'property/properties.actions';
import withReservation from 'hocs/containers/withReservation.container';
import {
  getExtendedRealCalendar,
  getInitialMonth,
  getRevenue,
  getPreviousMonth,
  getBookedNights,
  getRevenueByReservationStatus,
  getIsFetchingMiniData,
} from 'property/calendar.selectors';
import { getOwnersPortalShowReservedReservations } from 'user/user.selectors';

import { constants, showModal } from '../modal';
import { updateRentalPeriod } from '../property/properties.actions';

const mapStateToProps = (state, props) => ({
  ...state.property.selection,
  calendar: getExtendedRealCalendar(state, props),
  previousMonth: getPreviousMonth(state, props),
  isLoading: !getExtendedRealCalendar(state, props),
  initialMonth: getInitialMonth(state, props),
  revenue: getRevenue(state, props),
  bookedNights: getBookedNights(state, props),
  revenueByReservationStatus: getRevenueByReservationStatus(state, props),
  isFetchingMiniData: getIsFetchingMiniData(state, props),
  locale: state.user.locale,
  allowReservations: state.user.allowReservations,
  rentalPeriods: state.property.rentalPeriods.list,
  isRentalPeriodEnabled: props.isRentalPeriodEnabled,
  showReservedReservations: getOwnersPortalShowReservedReservations(state),
});

const mapDispatchToProps = (dispatch) => ({
  onShowRentalPeriodModal: (props) => {
    dispatch(showModal(constants.RENTAL_PERIOD, props));
  },
  addReservation: (props) => {
    dispatch(addReservation(props));
  },
  updateRentalPeriod: ({ listingId, data }) =>
    dispatch(updateRentalPeriod({ listingId, data })),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ allowReservations }) => allowReservations, withReservation)
)(Calendar);
