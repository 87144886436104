/* eslint-disable no-param-reassign */
import keyBy from 'lodash/keyBy';
import pull from 'lodash/pull';
import reduce from 'lodash/reduce';
import map from 'lodash/map';

import { types } from 'property/properties.actions';
import {
  reducer,
  getMonthYearKey,
  toISODateString,
  getLastDateInMonth,
  getDateFromKey,
  getNextMonthKey,
} from 'utils/common';

const initialState = {
  selection: {
    from: null,
    to: null,
    isSelecting: false,
  },
  isFetching: false,
  isFetchingAll: false,
  isFetchingCalendars: false,
  statements: {
    total: null,
    list: [],
  },
  rentalPeriods: {
    defaultAvailability: '',
    list: [],
  },
  allResults: null,
};

const updateListingProp = (state, listingId, prop, value) => {
  const listing = state.results[listingId];
  return {
    ...state,
    results: {
      ...state.results,
      [listingId]: {
        ...listing,
        [prop]: value,
      },
    },
  };
};

const updateListingNestedProp = (state, listingId, prop, key, value) => {
  const listing = state.results[listingId];
  const curr = listing[prop];
  const newValue = {
    ...curr,
    [key]: value,
  };

  return updateListingProp(state, listingId, prop, newValue);
};

const updateMonth = (listing, month, start, end, propValue) => {
  if (month) {
    const [from, to] = [toISODateString(start), toISODateString(end)];
    const updatedDays = Object.keys(month).reduce((prev, current) => {
      if (!prev[from]) {
        if (current === from) {
          prev[from] = { ...month[current], ...propValue };
        }
      } else if (!prev[to] && prev[from]) {
        prev[current] = { ...month[current], ...propValue };
      }
      return prev;
    }, {});

    return {
      ...month,
      ...updatedDays,
    };
  }
};

const updateCalendar = (state, listingId, start, end, propValue) => {
  const listing = state.results[listingId];
  const { calendar } = listing;
  const monthYearKey = getMonthYearKey(start.getMonth(), start.getFullYear());
  const monthYearKeys = [monthYearKey];

  const endDateMonthYearKey = getMonthYearKey(
    end.getMonth(),
    end.getFullYear()
  );

  if (monthYearKey !== endDateMonthYearKey) {
    let lastMonthYearKey = monthYearKeys[monthYearKeys.length - 1];
    while (lastMonthYearKey !== endDateMonthYearKey) {
      lastMonthYearKey = getNextMonthKey(lastMonthYearKey);
      monthYearKeys.push(lastMonthYearKey);
    }
  }

  const updatedMonths = monthYearKeys.map((key, index) => {
    let endDate = null;
    const startDate = index === 0 ? start : getDateFromKey(key);
    if (key !== endDateMonthYearKey) {
      endDate = getLastDateInMonth(
        getDateFromKey(key).getMonth(),
        getDateFromKey(key).getFullYear()
      );
    } else {
      endDate = end;
    }
    return updateMonth(listing, calendar[key], startDate, endDate, propValue);
  });
  const updatedCalendar = {};

  for (let i = 0; i < monthYearKeys.length; i += 1) {
    updatedCalendar[monthYearKeys[i]] = updatedMonths[i];
  }

  return updateListingProp(state, listingId, 'calendar', {
    ...calendar,
    ...updatedCalendar,
  });
};

const handlers = {
  [types.FETCH_LISTINGS_REQUEST](state) {
    return {
      ...state,
      isFetching: true,
    };
  },

  [types.FETCH_LISTINGS](state, action) {
    const current = action.listings.skip !== 0 ? state.results : undefined;

    return {
      ...state,
      isFetching: false,
      count: action.listings.count,
      results: Object.assign(
        current || {},
        keyBy(action.listings.results, '_id')
      ),
    };
  },

  [types.FETCH_ALL_LISTINGS_REQUEST](state) {
    return {
      ...state,
      isFetchingAll: true,
    };
  },

  [types.FETCH_ALL_LISTINGS](state, action) {
    return {
      ...state,
      isFetchingAll: false,
      allResults: action.listings || [],
    };
  },

  [types.FETCH_CALENDAR_MONTH_YEAR_REQUEST](state, action) {
    const { listingId, month, year } = action;
    const key = getMonthYearKey(month, year);
    return updateListingNestedProp(state, listingId, 'calendar', key, {
      isFetching: true,
    });
  },

  [types.FETCH_CALENDAR_MONTH_YEAR](state, action) {
    const { listingId, month, year, calendar } = action;
    const key = getMonthYearKey(month, year);
    return updateListingNestedProp(
      state,
      listingId,
      'calendar',
      key,
      keyBy(calendar, 'date')
    );
  },

  [types.FETCH_CALENDAR_FROM_TO_REQUEST](state) {
    return {
      ...state,
      isFetchingCalendars: true,
    };
  },

  [types.FETCH_CALENDAR_FROM_TO](state, action) {
    const { listingId, calendars } = action;
    const datesObject = reduce(
      calendars,
      (obj, n) => {
        const dateArr = n.date.split('-');
        const month = parseInt(dateArr[1], 10) - 1;
        const monthYear = `${month}_${dateArr[0]}`;
        if (!obj[monthYear]) {
          obj[monthYear] = {};
          if (monthYear === obj[monthYear]) {
            obj[monthYear][n.date] = n;
          }
        }
        return obj;
      },
      {}
    );

    map(calendars, (calendar) => {
      const dateArr = calendar.date.split('-');
      const month = parseInt(dateArr[1], 10) - 1;
      const monthYear = `${month}_${dateArr[0]}`;
      datesObject[monthYear][calendar.date] = calendar;
    });
    return updateListingProp(
      {
        ...state,
        isFetchingCalendars: false,
      },
      listingId,
      'calendar',
      {
        ...datesObject,
        ...state.results[listingId].calendar,
      }
    );
  },

  [types.FETCH_LISTING_MINI_DATA_REQUEST](state, action) {
    const { listingId, month, year } = action;
    const key = getMonthYearKey(month, year);

    return updateListingNestedProp(state, listingId, 'miniData', key, {
      isFetching: true,
    });
  },

  [types.FETCH_LISTING_MINI_DATA](state, action) {
    const { listingId, month, year } = action;
    const key = getMonthYearKey(month, year);

    return updateListingNestedProp(
      state,
      listingId,
      'miniData',
      key,
      action.data
    );
  },

  [types.VIEW_CALENDAR](state, action) {
    const { listingId, month, year } = action;
    const key = getMonthYearKey(month, year);

    return updateListingProp(state, listingId, 'viewCal', key);
  },

  [types.ADD_ACTIVE_LISTING](state, action) {
    const activeListings = (state.activeListings || []).concat(
      action.listingId
    );

    return {
      ...state,
      activeListings,
    };
  },

  [types.REMOVE_ACTIVE_LISTING](state, action) {
    const activeListings = pull(state.activeListings, action.listingId);

    return {
      ...state,
      activeListings,
    };
  },

  [types.RESET_ALL]() {
    return {};
  },

  [types.SELECT](state, action) {
    return {
      ...state,
      selection: {
        ...state.selection,
        ...action.data,
      },
    };
  },

  [types.UPDATE_CALENDAR](state, { listingId, from, to, propValue }) {
    return updateCalendar(state, listingId, from, to, propValue);
  },

  [types.FETCH_RENTAL_PERIODS](state, { defaultAvailability, rentalPeriods }) {
    return {
      ...state,
      rentalPeriods: {
        defaultAvailability,
        list: [...rentalPeriods],
      },
    };
  },

  [types.UPDATE_RENTAL_PERIODS](state, { rentalPeriods }) {
    return {
      ...state,
      rentalPeriods: {
        ...state.rentalPeriods,
        list: [...rentalPeriods],
      },
    };
  },
};

export default reducer(handlers, initialState);
