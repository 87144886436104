import { isDateBetweenDates } from './calendar';

export const parseRentalPeriodDates = (rentalPeriods) => {
  return rentalPeriods.map((period) => ({
    ...period,
    from: new Date(period.from),
    to: new Date(period.to),
  }));
};

export const isRentalPeriodInCalendar = (date, rentalPeriods = []) => {
  return rentalPeriods.some(({ from, to }) => {
    return isDateBetweenDates({ date, from, to, isStrongEqual: true });
  });
};

export const getRentalPeriod = (date, rentalPeriods) => {
  return rentalPeriods.find(({ from, to }) =>
    isDateBetweenDates({ date, from, to, isStrongEqual: true }));
};

export const isRentalPeriodElement = (isRentalDate, classNames) => {
  return isRentalDate && classNames.includes('hatched');
};

export const getStartDecorationClassName = ({
  status,
  prevStatus = '',
  isLastBooking,
  withRequestToBook,
}) => {
  const computedStatus =
    withRequestToBook && status === 'available' ? 'hatched' : status;

  return status === prevStatus && isLastBooking
    ? `middle ${computedStatus}`
    : `start ${computedStatus}`;
};

export const getEndDecorationClassName = ({
  status,
  prevStatus = '',
  isLastBooking,
  withRequestToBook,
}) => {
  const computedStatus =
    withRequestToBook && prevStatus === 'available' ? 'hatched' : prevStatus;

  return status === prevStatus && !isLastBooking
    ? `middle ${status}`
    : `end ${computedStatus}`;
};
