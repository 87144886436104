import useGuestyMediaQuery from '@guestyci/foundation/useMediaQuery';

export const ScreenSize = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XLG: 'xlg',
};

export const useMediaQuery = () => {
  const isXs = useGuestyMediaQuery({
    maxWidth: 'sm',
  });
  const isSm = useGuestyMediaQuery({
    minWidth: 'sm',
    maxWidth: 'md',
  });
  const isMd = useGuestyMediaQuery({
    minWidth: 'md',
    maxWidth: 'lg',
  });
  const isLg = useGuestyMediaQuery({
    minWidth: 'lg',
    maxWidth: 'xl',
  });
  const isXl = useGuestyMediaQuery({
    minWidth: 'xl',
    maxWidth: 'xlg',
  });
  const isXlg = useGuestyMediaQuery({
    minWidth: 'xlg',
  });

  let screenSize = '';

  if (isXs) {
    screenSize = ScreenSize.XS;
  } else if (isSm) {
    screenSize = ScreenSize.SM;
  } else if (isMd) {
    screenSize = ScreenSize.MD;
  } else if (isLg) {
    screenSize = ScreenSize.LG;
  } else if (isXl) {
    screenSize = ScreenSize.XL;
  } else if (isXlg) {
    screenSize = ScreenSize.XLG;
  }

  return {
    isXsScreen: isXs,
    isSmScreen: isSm || isXs,
    isMdScreen: isMd || isSm || isXs,
    isLgScreen: isLg || isXl || isXlg,
    isXlScreen: isXl || isXlg,
    isXlgScreen: isXlg,
    screenSize,
  };
};
