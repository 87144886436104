import { get, post, put } from 'api/http-client';
import { createOwnersAPI } from 'api/ownersApi';
import { createRevenueAPI } from 'api/revenueApi';
import { createRASAPI } from 'api/rasApi';
import { createRoomAvailabilityAPI } from 'api/roomAvailabilityApi';
import {
  USE_ACCOUNTING_FLOW,
  SHOW_NET_RENTAL_INCOME,
  USE_NEW_MY_PROPERTIES_PAGE,
} from 'constants/featureToggle';
import {
  getFromTo,
  getMonthYearKey,
  getPrevMonthKey,
  getDateFromKey,
  getNextMonthKey,
} from 'utils/common';
import { formatDatesWithoutUTC } from 'property/property.utils';

const getOwnerRevenue = (params) =>
  createRevenueAPI().get('/analytics/owner', { params });
const getRASAccommodationFare = (params) =>
  createRASAPI().get('/accommodation-fare', { params });
const getRASAverageNightlyRate = (params) =>
  createRASAPI().get('/average-nightly-rate', { params });
const getRASRevPal = (params) => createRASAPI().get('/revpal', { params });
const getRASOwnersRevenue = (params) =>
  createRASAPI().get('/owners-revenue', { params });

const listingsFields = [
  '_id',
  'title',
  'bedrooms',
  'type',
  'propertyType',
  'address.full',
  'picture.regular',
  'picture.thumbnail',
  'active',
  'nickname',
  'defaultCheckInTime',
  'defaultCheckOutTime',
  'complexId',
  'mtl',
];

export const getListings = (data = {}) => {
  const params = {
    fields: listingsFields.join(' '),
    ...data,
  };

  const useNewMyPropertiesPage = window.featureToggle?.isEnabled(
    USE_NEW_MY_PROPERTIES_PAGE
  );

  return useNewMyPropertiesPage
    ? createOwnersAPI()
        .get('/me/listings', { params })
        .then((response) => response.data)
    : get('/v2/listings', params);
};

export const getCalendarFromTo = (listingId, from, to, options) => {
  const fieldsList = [
    'date',
    'accountId',
    'listingId',
    'guest',
    'status',
    'note',
    'price',
    'currency',
    'listing.prices.currency',
    'reservationId',
    'reservation',
    'blocks',
    'ownerReservation',
    'ownerReservationId',
  ];

  const params = {
    from,
    to,
    fields: fieldsList.join(' '),
  };

  const isAccountingFTEnabled =
    window.featureToggle?.isEnabled(USE_ACCOUNTING_FLOW);

  const showNetRentalIncome = window.featureToggle?.isEnabled(
    SHOW_NET_RENTAL_INCOME
  );

  return isAccountingFTEnabled &&
    showNetRentalIncome &&
    options.ownersPortalAccommodationFareEnabled
    ? createRevenueAPI()
        .get(`/analytics/listings/${listingId}/calendar`, {
          params,
        })
        .then((response) => response.data)
    : get(`/v2/listings/${listingId}/calendar`, params);
};

export const getCalendarMonthYear = (listingId, month, year, options) => {
  const { from, to } = getFromTo(month, year);
  return getCalendarFromTo(listingId, from, to, options);
};

export const getListingMiniData = (listingId, month, year) => {
  const useRas = !window.featureToggle?.isEnabled(USE_ACCOUNTING_FLOW);
  const params = {
    listingIds: listingId,
    ...getFromTo(month, year),
  };

  const ownerRevenuePromise = getOwnerRevenue({
    ...params,
    view: 'table',
    confirmedOnly: true,
  })
    .then((response) => {
      const result = response.data;
      const listing = result.tableData.find((x) => x._id === listingId);

      if (!listing) {
        return {};
      }

      let revenue;
      let bookedNights;

      const {
        analytics,
        revpal,
        occupancy,
        average_nightly_rate: avgNightlyRate,
      } = listing;

      if (analytics) {
        revenue = analytics.find((x) => x.type === 'OWNER_REVENUE') || {};
        bookedNights = analytics.find((x) => x.type === 'OCCUPANCY');
      }

      return {
        revpal,
        revenue,
        occupancy,
        bookedNights,
        avgNightlyRate,
        revenueByReservationStatus: revenue.byReservationStatus,
      };
    })
    .catch(() => {
      return {};
    });

  const promises = [ownerRevenuePromise];

  if (useRas) {
    const rasParams = {
      listing_id: listingId,
      ...getFromTo(month, year),
    };

    const rasAccommodationFarePromise = getRASAccommodationFare(rasParams)
      .then((response) => response.data?.data?.[0]?.accommodationFare)
      .catch(() => ({}));

    const rasAverageNightlyRatePromise = getRASAverageNightlyRate(rasParams)
      .then((response) => response.data?.data?.[0]?.avgNightlyRate)
      .catch(() => ({}));

    const rasRevPalPromise = getRASRevPal(rasParams)
      .then((response) => response.data?.data?.[0]?.revpal)
      .catch(() => ({}));

    const rasOwnersRevenuePromise = getRASOwnersRevenue(rasParams)
      .then((response) => response.data?.data?.[0]?.ownersRevenue)
      .catch(() => ({}));

    promises.push(
      rasAccommodationFarePromise,
      rasAverageNightlyRatePromise,
      rasRevPalPromise,
      rasOwnersRevenuePromise
    );
  }

  return Promise.all(promises)
    .then(
      ([
        ownerRevenue,
        rasAccommodationFare,
        rasAverageNightlyRate,
        rasRevPal,
        rasOwnersRevenue,
      ]) => {
        if (useRas) {
          return {
            bookedNights: ownerRevenue.bookedNights,
            occupancy: ownerRevenue.occupancy,
            accommodationFare: rasAccommodationFare || undefined,
            avgNightlyRate: rasAverageNightlyRate || undefined,
            revpal: rasRevPal || undefined,
            revenue: {
              type: 'OWNER_REVENUE',
              total: rasOwnersRevenue || undefined,
              listingId,
              byReservationStatus: {},
            },
            revenueByReservationStatus: {},
          };
        }

        return ownerRevenue;
      }
    )
    .catch(() => {
      return {};
    });
};

export const fetchCalendarData = (
  listing,
  month,
  year,
  { getCalendarMonthYear: _getCalendarMonthYear, getMiniData },
  options,
  forceLoad = false
) => {
  const listingId = listing._id;
  const monthKey = getMonthYearKey(month, year);
  const calendar = listing.calendar || {};
  if (!calendar[monthKey]) {
    _getCalendarMonthYear(listingId, month, year, options);
  }

  const prevMonthKey = getPrevMonthKey(monthKey);
  if (!calendar[prevMonthKey] || forceLoad) {
    const prevMonth = getDateFromKey(prevMonthKey);
    _getCalendarMonthYear(
      listingId,
      prevMonth.getMonth(),
      prevMonth.getFullYear(),
      options
    );
  }

  const prevPrevMonthKey = getPrevMonthKey(prevMonthKey);
  if (!calendar[prevPrevMonthKey] || forceLoad) {
    const prevPrevMonth = getDateFromKey(prevPrevMonthKey);
    _getCalendarMonthYear(
      listingId,
      prevPrevMonth.getMonth(),
      prevPrevMonth.getFullYear(),
      options
    );
  }

  const nextMonthKey = getNextMonthKey(monthKey);
  if (!calendar[nextMonthKey] || forceLoad) {
    const nextMonth = getDateFromKey(nextMonthKey);
    _getCalendarMonthYear(
      listingId,
      nextMonth.getMonth(),
      nextMonth.getFullYear(),
      options
    );
  }

  if (!listing.miniData || !listing.miniData[monthKey] || forceLoad) {
    getMiniData(listingId, month, year);
  }
};

export const addReservation = (checkIn, checkOut, listingId, note) =>
  post('/v2/owners-reservations', {
    ...formatDatesWithoutUTC(checkIn, checkOut),
    listingId,
    note,
  });

export const updateReservation = (
  ownerReservationId,
  checkIn,
  checkOut,
  note
) =>
  put(`/v2/owners-reservations/${ownerReservationId}`, {
    ...formatDatesWithoutUTC(checkIn, checkOut),
    note,
  });

export const cancelReservation = (ownerReservationId) =>
  post(`/v2/owners-reservations/${ownerReservationId}`, {});

export const fetchRentalPeriod = (listingId) =>
  createRoomAvailabilityAPI().get(
    `/availabilitySettings/unitTypes/${listingId}/rentalPeriods`
  );

export const updateRentalPeriod = ({ listingId, data }) =>
  createRoomAvailabilityAPI().patch(
    `/availabilitySettings/unitTypes/${listingId}`,
    data
  );
