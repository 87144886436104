import t from '@guestyci/localize/t.macro';

import { USE_ACCOUNTING_FLOW, SHOW_HELP_CENTER } from 'constants/featureToggle';

export default [
  {
    getName: () => t('My properties'),
    href: 'my-properties',
    toShow: () => true,
  },
  {
    getName: () => t('Reservation report'),
    href: 'reservation-report',
    toShow: ({ isGuestsReportSettingEnabled }) => isGuestsReportSettingEnabled,
  },
  {
    getName: () => t('Analytics'),
    href: 'analytics',
    toShow: ({
      portalSettings: {
        avgNightlyRate,
        bookedNights,
        occupancy,
        revenue,
        revPal,
      },
    }) => avgNightlyRate || bookedNights || revenue || occupancy || revPal,
  },
  {
    getName: () => t('Statements'),
    href: 'statements',
    toShow: () => window.featureToggle?.isEnabled(USE_ACCOUNTING_FLOW),
  },
  {
    getName: () => t('Documents'),
    href: 'documents',
    toShow: () => true,
  },
  {
    getName: () => t('Help center'),
    href: 'help-center',
    toShow: ({ portalSettings: { showHelpCenter } }) =>
      window.featureToggle?.isEnabled(SHOW_HELP_CENTER) && showHelpCenter,
  },
];
