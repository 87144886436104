/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import Dialog, {
  DialogHeader,
  DialogContent,
  DialogFooter,
} from '@guestyci/foundation/Dialog';
import { Row, Col } from '@guestyci/foundation/Layout';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import FlatButton from '@guestyci/foundation/FlatButton';
import t from '@guestyci/localize/t.macro';
import TimePicker from '@guestyci/foundation/TimePicker';
import NumberPicker from '@guestyci/foundation/NumberPicker';
import TextField from '@guestyci/foundation/TextField';
import TextArea from '@guestyci/foundation/TextArea';
import { useToast } from '@guestyci/foundation/Toast';

import DatePicker from 'containers/DatePicker.container';
import { fetchCalendarData as fetchCalendarDataService } from 'property/properties.services';
import { subtractDays, addDays } from 'utils/calendar';
import tracker from 'utils/dio/my-properties-tracker';
import {
  usePostInquiryMutation,
  usePostReservationMutation,
  usePostAlterationMutation,
  usePostConfirmAlterationMutation,
  usePostCancellationMutation,
  usePostConfirmCancellationMutation,
} from 'pages/my-properties/app/api/reservationsApi';
import { useUpdateMeMutation } from 'pages/my-properties/app/api/ownersApi';
import { USE_NEW_OWNER_RESERVATIONS } from 'constants/featureToggle';

import constants from '../constants';

import CancelReservationDialog from './CancelReservationDialog';

const proposedCheckInTime = '15:00';
const proposedCheckOutTime = '11:00';

const Reservation = (props) => {
  const {
    isOpen,
    select,
    modalProps,
    listingId,
    from,
    to,
    close,
    resetSelection,
    addReservation,
    updateReservation,
    cancelReservation,
    owner,
    updateOwnerInStore,
    updateCalendarInStore,
    defaultCheckInTime,
    defaultCheckOutTime,
  } = props;

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const lstId =
    modalProps && modalProps.listing ? modalProps.listing._id : listingId;

  const {
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    isOwnerReservationV2,
  } = modalProps;

  const initialState = {
    pickerFrom: from || modalProps.from,
    pickerTo: addDays(to, 1) || modalProps.to,
    expectedArrivalTime: modalProps.update
      ? moment(modalProps.from, 'HH:mm').format('hh:mm A')
      : moment(defaultCheckInTime || proposedCheckInTime, 'HH:mm').format(
          'hh:mm A'
        ),
    expectedDepartureTime: modalProps.update
      ? moment(modalProps.to, 'HH:mm').format('hh:mm A')
      : moment(defaultCheckOutTime || proposedCheckOutTime, 'HH:mm').format(
          'hh:mm A'
        ),
    note: modalProps.note,
    adults: numberOfAdults ?? 1,
    infants: numberOfInfants ?? 0,
    children: numberOfChildren ?? 0,
  };

  const [state, setState] = useState(initialState);
  const [uiState, setUiState] = useState({
    error: null,
    focusedInput: null,
  });

  const { addToast } = useToast();

  const {
    pickerFrom,
    pickerTo,
    expectedArrivalTime,
    expectedDepartureTime,
    note,
    adults,
    infants,
    children,
  } = state;

  const { error, focusedInput } = uiState;

  const [
    postInquiry,
    {
      data: inquiryData,
      isSuccess: isInquirySuccess,
      isError: isInquiryError,
      isLoading: isInquiryLoading,
    },
  ] = usePostInquiryMutation();

  const [
    postAlteration,
    {
      data: alterationData,
      isSuccess: isAlterationSuccess,
      isError: isAlterationError,
      isLoading: isAlterationLoading,
    },
  ] = usePostAlterationMutation();

  const [
    postReservation,
    {
      data: reservationData,
      isSuccess: isReservationSuccess,
      isError: isReservationError,
      isLoading: isReservationLoading,
    },
  ] = usePostReservationMutation();

  const [
    postConfirmAlteration,
    {
      isSuccess: isConfirmAlterationSuccess,
      isError: isConfirmAlterationError,
      isLoading: isConfirmAlterationLoading,
    },
  ] = usePostConfirmAlterationMutation();

  const [
    updateMe,
    { isError: isOwnerUpdateError, isLoading: isOwnerUpdateLoading },
  ] = useUpdateMeMutation();

  const [
    postCancellation,
    {
      data: cancellationData,
      isSuccess: isCancellationSuccess,
      isError: isCancellationError,
      isLoading: isCancellationLoading,
    },
  ] = usePostCancellationMutation();

  const [
    postConfirmCancellation,
    {
      isSuccess: isConfirmCancellationSuccess,
      isError: isConfirmCancellationError,
      isLoading: isConfirmCancellationLoading,
    },
  ] = usePostConfirmCancellationMutation();

  const isLoading =
    isInquiryLoading ||
    isReservationLoading ||
    isOwnerUpdateLoading ||
    isAlterationLoading ||
    isConfirmAlterationLoading ||
    isCancellationLoading ||
    isConfirmCancellationLoading;

  const isCreateError =
    isInquiryError || isReservationError || isOwnerUpdateError;
  const isUpdateError = isAlterationError || isConfirmAlterationError;
  const isCancelError = isCancellationError || isConfirmCancellationError;

  const isError = isCreateError || isUpdateError || isCancelError;

  const dismiss = useCallback(() => {
    if (!isLoading) {
      close(resetSelection);
    }
  }, [close, resetSelection, isLoading]);

  useEffect(() => {
    const createReservation = async () => {
      await postReservation({
        inquiryId: inquiryData._id,
        booker: owner.guestId
          ? {
              _id: owner.guestId,
              firstName: owner.firstName,
              lastName: owner.lastName,
              fullName: owner.fullName,
            }
          : {
              firstName: owner.firstName,
              lastName: owner.lastName,
              email: owner.email,
              phone: owner.phone,
            },
        ratePlanId: inquiryData.rates?.ratePlans[0]?.ratePlanId,
        source: inquiryData.source,
        unitId: listingId,
        notes: { other: note },
        plannedArrival: moment(expectedArrivalTime, 'hh:mm A').format('HH:mm'),
        plannedDeparture: moment(expectedDepartureTime, 'hh:mm A').format(
          'HH:mm'
        ),
        creationInfo: {
          owner: {
            _id: owner._id,
            fullName: owner.fullName,
            email: owner.email,
            phone: owner.phone,
            locale: owner.locale,
          },
        },
      });
    };

    if (isInquirySuccess) {
      createReservation();
    }
  }, [
    listingId,
    owner,
    note,
    inquiryData,
    isInquirySuccess,
    postReservation,
    expectedArrivalTime,
    expectedDepartureTime,
  ]);

  useEffect(() => {
    const updateOwner = async () => {
      await updateMe({
        guestId: reservationData.bookerId,
        email: owner.email,
      });
    };

    if (isReservationSuccess) {
      updateCalendarInStore({
        from: pickerFrom,
        to: subtractDays(pickerTo, 1),
        listingId,
        propValue: {
          blocks: { o: true },
          status: 'unavailable',
          pending: false,
          ownerReservationId: reservationData._id,
          ownersReservation: {
            checkIn: pickerFrom
              ? moment(pickerFrom).format('YYYY-MM-DD HH:mm:ss')
              : undefined,
            checkOut: pickerTo
              ? moment(pickerTo).format('YYYY-MM-DD HH:mm:ss')
              : undefined,
            note: note || '',
          },
        },
      });

      if (!owner.guestId) {
        owner.guestId = reservationData.bookerId;
        updateOwner(owner);
        updateOwnerInStore(owner);
      }

      dismiss();
      addToast.success(t('Reservation added successfully.'));
    }
  }, [
    isReservationSuccess,
    reservationData?._id,
    reservationData?.bookerId,
    updateCalendarInStore,
    pickerFrom,
    pickerTo,
    listingId,
    note,
    owner,
    updateMe,
    updateOwnerInStore,
    addToast,
    dismiss,
  ]);

  useEffect(() => {
    const alterReservation = async () => {
      await postConfirmAlteration({
        ...alterationData,
        reservationId: alterationData.alterationPayload.reservationId,
        inquiryId: alterationData._id,
        notes: { other: note },
        plannedArrival: moment(expectedArrivalTime, 'hh:mm A').format('HH:mm'),
        plannedDeparture: moment(expectedDepartureTime, 'hh:mm A').format(
          'HH:mm'
        ),
      });
    };

    if (isAlterationSuccess) {
      alterReservation();
    }
  }, [
    alterationData,
    isAlterationSuccess,
    postConfirmAlteration,
    note,
    expectedArrivalTime,
    expectedDepartureTime,
  ]);

  useEffect(() => {
    if (isConfirmAlterationSuccess) {
      updateCalendarInStore({
        from: modalProps.from,
        to: subtractDays(modalProps.to, 1),
        listingId,
        propValue: {
          blocks: {},
          status: 'available',
          pending: false,
          ownerReservationId: null,
          ownersReservation: {},
        },
      });

      updateCalendarInStore({
        from: pickerFrom,
        to: subtractDays(pickerTo, 1),
        listingId,
        propValue: {
          blocks: { o: true },
          status: 'unavailable',
          pending: false,
          ownerReservationId: alterationData.alterationPayload.reservationId,
          ownersReservation: {
            checkIn: pickerFrom
              ? moment(pickerFrom).format('YYYY-MM-DD HH:mm:ss')
              : undefined,
            checkOut: pickerTo
              ? moment(pickerTo).format('YYYY-MM-DD HH:mm:ss')
              : undefined,
            note: note || '',
          },
        },
      });

      dismiss();
      addToast.success(t('Reservation updated successfully.'));
    }
  }, [
    addToast,
    isConfirmAlterationSuccess,
    dismiss,
    updateCalendarInStore,
    modalProps.from,
    modalProps.to,
    listingId,
    pickerFrom,
    pickerTo,
    alterationData?.alterationPayload?.reservationId,
    note,
  ]);

  useEffect(() => {
    const confirmCancelReservation = async () => {
      await postConfirmCancellation({
        ...cancellationData,
        reservationId: cancellationData.alterationPayload.reservationId,
        inquiryId: cancellationData._id,
        notes: { other: note },
        plannedArrival: moment(expectedArrivalTime, 'hh:mm A').format('HH:mm'),
        plannedDeparture: moment(expectedDepartureTime, 'hh:mm A').format(
          'HH:mm'
        ),
      });
    };

    if (isCancellationSuccess) {
      confirmCancelReservation();
    }
  }, [
    cancellationData,
    isCancellationSuccess,
    postConfirmCancellation,
    note,
    expectedArrivalTime,
    expectedDepartureTime,
  ]);

  useEffect(() => {
    if (isConfirmCancellationSuccess) {
      updateCalendarInStore({
        from: pickerFrom,
        to: subtractDays(pickerTo, 1),
        listingId,
        propValue: {
          blocks: {},
          status: 'available',
          pending: false,
          ownerReservationId: null,
          ownersReservation: {},
        },
      });

      dismiss();
      addToast.success(t('Reservation canceled successfully.'));
    }
  }, [
    addToast,
    isConfirmCancellationSuccess,
    dismiss,
    updateCalendarInStore,
    pickerFrom,
    pickerTo,
    listingId,
  ]);

  useEffect(() => {
    if (isError) {
      let errorText = '';
      if (isCreateError) {
        errorText = t('There was an error completing your reservation.');
      } else if (isUpdateError) {
        errorText = t('There was an error updating your reservation.');
      } else if (isCancelError) {
        errorText = t('There was an error cancelling your reservation.');
      }
      errorText += ' ';
      errorText += t(
        'Please try again later or contact your property manager.'
      );
      dismiss();
      addToast.danger(errorText);
    }
  }, [addToast, isError, isCreateError, isUpdateError, isCancelError, dismiss]);

  const fetchCalendarData = (date, skip = 0) => {
    date.add(skip, 'months');

    const month = date.month();
    const year = date.year();
    const { getCalendarMonthYear, getMiniData, listing } = props;

    fetchCalendarDataService(
      listing,
      month,
      year,
      {
        getCalendarMonthYear,
        getMiniData,
      },
      {
        ownersPortalAccommodationFareEnabled:
          owner?.ownersPortalSettings?.accommodationFare,
      }
    );
  };

  const handleClose = ({ startDate, endDate }) => {
    setState((prev) => ({
      ...prev,
      pickerFrom: startDate ? startDate.toDate() : null,
      pickerTo: endDate ? endDate.toDate() : null,
    }));

    setUiState((prev) => ({
      ...prev,
      focusedInput: null,
    }));
  };

  const handleFocusChange = () => {
    setUiState((prev) => ({
      ...prev,
      focusedInput,
    }));
  };

  const isDirty = () => !isEqual(state, initialState);

  const useNewOwnerReservations = window.featureToggle.isEnabled(
    USE_NEW_OWNER_RESERVATIONS
  );

  const handleCreateReservation = async () => {
    // set time from input to check-in and check-out
    pickerFrom.setHours(
      moment(expectedArrivalTime, 'hh:mm A').hour(),
      moment(expectedArrivalTime, 'hh:mm A').minutes()
    );
    pickerTo.setHours(
      moment(expectedDepartureTime, 'hh:mm A').hour(),
      moment(expectedDepartureTime, 'hh:mm A').minutes()
    );

    if (useNewOwnerReservations) {
      await postInquiry({
        unitTypeId: modalProps.listing.mtl?.p ?? modalProps.listing._id,
        checkInDateLocalized: moment(pickerFrom).format('YYYY-MM-DD'),
        checkOutDateLocalized: moment(pickerTo).format('YYYY-MM-DD'),
        guestsCount: +adults + +children + +infants,
        numberOfGuests: {
          numberOfAdults: +adults,
          numberOfChildren: +children,
          numberOfInfants: +infants,
        },
        source: 'owner',
        unitId: modalProps.listing._id,
      });
    } else {
      addReservation({
        from: pickerFrom,
        to: subtractDays(pickerTo, 1),
        checkOut: pickerTo,
        listingId,
        note,
      });

      dismiss();
    }
  };

  const handleUpdateReservation = async () => {
    // set time from input to check-in and check-out
    pickerFrom.setHours(
      moment(expectedArrivalTime, 'hh:mm A').hour(),
      moment(expectedArrivalTime, 'hh:mm A').minutes()
    );
    pickerTo.setHours(
      moment(expectedDepartureTime, 'hh:mm A').hour(),
      moment(expectedDepartureTime, 'hh:mm A').minutes()
    );

    const { note: previousNote, ownerReservationId } = modalProps;

    if (!isDirty()) {
      dismiss();
      return;
    }

    if (isOwnerReservationV2) {
      await postAlteration({
        reservationId: ownerReservationId,
        dates: {
          checkInDateLocalized: moment(pickerFrom).format('YYYY-MM-DD'),
          checkOutDateLocalized: moment(pickerTo).format('YYYY-MM-DD'),
        },
        guestsCount: +adults + +children + +infants,
        numberOfGuests: {
          numberOfAdults: +adults,
          numberOfChildren: +children,
          numberOfInfants: +infants,
        },
      });
    } else {
      updateReservation({
        from: pickerFrom,
        to: subtractDays(pickerTo, 1),
        checkOut: pickerTo,
        previousFrom: modalProps.from,
        previousTo: subtractDays(modalProps.to, 1),
        previousCheckOut: modalProps.to,
        previousNote,
        listingId,
        ownerReservationId,
        note,
      });

      dismiss();
    }
  };

  const handleCancelReservation = async () => {
    tracker.onCancelReservation(listingId);

    const { ownerReservationId, listing } = modalProps;

    if (isOwnerReservationV2) {
      await postCancellation({
        reservationId: ownerReservationId,
      });
    } else {
      cancelReservation({
        from: pickerFrom,
        to: subtractDays(pickerTo, 1),
        checkOut: pickerTo,
        ownerReservationId,
        listingId: listing._id,
        note,
      });

      dismiss();
    }
  };

  const preloadInitialMonth = () => {
    fetchCalendarData(moment(), constants.MONTHS_TO_SKIP_ON_LOAD);
  };

  const preloadNextMonth = (date) => {
    fetchCalendarData(date, constants.MONTHS_TO_SKIP_ON_NEXT);
  };

  const hasError = (value) => {
    setUiState((prev) => ({
      ...prev,
      error: !!value,
    }));
  };

  return (
    <Dialog open={isOpen} onClose={dismiss}>
      <DialogHeader>
        {modalProps.update ? t('Edit reservation') : t('New reservation')}
      </DialogHeader>

      <DialogContent>
        <Col spacing={2}>
          <Row fullWidth>
            <Col fullWidth>
              <TextField>{t('Check-in and check-out dates')}</TextField>
              <DatePicker
                disabled={isLoading}
                hasError={hasError}
                listingId={lstId}
                preloadNextMonth={preloadNextMonth}
                preloadInitialMonth={preloadInitialMonth}
                select={select}
                startDate={pickerFrom}
                endDate={pickerTo}
                updateFrom={modalProps.from}
                updateTo={modalProps.to}
                onClose={handleClose}
                focusedInput={focusedInput} // we have to pass focusedInput since we are using it in selectors
                onFocusChange={handleFocusChange}
                initialMonth={moment(modalProps.initialMonth)}
              />
            </Col>
          </Row>

          <Row spacing={2}>
            <Col fullWidth>
              <TextField>{t('Arrival time')}</TextField>
              <TimePicker
                disabled={isLoading}
                onChange={(e) => {
                  const { value } = e.target;

                  setState((prev) => ({
                    ...prev,
                    expectedArrivalTime: value,
                  }));
                }}
                value={expectedArrivalTime}
              />
            </Col>
            <Col fullWidth>
              <TextField>{t('Departure time')}</TextField>
              <TimePicker
                disabled={isLoading}
                onChange={(e) => {
                  const { value } = e.target;

                  setState((prev) => ({
                    ...prev,
                    expectedDepartureTime: value,
                  }));
                }}
                value={expectedDepartureTime}
              />
            </Col>
          </Row>
          {((modalProps.update && isOwnerReservationV2) ||
            (!modalProps.update && useNewOwnerReservations)) && (
            <Row spacing={2}>
              <Col>
                <TextField>{t('Adults')}</TextField>
                <NumberPicker
                  disabled={isLoading}
                  name="numAdults"
                  value={adults}
                  onChange={(e) => {
                    const { value } = e.target;

                    setState((prev) => ({ ...prev, adults: value }));
                  }}
                  min={1}
                />
              </Col>
              <Col>
                <TextField>{t('Children')}</TextField>
                <NumberPicker
                  disabled={isLoading}
                  name="numChildren"
                  value={children}
                  onChange={(e) => {
                    const { value } = e.target;

                    setState((prev) => ({
                      ...prev,
                      children: value,
                    }));
                  }}
                  min={0}
                />
              </Col>
              <Col>
                <TextField>{t('Infants')}</TextField>
                <NumberPicker
                  disabled={isLoading}
                  name="numInfants"
                  value={infants}
                  onChange={(e) => {
                    const { value } = e.target;

                    setState((prev) => ({ ...prev, infants: value }));
                  }}
                  min={0}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col fullWidth>
              <TextArea
                disabled={isLoading}
                placeholder={t('Comments')}
                value={note}
                onChange={(e) => {
                  const { value } = e.target;

                  setState((prev) => ({
                    ...prev,
                    note: value,
                  }));
                }}
              />
            </Col>
          </Row>
        </Col>
        <CancelReservationDialog
          isOpen={isCancelDialogOpen}
          close={() => setIsCancelDialogOpen(false)}
          cancelReservation={handleCancelReservation}
          isLoading={isLoading}
        />
      </DialogContent>

      <DialogFooter>
        {modalProps.update ? (
          <Row spacing={4} align="center" justify="end">
            <FlatButton
              disabled={isLoading}
              onClick={() => {
                setIsCancelDialogOpen(true);
              }}
            >
              {t('Cancel reservation')}
            </FlatButton>

            <RaisedButton
              isLoading={isLoading}
              disabled={error || !(pickerFrom && pickerTo)}
              onClick={() => {
                tracker.onModifyReservation(lstId);
                handleUpdateReservation();
              }}
            >
              {t('Update')}
            </RaisedButton>
          </Row>
        ) : (
          <RaisedButton
            isLoading={isLoading}
            disabled={error || !(pickerFrom && pickerTo)}
            onClick={() => {
              tracker.onConfirmReservation(lstId);
              handleCreateReservation();
            }}
          >
            {t('Reserve')}
          </RaisedButton>
        )}
      </DialogFooter>
    </Dialog>
  );
};

export default Reservation;
