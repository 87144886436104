import { connect } from 'react-redux';

import DatePicker from 'components/DatePicker';
import { getBlockedDays } from 'property/property.selectors';

const mapStateToProps = (state, props) => ({
  blockedDays: getBlockedDays(state, props),
});

export default connect(mapStateToProps, null)(DatePicker);
