import { connect } from 'react-redux';

import PoweredBy from 'components/PoweredBy';
import { getPoweredByVisibility } from 'ui/ui.selectors';

const mapStateToProps = (state) => ({
  isVisible: getPoweredByVisibility(state),
});

export default connect(mapStateToProps)(PoweredBy);
