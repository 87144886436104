import PropTypes from 'prop-types';

import { ReactComponent as BtnTrash } from '@guestyci/icons/BtnTrash.svg';
import { ReactComponent as BtnEdit } from '@guestyci/icons/BtnEdit.svg';
import TextField from '@guestyci/foundation/TextField';
import IconButton from '@guestyci/foundation/IconButton';
import t from '@guestyci/localize/t.macro';

import DatePicker from './DatePicker';

const RentalPeriodItem = ({
  from,
  to,
  requestToBook,
  onEdit,
  onDelete,
  disabled,
}) => {
  return (
    <div className="d-flex align-items-center my-3">
      <div className="mr-3">
        <DatePicker
          startDate={from}
          endDate={to}
          preloadInitialMonth={() => {}}
          preloadNextMonth={() => {}}
          disabled
        />
      </div>

      {requestToBook && (
        <TextField className="mx-3" color="secondary">
          {t('Booking requests only')}
        </TextField>
      )}

      <div className="mx-3">
        <IconButton onClick={onEdit} color="secondary" disabled={disabled}>
          <BtnEdit />
        </IconButton>
      </div>

      <div className="mx-3">
        <IconButton onClick={onDelete} color="secondary" disabled={disabled}>
          <BtnTrash />
        </IconButton>
      </div>
    </div>
  );
};

RentalPeriodItem.propTypes = {
  from: PropTypes.instanceOf(Date).isRequired,
  to: PropTypes.instanceOf(Date).isRequired,
  requestToBook: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default RentalPeriodItem;
