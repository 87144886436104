import cn from 'classnames';

const LoginLayout = ({ logo, title, subtitle, error, children }) => (
  <div className="login-container pt-57 px-55 text-center">
    {logo ? (
      <img src={logo} className="login-logo mb-52" alt="logo" />
    ) : (
      <div style={{ height: 60 }} />
    )}
    <div className="font-size-large mb-25 line-height-1">{title}</div>
    <div
      className={cn('wide mb-45', 'line-height-1', { 'text-danger': error })}
    >
      {subtitle}
    </div>
    {children}
  </div>
);

export default LoginLayout;
