import moment from 'moment';

export const getDatesDiffInDays = (from, to = new Date()) =>
  moment(to).diff(moment(from), 'days');

export const isFutureDay = (date) => moment(date).isAfter();

export const isFutureOrCurrentDate = (date, granularity) => {
  const currentDate = moment();
  const isSameGranularity = moment(date).isSame(currentDate, granularity);
  const isAfterDate = isFutureDay(date);

  return isAfterDate || isSameGranularity;
};

/**
 * The computeBlockedDates function takes in a time range and returns an array of dates that are blocked.
 *
 * @param {Date[]} currentTimeRange Determine the number of days in
 *
 * @return An array of objects with the following properties:
 */
export const computeBlockedDates = (currentTimeRange) => {
  return (currentTimeRange || []).reduce((acc, timeRange) => {
    if (!timeRange) {
      return acc;
    }

    const { from, to } = timeRange;
    let daysDelta = getDatesDiffInDays(from, to);

    const fromMomentObj = moment(from, 'YYYY-MM-DD');
    const toMomentObj = moment(to, 'YYYY-MM-DD');

    acc.push({ moment: fromMomentObj });

    while (daysDelta > 0) {
      const nextDay = moment(fromMomentObj).add(daysDelta, 'days');

      if (!moment(toMomentObj).isBefore(nextDay)) {
        acc.push({ moment: nextDay });
      }

      daysDelta -= 1;
    }

    return acc;
  }, []);
};

export const formatDate = (dateObject, formatType = 'YYYY-MM-DD') =>
  moment(dateObject).format(formatType);
