import toMomentObject from 'react-dates/lib/utils/toMomentObject';
import moment from 'moment';

/**
 * Gets previous day
 * @param {Model.Calendar} day - day from db.calendars
 * @param {Array} days - all days from calendar for current listings
 * @returns {Model.Calendar}
 */
const getPreviousDay = (day, days) => {
  const previousDate = moment(day.date).add(-1, 'days').format('YYYY-MM-DD');
  return days.find && days.find(({ date }) => date && date === previousDate);
};

const dayIsCheckout = (day, days) => {
  const previous = getPreviousDay(day, days);
  return (
    previous &&
    previous.reservation &&
    toMomentObject(day.date).isSame(
      toMomentObject(new Date(previous.reservation.checkOut)),
      'day'
    )
  );
};

const previousDayIsAvailable = (day, days) => {
  const previous = getPreviousDay(day, days);

  return previous && previous.status === 'available';
};

const isToday = (day) => toMomentObject(day.date).isSame(new Date(), 'day');

const isNotAvailable = (day) => day.status !== 'available';

const daysToBlockOnCheckOut = (days) => (day) =>
  day.date &&
  !previousDayIsAvailable(day, days) &&
  (isNotAvailable(day) || dayIsCheckout(day, days) || isToday(day));

/**
 * Creates filter for blocked days based on which is currently being selected: checkin or checkout
 * @param {Array} days
 * @param {String} focusedInput
 * @returns {function(day, index): boolean}
 */
export const getFilter = (days, focusedInput) =>
  focusedInput === 'endDate' ? daysToBlockOnCheckOut(days) : isNotAvailable;

/**
 * Formats date to ignore UTC
 * @param {Moment | Date | String} date
 * @returns {string}
 */
export const formatDateWithoutUTC = (date) =>
  moment(date).format('YYYY-MM-DD HH:mm:ss');

/**
 * Formats time to ignore UTC
 * @param {Moment} checkIn - check-in date selected in reservation modal
 * @param {Moment} checkOut - check-out date selected in reservation modal
 * @returns {{checkIn: string, checkOut: string}}
 */
export const formatDatesWithoutUTC = (checkIn, checkOut) => ({
  checkIn: formatDateWithoutUTC(checkIn),
  checkOut: formatDateWithoutUTC(checkOut),
});
