import { darkBlue900 } from '@guestyci/foundation/theme/colors';
import { Row } from '@guestyci/foundation/Layout';
import Icon from '@guestyci/foundation/Icon';
import TextField from '@guestyci/foundation/TextField';

export const HeadingWithIcon = ({ icon, text, bold, color }) => {
  return (
    <Row spacing={1} align="center">
      <Icon svg={icon} width={17} height={16} fillColor={darkBlue900} />
      <TextField variant="h4" bold={bold} color={color} data-testid="text">
        {text}
      </TextField>
    </Row>
  );
};
