import { useEffect, useState } from 'react';
import moment from 'moment';
import { START_DATE, END_DATE } from 'react-dates/constants';

import DateRangePicker from '@guestyci/foundation-legacy/DatePickerDeprecated/DateRangePicker';
// TODO: DateRangePicker is not working correctly in new foundation
// import DateRangePicker from '@guestyci/foundation/DateRangePicker';

const DATE_FORMAT = 'YYYY-MM-DD';

const DatePicker = (props) => {
  const {
    onClose,
    blockedDays,
    preloadInitialMonth,
    preloadNextMonth,
    startDate,
    endDate,
    disabled,
    hasError,
    enablePastDays,
    initialMonth,
  } = props;

  const [range, setRange] = useState({
    startDate: startDate && moment(startDate),
    endDate: endDate && moment(endDate),
  });

  const [hasRangeError, setHasRangeError] = useState(false);

  const [blockedDates, setBlockedDates] = useState(
    blockedDays?.map((day) => day.moment.format(DATE_FORMAT)) || []
  );

  useEffect(() => {
    preloadInitialMonth();
  }, [preloadInitialMonth]);

  useEffect(() => {
    if (blockedDays?.length > 0) {
      let newBlockedDates;

      if (!range.endDate) {
        newBlockedDates = blockedDays.map((day) =>
          day.moment.format(DATE_FORMAT)
        );
      } else {
        newBlockedDates = blockedDays
          .map((day) => moment(day).add(1, 'days').format(DATE_FORMAT))
          .filter((day) => day !== range.startDate.format(DATE_FORMAT));
      }

      setBlockedDates(newBlockedDates);
    }
  }, [blockedDays, range.startDate, range.endDate]);

  const handleOnClose = (...params) => {
    onClose(...params);
  };

  const onDatesChange = (value) => {
    const blockedDaysInRange = blockedDays.filter(
      (blocked) =>
        value.endDate &&
        blocked.moment.isBetween(
          moment(value.startDate),
          moment(value.endDate).subtract(1, 'days')
        )
    ).length;

    const rangeError = blockedDaysInRange || !value.endDate;
    hasError(rangeError);
    setHasRangeError(rangeError);

    setRange({
      startDate: moment(value.startDate),
      endDate: value.endDate && moment(value.endDate),
    });
  };

  let initialVisibleMonth = null;
  if (range.startDate) {
    initialVisibleMonth = moment(range.startDate).format('YYYY-MM');
  } else if (initialMonth) {
    initialVisibleMonth = initialMonth.format('YYYY-MM');
  }

  return (
    <DateRangePicker
      value={range}
      hideKeyboardShortcutsPanel
      onClose={handleOnClose}
      onChange={onDatesChange}
      onNextMonthClick={preloadNextMonth}
      disabled={disabled}
      enablePastDays={enablePastDays}
      showClearDate={false}
      initialVisibleMonth={initialVisibleMonth}
      blockedDates={blockedDates}
      startDateId={START_DATE}
      endDateId={END_DATE}
      isContinuous={blockedDates?.length}
      error={hasRangeError}
    />
  );
};

export default DatePicker;
