import PropTypes from 'prop-types';

import Icon from 'components/common/Icon';
import { getActiveString, pluralize } from 'utils/common';

const ListingCard = ({ listing }) => {
  const img =
    listing.picture && listing.picture.thumbnail ? (
      <div style={{ backgroundImage: `url(${listing.picture.thumbnail})` }} />
    ) : (
      <Icon icon="placeholder" />
    );

  return (
    <div className="bg-white p-35">
      <div className="row no-gutters align-items-center">
        <div className="col">
          <div className="row no-gutters align-items-center mb-35">
            <div className="col-auto mr-3 property-img">{img}</div>
            <div className="col">
              <div className="font-size-medium">{listing.nickname}</div>
              <small className="text-capitalize">
                {listing.bedrooms} {pluralize('bedroom', listing.bedrooms)}{' '}
                &middot; {getActiveString(listing.active)}
              </small>
            </div>
          </div>
          <small>{listing.address.full}</small>
        </div>
        <div className="col text-right property-sm property-icon">
          <Icon icon="arrow" />
        </div>
      </div>
    </div>
  );
};

ListingCard.propTypes = {
  listing: PropTypes.shape(),
};

export default ListingCard;
