import { connect } from 'react-redux';
import { compose } from 'redux';

import withReservationComponent from 'hocs/components/withReservation';
import { select } from 'property/properties.actions';
import { getDisabledDays } from 'property/calendar.selectors';
import { constants, showModal } from 'modal';
import {
  USE_NEW_MY_PROPERTIES_PAGE,
  USE_RESERVATION_DRAWER,
} from 'constants/featureToggle';

const mapStateToProps = (state, props) => ({
  // property which determines unclickable/unselectable area of calendar
  disabledDays: getDisabledDays(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  openReservationModal(props) {
    if (
      props.isOwnerReservationV2 &&
      window.featureToggle?.isEnabled(USE_NEW_MY_PROPERTIES_PAGE) &&
      window.featureToggle?.isEnabled(USE_RESERVATION_DRAWER)
    ) {
      dispatch(showModal(constants.DRAWER, props));
    } else {
      dispatch(showModal(constants.RESERVATION, props));
    }
  },
  select(props) {
    dispatch(select(props));
  },
});

const withReservation = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withReservationComponent
);

export default withReservation;
