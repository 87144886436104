import cn from 'classnames';
import get from 'lodash/get';
import set from 'lodash/set';
import find from 'lodash/find';

import { toISODateString } from 'utils/common';
import { subtractDays } from 'utils/calendar';
import {
  getEndDecorationClassName,
  getRentalPeriod,
  getStartDecorationClassName,
  isRentalPeriodElement,
  isRentalPeriodInCalendar,
  parseRentalPeriodDates,
} from 'utils/rental-period';

import CalendarDayTooltip from './CalendarDayTooltip';

const getDayStatus = (calObj) => {
  if (get(calObj, 'blocks.o')) {
    return 'booked owner-reserved';
  }

  return calObj.status;
};

const CalendarDay = ({
  listing,
  calendar,
  day,
  rentalPeriods,
  isRentalPeriodEnabled,
  updateRentalPeriod,
  onShowRentalPeriodModal,
  showReservedReservations,
}) => {
  let startDecoration;
  let endDecoration;
  const calObj = calendar && calendar[toISODateString(day)];

  if (calObj?.status === 'reserved' && !showReservedReservations) {
    calObj.status = 'unavailable';

    if (calObj.note) {
      calObj.note = '';
    }

    const reservationBlockRef =
      find(calObj.blockRefs || [], { reservationId: calObj.reservationId }) ||
      {};

    if (reservationBlockRef) {
      set(reservationBlockRef, 'reservation.status', 'unavailable');
    }
  }

  const parsedRentalPeriods = parseRentalPeriodDates(rentalPeriods);
  const isRentalPeriodDate = isRentalPeriodInCalendar(day, parsedRentalPeriods);
  const isPrevRentalPeriod = getRentalPeriod(
    subtractDays(day, 1),
    parsedRentalPeriods
  )?.requestToBook;
  const rentalPeriod = getRentalPeriod(day, parsedRentalPeriods);
  const hasRequestToBook = rentalPeriod?.requestToBook || false;

  const withRequestToBook =
    isRentalPeriodDate && isRentalPeriodEnabled && hasRequestToBook;

  let status = null;

  if (calObj) {
    status = getDayStatus(calObj);
    const prevCalObj = calendar[toISODateString(subtractDays(day, 1))];
    const prevStatus = prevCalObj && getDayStatus(prevCalObj);
    const reservationId = calObj.reservationId || calObj.ownerReservationId;
    const prevReservationId =
      prevCalObj && (prevCalObj.reservationId || prevCalObj.ownerReservationId);

    const isLastBooking = reservationId && reservationId !== prevReservationId;

    startDecoration = getStartDecorationClassName({
      status,
      prevStatus,
      withRequestToBook,
      isPrevRentalPeriod,
    });

    endDecoration = getEndDecorationClassName({
      status,
      prevStatus,
      isLastBooking,
      withRequestToBook,
      isPrevRentalPeriod,
    });
  }

  const isRentalElement = isRentalPeriodElement(
    isRentalPeriodDate,
    cn(startDecoration, endDecoration)
  );

  const rentalPeriodUpdate = async (updatedPeriod) => {
    if (!rentalPeriods || !isRentalPeriodEnabled) {
      return;
    }

    const updatedRentalPeriods = rentalPeriods.map((period) => {
      if (period._id === updatedPeriod._id) {
        return updatedPeriod;
      }

      return period;
    });

    await updateRentalPeriod({
      listingId: listing._id,
      data: { rentalPeriods: updatedRentalPeriods },
    });
  };

  const onRentalPeriodsClick = (e, date, isRentalPeriod) => {
    if (!rentalPeriods || !isRentalPeriodEnabled) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    const currentRentalPeriod = getRentalPeriod(
      date,
      parseRentalPeriodDates(rentalPeriods)
    );

    if (!isRentalPeriod || !rentalPeriod) {
      return;
    }

    onShowRentalPeriodModal({
      update: rentalPeriodUpdate,
      from: currentRentalPeriod.from,
      to: currentRentalPeriod.to,
      requestToBook: currentRentalPeriod.requestToBook,
      rentalPeriodId: currentRentalPeriod._id,
      rentalPeriods,
      _localGenerated: false,
    });
  };

  return (
    <CalendarDayTooltip calObj={calObj}>
      {
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className={cn(
            'DayPicker-Day--wrap pt-2 pr-2 pt-sm-3 pr-sm-3',
            startDecoration
          )}
          onClick={(e) => {
            onRentalPeriodsClick(e, day, isRentalElement);
          }}
        >
          {startDecoration && (
            <div className={cn('DayPicker-Day--range', startDecoration)} />
          )}
          {endDecoration && (
            <div className={cn('DayPicker-Day--range', endDecoration)} />
          )}
          <span>{day.getDate()}</span>
        </div>
      }
    </CalendarDayTooltip>
  );
};

export default CalendarDay;
