import moment from 'moment';
import get from 'lodash/get';

import { featureToggleInit } from '@guestyci/feature-toggle-fe';
import Resource from '@guestyci/agni';

import { getListings } from 'property/properties.actions';
import { USE_NEW_MY_PROPERTIES_PAGE } from 'constants/featureToggle';

export const initFeatureToggle = async () => {
  try {
    const { env } = Resource.create();
    const { accountId } = Resource.getCurrentUser();

    window.featureToggle = await featureToggleInit({
      env,
      accountId: accountId || get(window, ['appConfig', 'accountId']),
    });
  } catch (err) {
    console.error(err);
  }
};
export const fetchInitData = async (dispatch) => {
  await initFeatureToggle();
  if (!window.featureToggle.isEnabled(USE_NEW_MY_PROPERTIES_PAGE)) {
    dispatch(getListings({ sort: 'nickname' }));
  }
};

export const initMoment = (locale) => {
  moment.defineLocale('en-US-nonAmericanDates', {
    parentLocale: 'en-US',
  });
  moment.updateLocale('en-US-nonAmericanDates', {
    longDateFormat: {
      L: 'DD/MM/YYYY',
      LL: 'MMMM D, YYYY',
      LLL: 'MMMM D, YYYY h:mm A',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      LT: 'h:mm A',
      LTS: 'h:mm:ss A',
    },
  });

  moment.locale(locale);
};
