import { Accordion, AccordionItem } from 'react-sanfona';
import cn from 'classnames';

import { Tabs, Tab } from '@guestyci/foundation-legacy/Tabs';
import t from '@guestyci/localize/t.macro';

import { getCurrentMonthYearKey, getDateFromKey } from 'utils/common';
import Page from 'pages/Page';
import Icon from 'components/common/Icon';
import Calendar from 'containers/Calendar.container';
import ListingCard from 'components/ListingCard';
import ListingRow from 'components/ListingRow';
import { fetchCalendarData } from 'property/properties.services';
import withAuthentication from 'components/withAuthentication';
import AppSpinner from 'components/AppSpinner';
import LazyLoader from 'components/common/LazyLoader';
import RentalPeriod from 'containers/RentalPeriod.container';
import RentalPeriodAvailabilityWrapper from 'containers/RentalPeriodAvailabilityWrapper.container';
import tracker from 'utils/dio/my-properties-tracker';

const MyProperties = ({
  getCalendarMonthYear,
  getMiniData,
  addActiveListing,
  removeActiveListing,
  getListings,
  setViewCal,
  isFetching,
  isMediumScreen,
  isSmallScreen,
  listings,
  listingsCount,
  activeListingIndex,
  ownersPortalAccommodationFareEnabled,
}) => {
  const onExpand = (listing, initialMonth) => {
    const month = initialMonth.getMonth();
    const year = initialMonth.getFullYear();
    addActiveListing(listing._id);

    fetchCalendarData(
      listing,
      month,
      year,
      {
        getCalendarMonthYear,
        getMiniData,
      },
      { ownersPortalAccommodationFareEnabled }
    );
  };

  const onClose = (listing) => {
    removeActiveListing(listing._id);
  };

  const onMonthChange = (listing, date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const listingId = listing._id;
    setViewCal(listingId, month, year);

    fetchCalendarData(
      listing,
      month,
      year,
      {
        getCalendarMonthYear,
        getMiniData,
      },
      { ownersPortalAccommodationFareEnabled }
    );
  };

  const loadMoreLisitngs = () => {
    const listingsLength = listings.length;
    if (
      typeof getListings !== 'function' ||
      listingsCount === listingsLength ||
      isFetching
    ) {
      return;
    }

    getListings({ skip: listingsLength, sort: 'nickname' });
  };

  const renderListing = (listing, index) => {
    const isLast = index === listings.length - 1;
    const monthKey = listing.viewCal || getCurrentMonthYearKey();
    const initialMonth = getDateFromKey(monthKey);

    const content = isMediumScreen ? (
      <ListingCard listing={listing} />
    ) : (
      <ListingRow listing={listing} />
    );
    const wrapperClassName = isMediumScreen ? 'px-35 pb-45' : 'p-45';
    const expanded = activeListingIndex.indexOf(index) > -1;

    return (
      <AccordionItem
        title={<div>{content}</div>}
        key={index}
        className={cn({
          'mb-25 mb-sm-35': isMediumScreen,
          separator: !isMediumScreen && !isLast,
        })}
        onExpand={() => {
          tracker.onShowListingCalendar(listing._id);
          onExpand(listing, initialMonth);
        }}
        onClose={() => {
          tracker.onHideListingCalendar(listing._id);
          onClose(listing);
        }}
        expanded={expanded}
      >
        {expanded && (
          <div className={wrapperClassName}>
            <RentalPeriodAvailabilityWrapper
              listingId={listing._id}
              showSpinner
            >
              {({ isRentalPeriodEnabled }) => {
                if (isRentalPeriodEnabled) {
                  return (
                    <Tabs className="custom-tabs-container">
                      <Tab title={t('Calendar')}>
                        <Calendar
                          listing={listing}
                          onMonthChange={(date) => onMonthChange(listing, date)}
                          isRentalPeriodEnabled={isRentalPeriodEnabled}
                        />
                      </Tab>
                      <Tab title={t('Rental periods')}>
                        <RentalPeriod
                          listing={listing}
                          onCancel={() => onClose(listing)}
                        />
                      </Tab>
                    </Tabs>
                  );
                }

                return (
                  <Calendar
                    listing={listing}
                    onMonthChange={(date) => onMonthChange(listing, date)}
                    isRentalPeriodEnabled={isRentalPeriodEnabled}
                  />
                );
              }}
            </RentalPeriodAvailabilityWrapper>
          </div>
        )}
      </AccordionItem>
    );
  };

  let content;
  if (listings.length) {
    content = (
      <div
        className={cn({
          'px-5 pt-5 my-properties-table flex-1': !isMediumScreen,
        })}
      >
        {!isMediumScreen && (
          <div className="row no-gutters py-35 px-5 my-properties-header">
            <span className="text-uppercase text-muted font-size-sm mr-35 property-img">
              {t('properties')} ({listingsCount})
            </span>
            <span className="text-uppercase text-muted font-size-sm mr-35 property-flex">
              &nbsp;
            </span>
            <span className="text-uppercase text-muted font-size-sm mr-6 property-flex">
              {t('address')}
            </span>
            <span className="text-uppercase text-muted font-size-sm mr-35 property-md">
              {t('bedrooms')}
            </span>
            <span className="text-uppercase text-muted font-size-sm mr-35 property-md">
              {t('status')}
            </span>
            <span className="text-uppercase text-muted font-size-sm property-sm">
              &nbsp;
            </span>
          </div>
        )}
        <Accordion allowMultiple>{listings.map(renderListing)}</Accordion>
      </div>
    );
  } else {
    content = (
      <div className="stretch d-flex flex-column justify-content-center align-items-center">
        <p className="font-size-h4 font-weight-light mb-2">
          {t(
            'There are no properties associated with your account, if this is a mistake please contact us'
          )}
        </p>
        <p className="mb-55">{t('Please contact your account manager')}</p>
        <Icon icon="no-properties-filler" />
      </div>
    );
  }

  return (
    <Page
      className="my-properties"
      layoutContentClassName={cn({
        'my-properties-layout pb-0': isMediumScreen || isSmallScreen,
      })}
      layoutContentInnerClassName={cn('overflow-hidden h-100 pb-0')}
      title={t('My properties')}
    >
      <LazyLoader
        className={cn('h-100 overflow-auto', {
          'mb-55': !isSmallScreen && !isMediumScreen,
          'mb-3': isSmallScreen || isMediumScreen,
        })}
        hasMore={!isFetching && listingsCount > listings.length}
        loadMore={() => {
          loadMoreLisitngs();
        }}
      >
        {content}
      </LazyLoader>
      {isFetching && <AppSpinner />}
    </Page>
  );
};

export default withAuthentication(MyProperties);
