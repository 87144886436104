import { DateUtils } from 'react-day-picker';
import get from 'lodash/get';
import moment from 'moment';

import { fromISODateString } from 'utils/common';

export const getDays = (calendar, param, value) =>
  calendar &&
  Object.keys(calendar).reduce(
    (prev, curr) =>
      calendar[curr] && get(calendar[curr], param) === value
        ? [...prev, fromISODateString(curr)]
        : prev,
    []
  );

export const addDays = (day, amount) => {
  if (!day) {
    return null;
  }

  const nextDay = new Date(day);
  nextDay.setMonth(day.getMonth());
  nextDay.setDate(day.getDate() + amount);

  return nextDay;
};

export const subtractDays = (day, amount) => addDays(day, -amount);

export const hasAnyDateFromRange = (data, from, to) =>
  data && data.filter((date) => DateUtils.isDayBetween(date, from, to)).length;

export const addMonth = (day) =>
  moment(new Date(day)).add(1, 'months').toDate();

export const parseISO = (s) => {
  const b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
};

export const resetHoursForDate = (date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const isDateBetweenDates = ({ date, from, to, isStrongEqual }) => {
  const modifiedDate = resetHoursForDate(date);
  const modifiedFrom = resetHoursForDate(from);
  const modifiedTo = resetHoursForDate(to);

  if (!isStrongEqual) {
    return modifiedDate < modifiedTo && modifiedDate > modifiedFrom;
  }
  return modifiedDate <= modifiedTo && modifiedDate >= modifiedFrom;
};
