import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import LocalizationProvider from '@guestyci/localize/LocalizationProvider';

import ScrollToTop from 'components/common/ScrollToTop';
import DirtyWarningDialog from 'components/DirtyWarningDialog';
import navItems from 'navItems';
import routes from 'routes';
import { RootModal } from 'modal';
import { initMoment } from 'initData';
// eslint-disable-next-line no-restricted-imports
import NavBar from 'pages/layout/app/components/NavBar';
import localStorage from 'utils/localStorage';

import AppSpinner from './AppSpinner';

export default class App extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const winWidth = window.innerWidth;
    const { hash, locale, initState } = this.props;

    this.props.onLoad({
      navItems: this.filterNavItems(navItems),
      winWidth,
      hash,
      initState,
      locale,
    });

    window.addEventListener('resize', () => {
      this.props.onLoad({ winWidth: window.innerWidth });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      userLanguage,
      currentLocale,
      isAuthenticated,
      portalSettings,
      onLoad,
      onSetCurrentLocale,
    } = this.props;

    if (
      !isEqual(prevProps.portalSettings, portalSettings) ||
      prevProps.isAuthenticated !== isAuthenticated
    ) {
      onLoad({ navItems: this.filterNavItems(navItems) });
    }

    // re-init translations & moment after user is authenticated,
    // so we can retrieve his saved lang from settings
    if (
      !prevProps.isAuthenticated &&
      isAuthenticated &&
      currentLocale !== userLanguage
    ) {
      onSetCurrentLocale(userLanguage);
      localStorage.setLanguage(userLanguage);
      initMoment(userLanguage);
    }
  }

  filterNavItems = (items) => {
    const { portalSettings, isGuestsReportSettingEnabled } = this.props;

    return items.filter((navItem) =>
      navItem.toShow({ portalSettings, isGuestsReportSettingEnabled })
    );
  };

  render() {
    const {
      isFetching,
      history,
      warnDirty,
      isLoaderVisible,
      hasNavbar = true,
      isGuestsReportSettingEnabled,
      isBookingRequestSettingEnabled,
      userLanguage,
      portalSettings,
    } = this.props;

    if (isFetching || isLoaderVisible) {
      return <AppSpinner />;
    }

    const routeElements = routes
      .filter((route) =>
        route.isAvailable({
          isGuestsReportSettingEnabled,
          isBookingRequestSettingEnabled,
          portalSettings,
        })
      )
      .map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          component={route.component}
        />
      ))
      .concat(
        // TODO: is this not compatible with @guestyci/history? (zbo)
        <Redirect
          key="/"
          exact
          from="/"
          to={{
            pathname: '/my-properties',
            state: { from: '/' },
          }}
        />
      )
      .concat(<Redirect key="*" to="/404" />);

    const language =
      userLanguage === 'en-US-nonAmericanDates'
        ? 'en-US'
        : userLanguage || 'en-US';

    return (
      <LocalizationProvider language={language}>
        <ScrollToTop>
          <div id="app" className="main">
            {hasNavbar && <NavBar />}
            <Suspense fallback={<AppSpinner />}>
              <Switch>{routeElements}</Switch>
            </Suspense>
            {warnDirty && (
              <DirtyWarningDialog
                to={warnDirty}
                handleOk={() => {
                  this.props.onDirtyAnswer(warnDirty, history.push);
                }}
                handleClose={() => {
                  this.props.onDirtyAnswer();
                }}
              />
            )}
            <RootModal />
          </div>
        </ScrollToTop>
      </LocalizationProvider>
    );
  }
}
