import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

import { Section, Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import Tooltip from '@guestyci/foundation/Tooltip';
import { BtnHelp } from '@guestyci/icons';
import t from '@guestyci/localize/t.macro';

import {
  chartTypes,
  getChartTypeNameString,
  getChartTypeTooltipString,
} from 'property/properties.actions';
import {
  getFormatter,
  getPriceStr,
  getRevenueLabel,
  getReservationStatusString,
} from 'utils/common';
import { isFutureOrCurrentDate } from 'utils/dates';
import Spinner from 'components/common/Spinner';
import Stats from 'containers/Stats.container';
import { USE_ACCOUNTING_FLOW } from 'constants/featureToggle';

const RenderWithSpinner = ({ children, showSpinner }) =>
  showSpinner ? <Spinner small /> : children;

const CalendarStats = (props) => {
  const {
    loading,
    revenue,
    accountCurrency,
    revenueByReservationStatus,
    initialMonth,
    ...stats
  } = props;
  const isFutureDate = isFutureOrCurrentDate(initialMonth, 'month');
  const revenueLabel = getRevenueLabel(isFutureDate);
  const useRAS = !window.featureToggle?.isEnabled(USE_ACCOUNTING_FLOW);
  const { canceled, confirmed, ...rest } = revenueByReservationStatus;
  const canceledPrice = getPriceStr(canceled || 0, accountCurrency);
  const confirmedPrice = getPriceStr(confirmed || 0, accountCurrency);
  const otherStatuses = Object.keys(rest);
  const body = (
    <Section justify="fill-content" className="revenue-tooltip-container">
      {useRAS ? (
        <Col spacing={3} fullWidth>
          <TextField className="title-no-border">{revenueLabel}</TextField>
          <TextField className="tooltip-description-no-border">
            <TextField>
              {t(
                'This is the amount you will earn on confirmed and cancelled reservations for the time period you selected. We calculate the amount based on the revenue share you agreed to with your property manager.'
              )}
            </TextField>
          </TextField>
        </Col>
      ) : (
        <Col fullWidth>
          <TextField className="mb-3 pb-3 title">{revenueLabel}</TextField>
          <Row align="center" justify="between">
            <TextField className="key-name">
              {startCase(t('confirmed'))}
            </TextField>
            <TextField>{confirmedPrice}</TextField>
          </Row>

          {otherStatuses.length > 0 &&
            otherStatuses.map((status) => (
              <Row align="center" justify="between" fullWidth>
                <TextField className="key-name">
                  {startCase(getReservationStatusString(status))}
                </TextField>
                <TextField>
                  {getPriceStr(rest[status], accountCurrency)}
                </TextField>
              </Row>
            ))}

          {canceled !== 0 && (
            <Row align="center" justify="between">
              <TextField className="key-name">
                {startCase(t('canceled'))}
              </TextField>
              <TextField>{canceledPrice}</TextField>
            </Row>
          )}

          <TextField className="mt-3 pt-3 tooltip-description">
            <TextField>
              {t(
                'This is the amount you will earn on confirmed and cancelled reservations for the time period you selected. We calculate the amount based on the revenue share you agreed to with your property manager.'
              )}
            </TextField>
          </TextField>
        </Col>
      )}
    </Section>
  );

  return (
    <Stats className="d-flex text-center my-4 my-sm-0">
      {Object.keys(chartTypes)
        .filter((type) => useRAS || type !== chartTypes.ACCOMMODATION_FARE)
        .slice(0, -1)
        .map(camelCase)
        .map((stat) => (
          <Stats.Stat stat={stat} key={stat}>
            <div className="row mr-5">
              <div className="font-size-sm text-uppercase text-wide">
                <span>
                  <Tooltip
                    body={getChartTypeTooltipString(stat)}
                    placement="bottom"
                  >
                    {getChartTypeNameString(stat)}
                    <BtnHelp
                      fillOpacity="0.4"
                      height={16}
                      width={16}
                      className="ml-1"
                    />
                    <RenderWithSpinner showSpinner={loading}>
                      <div className="font-size-h2 font-weight-light nowrap">
                        {getFormatter(stat)(stats[stat], accountCurrency)}
                      </div>
                    </RenderWithSpinner>
                  </Tooltip>
                </span>
              </div>
            </div>
          </Stats.Stat>
        ))}

      <Stats.Stat stat="revenue">
        <div className="row">
          <div className="font-size-sm text-uppercase text-wide">
            <span>
              <Tooltip body={body} placement="bottom start">
                {revenueLabel}
                <BtnHelp
                  fillOpacity="0.4"
                  height={16}
                  width={16}
                  className="ml-1"
                />
                <RenderWithSpinner showSpinner={loading}>
                  <div className="font-size-h2 font-weight-light nowrap">
                    {revenue}
                  </div>
                </RenderWithSpinner>
              </Tooltip>
            </span>
          </div>
        </div>
      </Stats.Stat>
    </Stats>
  );
};

export default CalendarStats;

CalendarStats.propTypes = {
  loading: PropTypes.bool.isRequired,
  bookedNights: PropTypes.string.isRequired,
  revenue: PropTypes.string.isRequired,
  accountCurrency: PropTypes.string.isRequired,
  revenueByReservationStatus: PropTypes.shape({
    confirmed: PropTypes.number,
    canceled: PropTypes.number,
  }).isRequired,
  initialMonth: PropTypes.instanceOf(Date).isRequired,
};
