import { createApi } from '@reduxjs/toolkit/query/react';

import Resource from '@guestyci/agni';

let cachedApi = null;

const dynamicBaseQuery = async (args, api, extraOptions) => {
  if (!cachedApi) {
    cachedApi = Resource.create({
      domain: 'revenue-analytics',
      development: true,
    }).api;
  }

  return cachedApi(args, api, extraOptions);
};

export const revenueAnalyticsApi = createApi({
  reducerPath: 'revenueAnalyticsApi',
  baseQuery: dynamicBaseQuery,
  endpoints: ({ query }) => ({
    getOwnerAnalytics: query({
      query: ({
        startDate,
        endDate,
        listingIds = null,
        confirmedOnly = true,
      }) => ({
        url: '/analytics/owner',
        params: {
          from: startDate,
          to: endDate,
          listingIds: listingIds?.join(','),
          confirmedOnly,
        },
      }),
      providesTags: ['analytics-owner'],
    }),
  }),
});

export const { useGetOwnerAnalyticsQuery } = revenueAnalyticsApi;
