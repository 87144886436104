import { EventTypes } from 'redux-segment';

import { getDatesDiffInDays } from 'utils/dates';

const filterListWithFalseItems = (list) => list.filter((item) => !!item);

export const withSegmentTracking = (action, analyticsList) => {
  const analytics =
    analyticsList && analyticsList.length
      ? filterListWithFalseItems(analyticsList)
      : null;

  if (analytics && analytics.length) {
    return { ...action, meta: { analytics: [...analytics] } };
  }
  return { ...action };
};

export const identifyUser = (user) => {
  if (user && user.accountId) {
    return {
      eventType: EventTypes.identify,
      eventPayload: {
        userId: user._id,
        traits: {
          name: user.fullName,
          username: user.fullName,
          email: user.email,
          displayName: user.fullName,
          accountId: user.accountId,
          userId: user._id,
          accountName: user.account.name,
          userSeniority: getDatesDiffInDays(user.createdAt, new Date()),
          userRoles: user.roles,
          accountStatus: user.active,
          accountCategorization: user.account.accountCategorization,
        },
      },
    };
  }
  return undefined;
};

export const trackLogin = (user) => {
  if (user && user.accountId) {
    return {
      eventType: EventTypes.track,
      eventPayload: {
        event: 'LOGIN',
        userId: user._id,
      },
    };
  }

  return undefined;
};

export const trackLogout = {
  eventType: EventTypes.track,
  eventPayload: {
    event: 'LOGOUT',
  },
};
