import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import find from 'lodash/find';

import { featureToggle } from '@guestyci/feature-toggle-fe';
import Tooltip from '@guestyci/foundation/Tooltip';
import { ReactComponent as IcoNotes } from '@guestyci/icons/IcoNotes.svg';
import { Row } from '@guestyci/foundation/Layout';
import Icon from '@guestyci/foundation/Icon';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

import {
  USE_ACCOUNTING_FLOW,
  SHOW_NET_RENTAL_INCOME,
} from 'constants/featureToggle';
import { getChannelIcon } from 'constants/channelsMapper';
import { getPriceStr, getRevenueLabel } from 'utils/common';
import { isFutureDay } from 'utils/dates';
import {
  getOwnersPortalRevenueSetting,
  getOwnersPortalAccommodationFareSetting,
  getOwnersPortalShowInternalNotesForBlocks,
  getOwnersPortalShowHostPayout,
} from 'user/user.selectors';
import { getAccountCurrency } from 'property/calendar.selectors';
import { getOwnersPortalShowReservationTooltipFullName } from 'ui/ui.selectors';

const getReservationInformation = (calObj) => {
  const reservationBlockRef =
    find(calObj.blockRefs || [], { reservationId: calObj.reservationId }) || {};

  const guestFullName = get(
    reservationBlockRef,
    'reservation.guest.fullName',
    ''
  );
  const platform = get(
    reservationBlockRef,
    'reservation.integration.platform',
    ''
  );
  const source = get(reservationBlockRef, 'reservation.source', '');

  return {
    reservationBlockRef,
    guestFullName,
    platform,
    source,
  };
};

export const getRevenue = (calObj, accountCurrency) => {
  const reservationCurrency = get(calObj, 'reservation.money.currency');
  const newAccountingRevenue = get(
    calObj,
    'reservation.accounting.analytics.ownerRevenueInAccountCurrency'
  );
  const oldAccountingPMCRevenue = get(
    calObj,
    'reservation.accounting.balances.owners'
  );
  const legacyRevenue = get(calObj, 'reservation.money.ownerRevenue');
  const oldAccountingOwnerRevenue = !isNil(oldAccountingPMCRevenue)
    ? oldAccountingPMCRevenue * -1
    : oldAccountingPMCRevenue;
  const accountingValue = isNil(newAccountingRevenue)
    ? oldAccountingOwnerRevenue
    : newAccountingRevenue;
  const accountingRevenue = isNil(accountingValue)
    ? legacyRevenue
    : accountingValue;
  const revenueCurrency = isNil(accountingValue)
    ? reservationCurrency
    : accountCurrency;

  return {
    accountingRevenue,
    revenueCurrency,
    legacyRevenue,
    reservationCurrency,
  };
};

const getAccountRevenue = (calObj, accountCurrency, isAccountingFTEnabled) => {
  const {
    accountingRevenue,
    revenueCurrency,
    legacyRevenue,
    reservationCurrency,
  } = getRevenue(calObj, accountCurrency);
  const revenueAmount = isAccountingFTEnabled
    ? (accountingRevenue ?? legacyRevenue ?? t('N/A'))
    : (legacyRevenue ?? accountingRevenue ?? t('N/A'));
  const currency = isAccountingFTEnabled
    ? revenueCurrency || reservationCurrency || t('N/A')
    : reservationCurrency || revenueCurrency || t('N/A');

  return {
    revenueAmount,
    currency,
  };
};

const getTooltipText = (
  calObj,
  ownersPortalRevenueEnabled,
  ownersPortalAccommodationFareEnabled,
  accountCurrency,
  showInternalNotesForBlocksEnabled,
  showGuestFullNameEnabled,
  showHostPayoutEnabled,
  isAccountingFTEnabled,
  showNetRentalIncome
) => {
  if (calObj && (calObj.status === 'booked' || calObj.status === 'reserved')) {
    const { reservationBlockRef, guestFullName, platform, source } =
      getReservationInformation(calObj);

    const { revenueAmount, currency } = getAccountRevenue(
      calObj,
      accountCurrency,
      isAccountingFTEnabled
    );
    const checkOut = get(calObj, 'reservation.checkOut', '');
    const nights = get(reservationBlockRef, 'reservation.nightsCount', '');
    const isFutureDate = isFutureDay(checkOut);
    const revenueLabel = getRevenueLabel(isFutureDate);
    const statusLabel =
      calObj.status === 'booked' ? t('Confirmed') : t('Requested');

    const nightString = `${nights} ${t(
      '{num, plural, one {night} other {nights}}',
      {
        num: nights,
      }
    )}`;

    return (
      <div className="calendar-tooltip revenue-tooltip-container">
        {calObj.reservation?.confirmationCode && (
          <div className="mb-3 pb-3 title">
            {calObj.reservation.confirmationCode}
          </div>
        )}
        <table>
          <tbody>
            <tr>
              <td className="key-name">{t('Status')}</td>
              <td className={`status-value ${calObj.status}`}>{statusLabel}</td>
            </tr>

            <tr>
              <td className="key-name">{t('Number of nights')}</td>
              <td>{nightString}</td>
            </tr>

            {showGuestFullNameEnabled ? (
              <tr>
                <td className="key-name">{t("Guest's Name")}</td>
                <td>{guestFullName}</td>
              </tr>
            ) : null}

            <tr>
              <td className="key-name">{t('Guests')}</td>
              <td>{calObj.reservation?.guestsCount ?? 1}</td>
            </tr>

            {calObj.reservation && ownersPortalRevenueEnabled ? (
              <tr>
                <td className="key-name">{revenueLabel}</td>
                <td>{getPriceStr(revenueAmount, currency)}</td>
              </tr>
            ) : null}

            {calObj.reservation &&
            !showNetRentalIncome &&
            ownersPortalAccommodationFareEnabled ? (
              <tr>
                <td className="key-name">{t('Accommodation fare')}</td>
                <td>
                  {getPriceStr(
                    calObj.reservation.money.fareAccommodation,
                    currency
                  )}
                </td>
              </tr>
            ) : null}

            {calObj.reservation &&
            showNetRentalIncome &&
            isAccountingFTEnabled &&
            ownersPortalAccommodationFareEnabled ? (
              <tr>
                <td className="key-name">{t('Rental income')}</td>
                <td>
                  {calObj.reservation?.accounting?.analytics?.netRentalIncome
                    ? getPriceStr(
                        calObj.reservation.accounting.analytics.netRentalIncome,
                        currency
                      )
                    : t('N/A')}
                </td>
              </tr>
            ) : null}

            {reservationBlockRef?.reservation && showHostPayoutEnabled && (
              <tr>
                <td className="key-name">{t('Total payout')}</td>
                <td className="d-flex align-items-center justify-content-inherit">
                  <span className="source-value ml-1">
                    {reservationBlockRef.reservation.money?.hostPayout
                      ? getPriceStr(
                          reservationBlockRef.reservation.money.hostPayout,
                          currency
                        )
                      : t('N/A')}
                  </span>
                </td>
              </tr>
            )}

            <tr>
              <td className="key-name">{t('Source')}</td>
              <td className="d-flex align-items-center justify-content-inherit">
                <Icon {...getChannelIcon(platform)} />
                <span className="source-value ml-1">{source}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  if (
    calObj &&
    calObj.status === 'unavailable' &&
    showInternalNotesForBlocksEnabled
  ) {
    const blockRefNote = calObj?.note;

    if (!blockRefNote) {
      return null;
    }

    return (
      <Row spacing={4} align="center" justify="between">
        <Icon svg={IcoNotes} alt="notes-icon" />
        <TextField>{blockRefNote}</TextField>
      </Row>
    );
  }

  return null;
};

const CalendarDayTooltip = ({ calObj, children }) => {
  const showReservationTooltips =
    useSelector((state) => state.user.showReservationTooltips) ?? true;
  const accountCurrency = useSelector((state) => getAccountCurrency(state));
  const ownersPortalRevenueEnabled = useSelector((state) =>
    getOwnersPortalRevenueSetting(state)
  );
  const ownersPortalAccommodationFareEnabled = useSelector((state) =>
    getOwnersPortalAccommodationFareSetting(state)
  );
  const showInternalNotesForBlocksEnabled = useSelector((state) =>
    getOwnersPortalShowInternalNotesForBlocks(state)
  );
  const showGuestFullNameEnabled = useSelector((state) =>
    getOwnersPortalShowReservationTooltipFullName(state)
  );
  const showHostPayoutEnabled = useSelector((state) =>
    getOwnersPortalShowHostPayout(state)
  );

  if (!showReservationTooltips) {
    return children;
  }

  const isAccountingFTEnabled = featureToggle.isEnabled(USE_ACCOUNTING_FLOW);
  const showNetRentalIncome = featureToggle.isEnabled(SHOW_NET_RENTAL_INCOME);

  return (
    <Tooltip
      body={getTooltipText(
        calObj,
        ownersPortalRevenueEnabled,
        ownersPortalAccommodationFareEnabled,
        accountCurrency,
        showInternalNotesForBlocksEnabled,
        showGuestFullNameEnabled,
        showHostPayoutEnabled,
        isAccountingFTEnabled,
        showNetRentalIncome
      )}
      placement="top"
    >
      {children}
    </Tooltip>
  );
};

export default CalendarDayTooltip;
