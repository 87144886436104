/* eslint-disable no-param-reassign */
import { createApi } from '@reduxjs/toolkit/query/react';

import Resource from '@guestyci/agni';

let cachedApi = null;

const dynamicBaseQuery = async (args, api, extraOptions) => {
  if (!cachedApi) {
    cachedApi = Resource.create({
      domain: 'owners',
      development: true,
    }).api;
  }

  return cachedApi(args, api, extraOptions);
};

export const ownersApi = createApi({
  reducerPath: 'ownersApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [
    'me',
    'me-account-brands',
    'me-account-brands-display',
    'me-guests',
  ],
  endpoints: ({ query, mutation }) => ({
    getMeAccountBrands: query({
      query: () => ({
        url: '/me/account-brands',
      }),
      providesTags: ['me-account-brands'],
    }),

    getMeAccountBrandToDisplay: query({
      query: () => ({
        url: '/me/account-brands/display',
      }),
      providesTags: ['me-account-brands-display'],
    }),

    getMeGuests: query({
      query: ({ q, skip = 0 }) => ({
        url: '/me/guests',
        params: {
          q,
          skip,
          sort: 'fullName',
        },
        serializeQueryArgs: ({ endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems) => {
          if (newItems.skip === 0) {
            currentCache.results = newItems.results;
          } else {
            currentCache.results.push(...newItems.results);
          }
          currentCache.skip = newItems.skip;
          currentCache.limit = newItems.limit;
          currentCache.count = newItems.count;
          currentCache.sort = newItems.sort;
        },
        forceRefetch({ currentArg, previousArg }) {
          return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
        },
      }),
      providesTags: ['me-guests'],
    }),

    getMe: query({
      query: () => ({
        url: '/me',
      }),
      providesTags: ['me'],
    }),

    updateMe: mutation({
      query: (data) => ({
        url: '/me',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['me'],
    }),

    // NOTE: this is used to avoid invalidation because that refreshes the page and can't go back to the same state
    updateMeGuest: mutation({
      query: (data) => ({
        url: '/me',
        method: 'PUT',
        data,
      }),
    }),
  }),
});

export const {
  useGetMeAccountBrandsQuery,
  useGetMeAccountBrandToDisplayQuery,
  useGetMeGuestsQuery,
  useGetMeQuery,
  useUpdateMeMutation,
  useUpdateMeGuestMutation,
} = ownersApi;
