import { createApi } from '@reduxjs/toolkit/query/react';

import Resource from '@guestyci/agni';

const CANCELED_STATUS_STRING = 'canceled';

let cachedApi = null;

const dynamicBaseQuery = async (args, api, extraOptions) => {
  if (!cachedApi) {
    cachedApi = Resource.create({
      domain: 'reservations-fegw',
      development: true,
    }).api;
  }

  return cachedApi(args, api, extraOptions);
};

export const reservationsApi = createApi({
  reducerPath: 'reservationsApi',
  baseQuery: dynamicBaseQuery,
  endpoints: ({ mutation }) => ({
    postInquiry: mutation({
      query: (data) => ({
        url: '/inquiries/owner',
        method: 'POST',
        data,
      }),
    }),
    postReservation: mutation({
      query: (data) => ({
        url: '/reservations/owner/confirmed',
        method: 'POST',
        data,
      }),
    }),
    postAlteration: mutation({
      query: (data) => ({
        url: '/alterations',
        method: 'POST',
        data,
      }),
    }),
    postConfirmAlteration: mutation({
      query: (data) => ({
        url: '/alterations/confirm-change',
        method: 'POST',
        data,
      }),
    }),
    postCancellation: mutation({
      query: (data) => ({
        url: '/alterations',
        method: 'POST',
        data: {
          ...data,
          status: CANCELED_STATUS_STRING,
        },
      }),
    }),
    postConfirmCancellation: mutation({
      query: (data) => ({
        url: '/alterations/confirm-change',
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  usePostInquiryMutation,
  usePostReservationMutation,
  usePostAlterationMutation,
  usePostConfirmAlterationMutation,
  usePostCancellationMutation,
  usePostConfirmCancellationMutation,
} = reservationsApi;
