import React from 'react';
import PropTypes from 'prop-types';
import first from 'lodash/first';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { isNotNilOrEmtyString } from 'utils/predicates';
import Password from 'components/common/Password';
import {
  PasswordPairValidationService,
  ERROR_MESSAGES,
  ERROR_TYPES,
  PasswordModel,
  getErrorMessageString,
} from 'services/passwordPairValidation.service';

const isValidationError = (validationResult) => {
  return (
    validationResult &&
    validationResult.hasError &&
    validationResult.errors.length
  );
};
class PasswordPair extends React.Component {
  validationService = null;

  constructor(props) {
    super(props);

    this.state = {
      passwordErrorMessage: null,
      passwordRetypeErrorMessage: null,
      password: null,
      retypePassword: null,
    };

    this.validatePassword = this.validatePassword.bind(this);
    this.validateRetypePassword = this.validateRetypePassword.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.retypePasswordChanged = this.retypePasswordChanged.bind(this);
  }

  componentDidMount() {
    this.validationService = new PasswordPairValidationService();
  }

  getValidationError(errorMessageType) {
    return (
      this.state[errorMessageType] &&
      getErrorMessageString(this.state[errorMessageType])
    );
  }

  async validate(toValidate) {
    const validationResult = await this.validationService.validate(
      ...toValidate
    );

    return isValidationError(validationResult)
      ? get(first(validationResult.errors), 'message')
      : null;
  }

  async validatePassword() {
    const passwordErrorMessage = await this.validate([
      new PasswordModel([ERROR_TYPES.MIN_LENGTH], this.state.password),
    ]);

    this.setState(() => ({ passwordErrorMessage }));
  }

  async validateRetypePassword() {
    const errorMessage = await this.validate([
      new PasswordModel([ERROR_TYPES.MIN_LENGTH], this.state.password),
      new PasswordModel([ERROR_TYPES.DONT_MATCH], this.state.retypePassword),
    ]);

    this.setState(
      // do not set error message as passwordRetypeErrorMessage if we are actually getting it from password min lenghth validation
      () => ({
        passwordRetypeErrorMessage:
          errorMessage === ERROR_MESSAGES.DONT_MATCH ? errorMessage : undefined,
        passwordErrorMessage:
          errorMessage === ERROR_MESSAGES.MIN_LENGTH ? errorMessage : undefined,
      }),
      () => this.passwordPairChanged()
    );
  }

  passwordChanged(_isValid, value) {
    this.setState({ password: value }, () => {
      const { retypePassword } = this.state;
      if (retypePassword) this.validateRetypePassword();
    });
  }

  retypePasswordChanged(_isValid, value) {
    this.setState({ retypePassword: value }, () =>
      this.validateRetypePassword()
    );
  }

  isValid() {
    return (
      isNotNilOrEmtyString(this.state.password) &&
      isNotNilOrEmtyString(this.state.retypePassword) &&
      this.state.password === this.state.retypePassword &&
      isNil(this.state.passwordErrorMessage) &&
      isNil(this.state.passwordRetypeErrorMessage)
    );
  }

  passwordPairChanged() {
    const { onChange } = this.props;
    const { password, retypePassword } = this.state;
    if (onChange) onChange(this.isValid(), password || retypePassword);
  }

  render() {
    const { password, retypePassword, info } = this.props;
    return (
      <div>
        <Password
          id={password.id}
          label={password.label}
          info={info}
          onBlur={this.validatePassword}
          onChange={this.passwordChanged}
          error={this.getValidationError('passwordErrorMessage')}
        />
        <Password
          id={retypePassword.id}
          label={retypePassword.label}
          onBlur={this.validateRetypePassword}
          onChange={this.retypePasswordChanged}
          error={this.getValidationError('passwordRetypeErrorMessage')}
        />
      </div>
    );
  }
}

PasswordPair.propTypes = {
  password: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  retypePassword: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  validationRules: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  info: PropTypes.string,
};

export default PasswordPair;
