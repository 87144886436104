import cn from 'classnames';

import { Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

const CALENDAR_LEGEND_ITEMS = [
  {
    id: '340e4de8-35e4-46b7-b612-1525fe6aacf6',
    text: () => t('Available'),
    className: 'legend-item--available',
  },
  {
    id: '0a365f03-83ca-4c9b-913b-5f31eb862aa2',
    text: () => t('Available for booking request'),
    className: 'legend-item--available-for-booking',
  },
  {
    id: '60ea310f-2168-4d38-bffd-bec546007366',
    text: () => t('Unavailable'),
    className: 'legend-item--unavailable',
  },
  {
    id: 'ba84db34-1095-4cef-9b3e-be8621f5013d',
    text: () => 'Owner stay',
    className: 'legend-item--owner-stay',
  },
  {
    id: '0cb12cd7-25e2-4ede-86e2-0ed1c8d71d78',
    text: () => t('Confirmed reservation'),
    className: 'legend-item--reservation',
  },
  {
    id: 'd60333ec-cc81-11ed-afa1-0242ac120002',
    text: () => t('Requested reservation'),
    className: 'legend-item--reserved',
  },
];

const CalendarLegendItem = ({ text, className }) => {
  return (
    <Row spacing={1} align="center">
      <span className={cn('legend-item', className)} />
      <TextField color="secondary" variant="h7">
        {text()}
      </TextField>
    </Row>
  );
};

const CalendarLegend = ({ showReservedReservations }) => {
  return (
    <Row justify="center" align="center" spacing={2}>
      {CALENDAR_LEGEND_ITEMS.filter(
        ({ id }) =>
          id !== 'd60333ec-cc81-11ed-afa1-0242ac120002' ||
          showReservedReservations
      ).map(({ id, text, className }) => {
        return (
          <CalendarLegendItem key={id} text={text} className={className} />
        );
      })}
    </Row>
  );
};

export default CalendarLegend;
