import React from 'react';
import PropTypes from 'prop-types';

// start loading new elements 150px above bottom scroll
const shouldLoadMore = ({ offsetHeight, scrollHeight, scrollTop }) =>
  scrollHeight - offsetHeight - 150 <= scrollTop;

class LazyLoader extends React.Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    if (!this.containerRef) {
      return;
    }

    if (shouldLoadMore(this.containerRef)) {
      this.props.loadMore();
    }
  }

  render() {
    const { className, children, hasMore } = this.props;

    return (
      <div
        className={className}
        onScroll={hasMore ? this.handleScroll : undefined}
        ref={(elem) => {
          this.containerRef = elem;
        }}
      >
        {children}
      </div>
    );
  }
}

LazyLoader.propTypes = {
  className: PropTypes.string,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

LazyLoader.defaultProps = {
  className: undefined,
  hasMore: false,
};

export default LazyLoader;
