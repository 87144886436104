import Reservation from 'modal/containers/Reservation.container';
import CancelReservation from 'modal/containers/CancelReservation.container';
import CreateRentalPeriod from 'modal/containers/CreateRentalPeriod.container';

import constants from '../constants';

const MODAL_COMPONENTS = {
  [constants.RESERVATION]: Reservation,
  [constants.CANCEL_RESERVATION]: CancelReservation,
  [constants.RENTAL_PERIOD]: CreateRentalPeriod,
};

const RootModal = ({ modalType, modalProps = {}, close }) => {
  if (!modalType || modalType === constants.DRAWER) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return (
    <SpecificModal
      isOpen
      close={(callback) => close(callback)}
      modalProps={modalProps}
    />
  );
};

export default RootModal;
