import React from 'react';
import PropTypes from 'prop-types';

export const Stat = ({ children }) => {
  return children;
};

const Stats = (props) => {
  const { portalSettings, children, className } = props;

  return (
    <div className={className}>
      {React.Children.map(children, (child) => {
        const { stat } = child.props;

        return portalSettings[stat] ? child : null;
      })}
    </div>
  );
};

Stats.Stat = Stat;

Stat.propTypes = {
  // This prop is used by the parent Stats component to conditionally render
  // the stat if the portalSettings allow it.
  stat: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Stats.propTypes = {
  children: PropTypes.node.isRequired,
  portalSettings: PropTypes.shape({
    bookedNights: PropTypes.bool,
    revenue: PropTypes.bool,
    revPal: PropTypes.bool,
    occupancy: PropTypes.bool,
    avgNightlyRate: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
};

export default Stats;
