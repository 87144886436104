import Resource from '@guestyci/agni';

// development option is needed to make requests going to app-{env}.guesty.com/api URL
// (or app.guesty.com/api for prod), where env is coming from the issuer of the token in localStorage
// by default agni is calling {your-domain}/api URL, which is not suitable in case of owner's portal
//
// portal, hosted on foo.guestyowners.com, will call foo.guestyowners.com/api,
// but we need to call app.guesty.com/api
//
// this option will be probably renamed to forceGateway in future releases of agni
export const createRoomAvailabilityAPI = () =>
  Resource.create({
    domain: 'rm-availability-settings-service',
    development: true,
  }).api;
