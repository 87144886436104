import { createApi } from '@reduxjs/toolkit/query/react';

import Resource from '@guestyci/agni';

let cachedApi = null;

const dynamicBaseQuery = async (args, api, extraOptions) => {
  if (!cachedApi) {
    cachedApi = Resource.create({
      domain: 'revenue-analytics-service',
      development: true,
    }).api;
  }

  return cachedApi(args, api, extraOptions);
};

export const rasApi = createApi({
  reducerPath: 'rasApi',
  baseQuery: dynamicBaseQuery,
  endpoints: ({ query }) => ({
    getAccountInfo: query({
      query: () => ({
        url: '/account-info',
      }),
      providesTags: ['ras-account-info'],
    }),

    getAccommodationFare: query({
      query: ({ startDate, endDate, owner, listingIds, groupBy }) => ({
        url: '/accommodation-fare',
        params: {
          from: startDate,
          to: endDate,
          owner_id: owner?._id,
          listing_id: listingIds && listingIds.join(','),
          group_by: groupBy,
        },
      }),
      providesTags: ['ras-accommodation-fare'],
    }),

    getAverageNightlyRate: query({
      query: ({ startDate, endDate, owner, listingIds, groupBy }) => ({
        url: '/average-nightly-rate',
        params: {
          from: startDate,
          to: endDate,
          owner_id: owner?._id,
          listing_id: listingIds && listingIds.join(','),
          group_by: groupBy,
        },
      }),
      providesTags: ['ras-average-nightly-rate'],
    }),

    getRevPal: query({
      query: ({ startDate, endDate, owner, listingIds, groupBy }) => ({
        url: '/revpal',
        params: {
          from: startDate,
          to: endDate,
          owner_id: owner?._id,
          listing_id: listingIds && listingIds.join(','),
          group_by: groupBy,
        },
      }),
      providesTags: ['ras-revpal'],
    }),

    getOwnersRevenue: query({
      query: ({ startDate, endDate, owner, listingIds, groupBy }) => ({
        url: '/owners-revenue',
        params: {
          from: startDate,
          to: endDate,
          owner_id: owner?._id,
          listing_id: listingIds && listingIds.join(','),
          group_by: groupBy,
        },
      }),
      providesTags: ['ras-owners-revenue'],
    }),
  }),
});

export const {
  useGetAccountInfoQuery,
  useGetAccommodationFareQuery,
  useGetAverageNightlyRateQuery,
  useGetRevPalQuery,
  useGetOwnersRevenueQuery,
} = rasApi;
