import React from 'react';
import Helmet from 'react-helmet';
import cn from 'classnames';
import { connect } from 'react-redux';

import Layout from 'components/Layout';
import withTransition from 'components/common/withTransition';
import { setHasNavbarAction } from 'ui/ui.actions';
import tracker from 'utils/dio/navigation-tracker';

class Page extends React.Component {
  componentDidMount() {
    const {
      location: { pathname, search, hash },
    } = window;
    const { displayNavbar, hasNavbar = true } = this.props;
    if (displayNavbar) {
      displayNavbar(hasNavbar);
    }

    tracker.onPageView(pathname, search, hash);
  }

  componentDidUpdate({ hasNavbar: prevHasNavbnar = true }) {
    const { displayNavbar, hasNavbar = true } = this.props;
    if (prevHasNavbnar !== hasNavbar && displayNavbar) {
      displayNavbar(hasNavbar);
    }
  }

  render() {
    const {
      children,
      className,
      layoutContentClassName,
      layoutContentInnerClassName,
      link,
      hasNavbar = true,
      title,
      subTitle,
    } = this.props;

    const meta = [{ name: 'apple-mobile-web-app-title', content: title }];

    meta.push({
      name: 'description',
      description: `Website for ${title}`,
    });

    return (
      <div className={cn('page', className)}>
        <Helmet title={title} link={link} meta={meta} />
        <Layout
          hasNavbar={hasNavbar}
          title={title}
          subTitle={subTitle}
          layoutContentClassName={layoutContentClassName}
          layoutContentInnerClassName={layoutContentInnerClassName}
        >
          {children}
        </Layout>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    displayNavbar: (hasNavbar) => dispatch(setHasNavbarAction(hasNavbar)),
  };
}

export default withTransition(connect(null, mapDispatchToProps)(Page));
