import React from 'react';

import t from '@guestyci/localize/t.macro';

import LoginLayout from 'components/login/LoginLayout';
import FormField from 'components/common/FormField';
import PasswordPair from 'components/common/PasswordPair';
import ButtonLoading from 'components/common/ButtonLoading';

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: false,
      value: null,
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(valid, value) {
    this.setState({ valid, value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState(() => ({ isLoading: true }));

    this.props.onRegister(this.props.hash, this.props.email, this.state.value);
  }

  render() {
    const { logo, name, email } = this.props;
    return (
      <LoginLayout
        logo={logo}
        title={`${t('Hi')}, ${name}`}
        subtitle={t("Let's start with choosing a password")}
      >
        <form onSubmit={this.onSubmit}>
          <FormField
            label={t('Email')}
            inputType="email"
            readonly
            value={email}
          />
          <PasswordPair
            password={{ id: 'pass-1', label: t('Choose password') }}
            retypePassword={{
              id: 'pass-2',
              label: t('Retype password'),
            }}
            info={t('Minimum 6 characters, no spaces')}
            onChange={this.onChange}
          />
          <ButtonLoading
            size="lg"
            className="w-100"
            isDisabled={!this.state.valid}
            loading={this.state.isLoading}
            noCheck
          >
            {t('Log in')}
          </ButtonLoading>
        </form>
      </LoginLayout>
    );
  }
}

export default Register;
