import { reducer } from 'utils/common';
import { types } from 'modal/redux/modal.actions';

const handlers = {
  [types.SHOW_MODAL](state, action) {
    return {
      ...state,
      modalType: action.modalType,
      modalProps: action.modalProps,
    };
  },

  [types.HIDE_MODAL](state) {
    return {
      ...state,
      modalType: null,
      modalProps: null,
    };
  },
};

export default reducer(handlers);
