import t from '@guestyci/localize/t.macro';

import LoginLayout from 'components/login/LoginLayout';

const CheckEmail = ({ logo }) => (
  <LoginLayout
    logo={logo}
    title={t('Check your email')}
    subtitle={
      <div className="line-height-14 mx-auto" style={{ width: 230 }}>
        {t('And follow the instructions provided to reset your password')}
      </div>
    }
  />
);

export default CheckEmail;
