export const types = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
};

export const showModal = (modalType, modalProps) => ({
  type: types.SHOW_MODAL,
  modalType,
  modalProps,
});

export const hideModal = () => ({
  type: types.HIDE_MODAL,
});
