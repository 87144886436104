import Dialog, {
  DialogHeader,
  DialogContent,
  DialogFooter,
} from '@guestyci/foundation/Dialog';
import { Row } from '@guestyci/foundation/Layout';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import FlatButton from '@guestyci/foundation/FlatButton';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

const CancelReservationDialog = (props) => {
  const { cancelReservation, close, isOpen, isLoading } = props;

  const handleCancelReservation = async () => {
    await cancelReservation();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (!isLoading) {
          close();
        }
      }}
    >
      <DialogHeader>{t('Cancel reservation')}</DialogHeader>

      <DialogContent>
        <TextField>
          {t('Are you sure you want to cancel the reservation?')}
        </TextField>
      </DialogContent>

      <DialogFooter>
        <Row spacing={4} align="center" justify="end">
          <FlatButton disabled={isLoading} onClick={close}>
            {t('Keep reservation')}
          </FlatButton>

          <RaisedButton isLoading={isLoading} onClick={handleCancelReservation}>
            {t('Yes, cancel reservation')}
          </RaisedButton>
        </Row>
      </DialogFooter>
    </Dialog>
  );
};

export default CancelReservationDialog;
