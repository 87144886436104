import { connect } from 'react-redux';

import CreateRentalPeriod from '../components/CreateRentalPeriod';
import { hideModal } from '../redux/modal.actions';

const mapDispatchToProps = (dispatch) => ({
  close(onClose) {
    dispatch(hideModal());
    if (typeof onClose === 'function') onClose();
  },
});

export default connect(null, mapDispatchToProps)(CreateRentalPeriod);
