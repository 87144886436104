/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import get from 'lodash/get';
import assign from 'lodash/assign';
import mapValues from 'lodash/mapValues';

import t from '@guestyci/localize/t.macro';

import { isNot } from 'utils/predicates';

const DEFAULT_RULES = {
  MIN_LENGTH: 6,
};

export const ERROR_MESSAGES = {
  DONT_MATCH: 'Passwords do not match',
  MIN_LENGTH: 'Password must contain at least 6 characters',
  DEFAULT: 'You have misspelling in your password',
};

export const getErrorMessageString = (errorType) => {
  switch (errorType.toUpperCase()) {
    case ERROR_MESSAGES.DONT_MATCH:
      return t('Passwords do not match');
    case ERROR_MESSAGES.MIN_LENGTH:
      return t('Password must contain at least 6 characters');
    case ERROR_MESSAGES.DEFAULT:
      return t('You have misspelling in your password');
    default:
      return errorType;
  }
};

export const ERROR_TYPES = mapValues({ ...ERROR_MESSAGES }, (v, k) => k);

export class ErrorModel {
  type = null;

  message = null;

  constructor(type, message) {
    if (type) this.type = type;
    if (message) this.message = message;
  }
}

export class ValidationErrorModel {
  hasError = true;

  errors = [];

  constructor(errors) {
    if (errors) this.errors = [...errors];
  }
}

export class ValidationSuccessModel {
  hasError = false;

  message = null;

  constructor(message) {
    if (message) this.message = message;
  }
}

export class PasswordModel {
  supportedRules;

  value;

  constructor(supportedRules, value) {
    if (supportedRules) this.supportedRules = supportedRules;
    if (value) this.value = value;
  }
}

export class PasswordPairValidationService {
  rules = { ...DEFAULT_RULES };

  errors = [];

  constructor(rules) {
    if (rules) this.rules = assign(this.rules, rules);
  }

  getAnErrorMessage(errorType) {
    return get(ERROR_MESSAGES, errorType, ERROR_MESSAGES.DEFAULT);
  }

  hasValue(pass) {
    return Boolean(pass && pass.value && pass.value.length);
  }

  checkMatch(pass, passPair) {
    const match = !!pass && !!passPair && pass === passPair;

    if (match) {
      return true;
    }
    this.addError(ERROR_TYPES.DONT_MATCH);
    return false;
  }

  checkLength(pass) {
    if (pass.length >= this.rules.MIN_LENGTH) {
      return true;
    }
    this.addError(ERROR_TYPES.MIN_LENGTH);
    return false;
  }

  addError(errorType) {
    const anErrorType = get(ERROR_TYPES, errorType, ERROR_TYPES.DEFAULT);
    this.errors.push(
      new ErrorModel(anErrorType, this.getAnErrorMessage(anErrorType))
    );
  }

  checkRule(rule, pass, passPair) {
    switch (rule) {
      case ERROR_TYPES.DONT_MATCH:
        return this.checkMatch(pass, passPair);
      case ERROR_TYPES.MIN_LENGTH:
        return this.checkLength(pass);
      default:
        return true;
    }
  }

  clearErrors() {
    this.errors = [];
  }

  doesAllRulesPassed(pass, passValue, passPairValue) {
    this.clearErrors();
    return pass && pass.supportedRules
      ? pass.supportedRules.every((rule) =>
          this.checkRule(rule, passValue, passPairValue)
        )
      : true;
  }

  hasPassErrors(pass) {
    if (this.hasValue(pass)) {
      return isNot(this.doesAllRulesPassed(pass, pass.value));
    }
    return false;
  }

  hasPassPairErrors(pass, passPair) {
    if (this.hasValue(pass) && this.hasValue(passPair)) {
      return isNot(
        this.doesAllRulesPassed(passPair, pass.value, passPair.value)
      );
    }
    return false;
  }

  validate(pass, passPair) {
    return new Promise((resolve) => {
      if (this.hasPassErrors(pass) || this.hasPassPairErrors(pass, passPair)) {
        resolve(new ValidationErrorModel(this.errors));
      } else {
        resolve(new ValidationSuccessModel());
      }
    });
  }
}
