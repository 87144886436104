import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { createSelector } from 'reselect';

export const getUser = (state) => state.user;

export const isPasswordReseted = (state) => !!state.user.passwordReset;

export const getPortalSettings = (state) => {
  if (!isEmpty(state.user)) {
    if (isEmpty(state.user.ownersPortalSettings)) {
      return { bookedNights: true, revenue: true };
    }

    return state.user.ownersPortalSettings;
  }

  return {};
};

export const getUserLanguage = (state) =>
  get(state, ['user', 'locale'], 'en-US');

export const getOwnersPortalRevenueSetting = createSelector(
  [getPortalSettings],
  (ownersPortalSettings) => get(ownersPortalSettings, 'revenue', false)
);

export const getOwnersPortalGuestsReportSetting = createSelector(
  [getPortalSettings],
  (ownersPortalSettings) => get(ownersPortalSettings, 'guestsReports', false)
);

export const getOwnersPortalAccommodationFareSetting = createSelector(
  [getPortalSettings],
  (ownersPortalSettings) =>
    get(ownersPortalSettings, 'accommodationFare', false)
);

export const getOwnersPortalRentalPeriodSetting = createSelector(
  [getPortalSettings],
  (ownersPortalSettings) =>
    get(ownersPortalSettings, ['rentalPeriods', 'openRentalPeriods'], false)
);

export const getOwnersPortalBookingRequestSetting = createSelector(
  [getPortalSettings],
  (ownersPortalSettings) =>
    get(ownersPortalSettings, ['rentalPeriods', 'requestToBook'], false)
);

export const getOwnersPortalShowReservedReservations = createSelector(
  [getPortalSettings],
  (ownersPortalSettings) =>
    get(ownersPortalSettings, ['showReservedReservations'], true)
);

export const getOwnersPortalShowInternalNotesForBlocks = createSelector(
  [getPortalSettings],
  (ownersPortalSettings) =>
    get(ownersPortalSettings, ['showInternalNotesForBlocks'], false)
);

export const getOwnersPortalShowHostPayout = createSelector(
  [getPortalSettings],
  (ownersPortalSettings) => get(ownersPortalSettings, ['hostPayout'], false)
);
