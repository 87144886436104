import snakeCase from 'lodash/snakeCase';

import t from '@guestyci/localize/t.macro';

import {
  getListings as doGetListings,
  getCalendarMonthYear as doGetCalendarMonthYear,
  getCalendarFromTo as doGetCalendarFromTo,
  getListingMiniData as doGetListingMiniData,
  addReservation as doAddReservation,
  cancelReservation as doCancelReservation,
  updateReservation as doUpdateReservation,
  fetchRentalPeriod as doFetchRentalPeriod,
  updateRentalPeriod as doUpdateRentalPeriod,
} from 'property/properties.services';
import { formatDateWithoutUTC } from 'property/property.utils';

/** **** Action Types ****** */

export const types = {
  FETCH_LISTINGS_REQUEST: 'FETCH_LISTINGS_REQUEST',
  FETCH_LISTINGS: 'FETCH_LISTINGS',
  FETCH_ALL_LISTINGS_REQUEST: 'FETCH_ALL_LISTINGS_REQUEST',
  FETCH_ALL_LISTINGS: 'FETCH_ALL_LISTINGS',
  FETCH_CALENDAR_MONTH_YEAR_REQUEST: 'FETCH_CALENDAR_MONTH_YEAR_REQUEST',
  FETCH_CALENDAR_MONTH_YEAR: 'FETCH_CALENDAR_MONTH_YEAR',
  FETCH_LISTING_MINI_DATA_REQUEST: 'FETCH_LISTING_MINI_DATA_REQUEST',
  FETCH_LISTING_MINI_DATA: 'FETCH_LISTING_MINI_DATA',
  VIEW_CALENDAR: 'VIEW_CALENDAR',
  ADD_ACTIVE_LISTING: 'ADD_ACTIVE_LISTING',
  REMOVE_ACTIVE_LISTING: 'REMOVE_ACTIVE_LISTING',
  RESET_ALL: 'RESET_ALL',
  SELECT: 'SELECT',
  UPDATE_CALENDAR: 'UPDATE_CALENDAR',
  FETCH_CALENDAR_FROM_TO_REQUEST: 'FETCH_CALENDAR_FROM_TO_REQUEST',
  FETCH_CALENDAR_FROM_TO: 'FETCH_CALENDAR_FROM_TO',
  FETCH_RENTAL_PERIODS: 'FETCH_RENTAL_PERIODS',
  UPDATE_RENTAL_PERIODS: 'UPDATE_RENTAL_PERIODS',
};

export const chartTypes = {
  BOOKED_NIGHTS: 'BOOKED_NIGHTS',
  OCCUPANCY: 'OCCUPANCY',
  ACCOMMODATION_FARE: 'ACCOMMODATION_FARE',
  AVG_NIGHTLY_RATE: 'AVG_NIGHTLY_RATE',
  REV_PAL: 'REV_PAL',
  REVENUE: 'OWNER_REVENUE',
};

export const getChartTypeNameString = (stat) => {
  switch (snakeCase(stat).toUpperCase()) {
    case chartTypes.ACCOMMODATION_FARE:
      return t('Accommodation fare');
    case chartTypes.AVG_NIGHTLY_RATE:
      return t('Average nightly rate');
    case chartTypes.BOOKED_NIGHTS:
      return t('Booked nights');
    case chartTypes.OCCUPANCY:
      return t('Occupancy');
    case chartTypes.REVENUE:
      return t('Revenue');
    case chartTypes.REV_PAL:
      return t('Rev pal');
    default:
      return stat;
  }
};

export const getChartTypeTooltipString = (stat) => {
  switch (snakeCase(stat).toUpperCase()) {
    case chartTypes.ACCOMMODATION_FARE:
      return t(
        'For reservations from booking channels, it is the total accommodation fare as it appears in the channel. For direct bookings, it is the total cost of staying at your property, including rates, discounts, fees, and promotions.'
      );
    case chartTypes.AVG_NIGHTLY_RATE:
      return t(
        'The average cost of staying at your property is calculated by dividing the total payout (that may include items such as cleaning fees and channel commissions) by booked nights.'
      );
    case chartTypes.BOOKED_NIGHTS:
      return t(
        'The number of nights when your property is already blocked by reservations within your chosen dates.'
      );
    case chartTypes.OCCUPANCY:
      return t(
        'A measure of how full your property is. Occupancy is calculated by dividing booked nights by available nights.'
      );
    case chartTypes.REV_PAL:
      return t(
        'Revenue per Available Listing measures how well your property performs by multiplying the Average nightly rate by Occupancy.'
      );
    default:
      return stat;
  }
};

/** **** Action Creators ****** */

const getCalendarFromToRequestAction = (listingId, from, to, options) => ({
  type: types.FETCH_CALENDAR_FROM_TO_REQUEST,
  listingId,
  from,
  to,
  options,
});

const getCalendarFromToAction = (listingId, from, to, options, calendars) => ({
  type: types.FETCH_CALENDAR_FROM_TO,
  listingId,
  from,
  to,
  options,
  calendars,
});

const getListingsRequestAction = () => ({
  type: types.FETCH_LISTINGS_REQUEST,
});

const getListingsAction = (listings) => ({
  type: types.FETCH_LISTINGS,
  listings,
});

const getAllListingsRequestAction = () => ({
  type: types.FETCH_ALL_LISTINGS_REQUEST,
});

const getAllListingsAction = (listings) => ({
  type: types.FETCH_ALL_LISTINGS,
  listings,
});

const getCalendarMonthYearRequestAction = (
  listingId,
  month,
  year,
  options
) => ({
  type: types.FETCH_CALENDAR_MONTH_YEAR_REQUEST,
  listingId,
  month,
  year,
  options,
});

const getCalendarMonthYearAction = (
  listingId,
  month,
  year,
  options,
  calendar
) => ({
  type: types.FETCH_CALENDAR_MONTH_YEAR,
  listingId,
  month,
  year,
  options,
  calendar,
});

const getListingMiniDataRequestAction = (listingId, month, year) => ({
  type: types.FETCH_LISTING_MINI_DATA_REQUEST,
  listingId,
  month,
  year,
});

const getListingMiniDataAction = (listingId, month, year, data) => ({
  type: types.FETCH_LISTING_MINI_DATA,
  listingId,
  month,
  year,
  data,
});

export const viewCalendarAction = (listingId, month, year) => ({
  type: types.VIEW_CALENDAR,
  listingId,
  month,
  year,
});

export const addActiveListing = (listingId) => ({
  type: types.ADD_ACTIVE_LISTING,
  listingId,
});

export const removeActiveListing = (listingId) => ({
  type: types.REMOVE_ACTIVE_LISTING,
  listingId,
});

export const resetAll = () => ({
  type: types.RESET_ALL,
});

export const select = (data) => ({
  type: types.SELECT,
  data,
});

export const updateCalendar = ({ from, to, note, listingId, propValue }) => ({
  type: types.UPDATE_CALENDAR,
  listingId,
  propValue,
  from,
  to,
  note,
});

export const getRentalPeriodDataAction = ({
  defaultAvailability,
  rentalPeriods,
}) => ({
  type: types.FETCH_RENTAL_PERIODS,
  defaultAvailability,
  rentalPeriods,
});

export const updateRentalPeriodDataAction = ({ rentalPeriods }) => ({
  type: types.UPDATE_RENTAL_PERIODS,
  rentalPeriods,
});

/** **** Actions ****** */

export const getCalendarFromTo =
  (listingId, from, to, options) => (dispatch) => {
    dispatch(getCalendarFromToRequestAction(listingId, from, to, options));

    return doGetCalendarFromTo(listingId, from, to, options).then((calendars) =>
      dispatch(getCalendarFromToAction(listingId, from, to, options, calendars))
    );
  };

export const getAllListings = (data) => (dispatch) => {
  dispatch(getAllListingsRequestAction());

  const getAllListingsRecursive = (request, results = []) => {
    return doGetListings(request).then((listings) => {
      if (listings.results.length < 1) {
        return results;
      }
      results.push(...listings.results);
      // eslint-disable-next-line no-param-reassign
      request.skip += listings.results.length;
      return getAllListingsRecursive(request, results);
    });
  };

  return getAllListingsRecursive(data).then((listings) =>
    dispatch(getAllListingsAction(listings))
  );
};

export const getListings = (data) => (dispatch) => {
  dispatch(getListingsRequestAction());

  return doGetListings(data).then((listings) =>
    dispatch(getListingsAction(listings))
  );
};

export const getCalendarMonthYear =
  (listingId, month, year, options) => (dispatch) => {
    dispatch(
      getCalendarMonthYearRequestAction(listingId, month, year, options)
    );

    return doGetCalendarMonthYear(listingId, month, year, options).then(
      (calendar) =>
        dispatch(
          getCalendarMonthYearAction(listingId, month, year, options, calendar)
        )
    );
  };

export const getListingMiniData = (listingId, month, year) => (dispatch) => {
  dispatch(getListingMiniDataRequestAction(listingId, month, year));

  return doGetListingMiniData(listingId, month, year).then((data) =>
    dispatch(getListingMiniDataAction(listingId, month, year, data))
  );
};

const makeAvailable = ({ from, to, listingId, pending, dispatch }) => {
  dispatch(
    updateCalendar({
      from,
      to,
      listingId,
      propValue: {
        blocks: {},
        status: 'available',
        pending,
        ownerReservationId: null,
        ownerReservation: {},
      },
    })
  );
};

const makeReserved = ({
  from,
  to,
  checkOut,
  listingId,
  ownerReservationId,
  note,
  pending,
  dispatch,
}) => {
  dispatch(
    updateCalendar({
      from,
      to,
      listingId,
      propValue: {
        blocks: { o: true },
        status: 'unavailable',
        pending,
        ownerReservationId,
        ownersReservation: {
          checkIn: from ? formatDateWithoutUTC(from) : undefined,
          checkOut: checkOut ? formatDateWithoutUTC(checkOut) : undefined,
          note: note || '',
        },
      },
    })
  );
};

export const addReservation =
  ({ from, to, listingId, note, checkOut }) =>
  (dispatch) => {
    makeReserved({
      from,
      to,
      checkOut,
      listingId,
      ownerReservationId: undefined,
      note,
      pending: true,
      dispatch,
    });
    return doAddReservation(from, checkOut, listingId, note, to)
      .then((ownerReservation) => {
        dispatch(
          updateCalendar({
            from,
            to,
            listingId,
            propValue: {
              ownerReservationId: ownerReservation.id,
              pending: false,
            },
          })
        );
      })
      .catch((err) => {
        makeAvailable({ from, to, listingId, pending: false, dispatch });
        console.error(err);
        alert("Couldn't reserve dates. Please contact your property manager.");
      });
  };

export const updateReservation =
  ({
    from,
    to,
    listingId,
    ownerReservationId,
    note,
    previousNote,
    previousFrom,
    previousTo,
    checkOut,
    previousCheckOut,
  }) =>
  (dispatch) => {
    makeAvailable({ from: previousFrom, to: previousTo, listingId, dispatch });
    makeReserved({
      from,
      to,
      checkOut,
      listingId,
      ownerReservationId,
      note,
      pending: true,
      dispatch,
    });

    return doUpdateReservation(ownerReservationId, from, checkOut, note, to)
      .then(() =>
        dispatch(
          updateCalendar({ from, to, listingId, propValue: { pending: false } })
        )
      )
      .catch((err) => {
        makeAvailable({ from, to, listingId, pending: false, dispatch });
        makeReserved({
          from: previousFrom,
          to: previousTo,
          checkOut: previousCheckOut,
          listingId,
          ownerReservationId,
          note: previousNote,
          dispatch,
        });
        console.error(err);
        alert("Couldn't update dates. Please contact your property manager.");
      });
  };

export const cancelReservation =
  ({ from, to, checkOut, listingId, ownerReservationId, note }) =>
  (dispatch) => {
    makeAvailable({ from, to, listingId, dispatch });
    return doCancelReservation(ownerReservationId).catch((err) => {
      makeReserved({
        from,
        to,
        checkOut,
        listingId,
        ownerReservationId,
        note,
        pending: false,
        dispatch,
      });
      console.error(err);
      alert("Couldn't delete dates. Please contact your property manager.");
    });
  };

export const fetchRentalPeriod = (listingId) => (dispatch) =>
  doFetchRentalPeriod(listingId)
    .then((response) => {
      const { defaultAvailability, rentalPeriods } = response.data;

      dispatch(
        getRentalPeriodDataAction({ defaultAvailability, rentalPeriods })
      );
    })
    .catch(() => {
      return {};
    });

export const updateRentalPeriod =
  ({ listingId, data }) =>
  (dispatch) =>
    doUpdateRentalPeriod({ listingId, data }).then((response) => {
      dispatch(updateRentalPeriodDataAction({ rentalPeriods: response.data }));
    });
