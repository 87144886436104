import { connect } from 'react-redux';
import qs from 'query-string';

import App from 'components/App';
import { getRoute } from 'routes';
import {
  uiAction,
  dirtyAnswerAction,
  setCurrentLocaleAction,
} from 'ui/ui.actions';
import {
  getOwnersPortalGuestsReportSetting,
  getOwnersPortalBookingRequestSetting,
  getPortalSettings,
  getUserLanguage,
} from 'user/user.selectors';
import { getHasNavbar } from 'ui/ui.selectors';

function mapStateToProps(state) {
  const {
    location: { pathname, search },
  } = window;
  const route = getRoute(pathname);
  const isFetching = route?.isFetching(state) || state.ui.shouldDisplayLoader;
  const initState = state.ui.initState || route?.initState || 'LOGIN';
  const query = qs.parse(search);
  const { hash, locale } = query;
  const { warnDirty } = state.ui;
  const isLoaderVisible = state.ui.shouldDisplayLoader;

  return {
    isFetching,
    // since strings aren't in the store, ui isn't rerendered when they are loaded
    currentLocale: state.ui.currentLocale,
    isAuthenticated: state.user.authenticated,
    winWidth: state.ui.winWidth,
    initState,
    hash,
    locale,
    warnDirty,
    isLoaderVisible,
    portalSettings: getPortalSettings(state),
    userLanguage: getUserLanguage(state),
    hasNavbar: getHasNavbar(state),
    isGuestsReportSettingEnabled: getOwnersPortalGuestsReportSetting(state),
    isBookingRequestSettingEnabled: getOwnersPortalBookingRequestSetting(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoad: (data) => dispatch(uiAction(data)),
    onDirtyAnswer: (to, navigate) => {
      dispatch(dirtyAnswerAction({ reset: !!to }));
      if (to) {
        navigate(to);
      }
    },
    onSetCurrentLocale: (userLocale) =>
      dispatch(setCurrentLocaleAction(userLocale)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
