import { Provider } from 'react-redux';

import withAuthentication from 'components/withAuthentication';
import Page from 'pages/Page';
import '@guestyci/foundation/style/styles.css';

import App from './App';
import store from './store';

const BookingRequest = ({ logo, ownerName, ownerId, history }) => {
  return (
    <Page title="Booking request">
      <Provider store={store}>
        <App
          ownerName={ownerName}
          ownerId={ownerId}
          logo={logo}
          history={history}
        />
      </Provider>
    </Page>
  );
};

export default withAuthentication(BookingRequest);
