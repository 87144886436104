/* eslint-disable no-dupe-class-members */
import React from 'react';

import t from '@guestyci/localize/t.macro';

import LoginLayout from 'components/login/LoginLayout';
import FormField from 'components/common/FormField';
import LoginButton from 'containers/LoginButton.container';

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      emailError: false,
      empty: true,
    };
  }

  onEmailBlur(e) {
    const valid = e.target.checkValidity();
    this.setState({
      emailError: valid ? false : 'Invalid email',
      empty: !e.target.value,
    });
  }

  onEmailChange(e) {
    const valid = e.target.checkValidity();
    this.setState({
      emailError: !valid,
      empty: !e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const { username } = e.target.elements;

    this.props
      .onForgotPassword(username.value)
      .then(() => this.props.changeMode('CHECK_EMAIL'));
  }

  onEmailBlur = this.onEmailBlur.bind(this);

  onEmailChange = this.onEmailChange.bind(this);

  onSubmit = this.onSubmit.bind(this);

  render() {
    const { logo } = this.props;
    const disableSubmit = this.state.empty || this.state.emailError;

    return (
      <LoginLayout
        logo={logo}
        title={t('Forgot password?')}
        subtitle={`${t('To reset your password, enter your email')}:`}
      >
        <form onSubmit={this.onSubmit}>
          <FormField
            id="username"
            label={t('Email')}
            inputType="email"
            onBlur={this.onEmailBlur}
            onChange={this.onEmailChange}
            error={this.state.emailError}
          />
          <LoginButton isDisabled={disableSubmit} text={t('Reset password')} />
        </form>
      </LoginLayout>
    );
  }
}

export default ForgotPassword;
