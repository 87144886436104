import Dialog, {
  DialogHeader,
  DialogContent,
  DialogFooter,
} from '@guestyci/foundation/Dialog';
import { Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import FlatButton from '@guestyci/foundation/FlatButton';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import t from '@guestyci/localize/t.macro';
import createStyles from '@guestyci/foundation/createStyles';

const useStyles = createStyles(({ breakpoints: { down } }) => ({
  root: {
    maxWidth: '500px',
    [down('sm')]: {
      minWidth: 'unset',
    },
  },
}));

const DirtyWarningDialog = ({ to, handleOk, handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={!!to} onClose={handleClose} noClear className={classes.root}>
      <DialogHeader>
        {t('Are you sure you want to leave this page?')}
      </DialogHeader>
      <DialogContent>
        <TextField variant="h5" color="secondary">
          {t('Your new changes will be lost.')}
        </TextField>
      </DialogContent>
      <DialogFooter>
        <Row spacing={2} align="center" justify="end">
          <FlatButton onClick={handleClose}>{t('Stay')}</FlatButton>
          <RaisedButton onClick={() => handleOk(to)}>{t('Leave')}</RaisedButton>
        </Row>
      </DialogFooter>
    </Dialog>
  );
};

export default DirtyWarningDialog;
