import DayPicker, { DateUtils } from 'react-day-picker';
import LocaleUtils from 'react-day-picker/moment';
import PropTypes from 'prop-types';

import { getDays, addDays } from 'utils/calendar';
import tracker from 'utils/dio/my-properties-tracker';

import AppSpinner from './AppSpinner';
import CalendarHeader from './CalendarHeader';
import CalendarDay from './CalendarDay';

const Caption = () => null;

const Weekday = ({ weekday, className, localeUtils, locale }) => {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  return (
    <div className={className} title={weekdayName}>
      {weekdayName.slice(0, 3)}
    </div>
  );
};

const Calendar = (props) => {
  const {
    to,
    from,
    locale,
    listing,
    isLoading,
    initialMonth,
    withReservation,
    isFetchingMiniData,
    calendar,
    rentalPeriods = [],
    isRentalPeriodEnabled,
    updateRentalPeriod,
    onShowRentalPeriodModal,
    allowReservations,
    openReservationModal,
    onMonthChange,
    showReservedReservations,
  } = props;

  const selectedPart = (day) => {
    if (day.getDay() === 1 && from) {
      if (to) {
        const [start, end] = DateUtils.isDayAfter(from, to)
          ? [to, from]
          : [from, to];
        return DateUtils.isDayBetween(day, start, addDays(end, 2));
      }

      return DateUtils.isDayBetween(day, from, addDays(from, 2));
    }
  };

  const modifiers = {
    past: {
      before: new Date(),
    },
    start: from,
    end: to,
    selectedPart,
    available: getDays(calendar, 'status', 'available'),
    reserved: getDays(calendar, 'blocks.o', true),
    pending: getDays(calendar, 'pending', true),
  };

  if (isLoading) {
    return <AppSpinner />;
  }

  return (
    <div style={{ position: 'relative' }} className="mx-4">
      <DayPicker
        {...withReservation}
        selectedDays={[from, { from, to }]}
        modifiers={modifiers}
        localeUtils={LocaleUtils}
        locale={locale}
        month={initialMonth}
        enableOutsideDays
        weekdayElement={Weekday}
        captionElement={Caption}
        navbarElement={CalendarHeader({
          listing,
          initialMonth,
          onMonthChange,
          loadingCalendar: isLoading,
          fetchingMiniData: isFetchingMiniData,
          localeUtils: LocaleUtils,
          locale,
          showReservedReservations,
        })}
        renderDay={(day) =>
          CalendarDay({
            listing,
            calendar,
            day,
            rentalPeriods,
            isRentalPeriodEnabled,
            updateRentalPeriod,
            onShowRentalPeriodModal,
            showReservedReservations,
          })
        }
        onMonthChange={onMonthChange}
        firstDayOfWeek={1}
      />
      {allowReservations && (
        <div
          aria-hidden="true"
          onClick={() => {
            tracker.onAddReservation(listing?._id);
            openReservationModal(props);
          }}
          className="add-reservation-button float-right d-table"
        >
          <p className="d-table-cell align-middle">&#10006;</p>
        </div>
      )}
    </div>
  );
};

Calendar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFetchingMiniData: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  withReservation: PropTypes.instanceOf(Object).isRequired,
  calendar: PropTypes.instanceOf(Object).isRequired,
  rentalPeriods: PropTypes.instanceOf(Array),
  listing: PropTypes.instanceOf(Object).isRequired,
  allowReservations: PropTypes.bool.isRequired,
  openReservationModal: PropTypes.func.isRequired,
  updateRentalPeriod: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func.isRequired,
  onShowRentalPeriodModal: PropTypes.func.isRequired,
  initialMonth: PropTypes.instanceOf(Date).isRequired,
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  isRentalPeriodEnabled: PropTypes.bool,
  showReservedReservations: PropTypes.bool,
};

export default Calendar;
