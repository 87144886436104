import React from 'react';
import { Redirect } from 'react-router-dom';

import t from '@guestyci/localize/t.macro';
import { Col } from '@guestyci/foundation/Layout';

import Page from 'pages/Page';
import Register from 'components/login/Register';
import Login from 'components/login/Login';
import ForgotPassword from 'components/login/ForgotPassword';
import CheckEmail from 'components/login/CheckEmail';
import NewPassword from 'components/login/NewPassword';
import PasswordChanged from 'components/login/PasswordChanged';

const LoginModes = {
  REGISTER: Register,
  LOGIN: Login,
  FORGOT_PASSWORD: ForgotPassword,
  CHECK_EMAIL: CheckEmail,
  NEW_PASSWORD: NewPassword,
  PASSWORD_CHANGED: PasswordChanged,
};

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.mode,
    };
    this.changeMode = this.changeMode.bind(this);
  }

  changeMode(mode) {
    this.setState({ mode });

    // NOTE: this is ugly. But really, it's the only login mode that needs a route, so I felt there's no need for infra here.
    // HOWEVER: if more login routes are needed, don't add if-else here, but rather create a mode<-->route map and navigate to it.
    if (mode === 'FORGOT_PASSWORD') {
      this.props.history.push('forgot-password');
    }
  }

  render() {
    const { mode } = this.state;
    const { authenticated } = this.props;

    const content = React.createElement(LoginModes[mode], {
      ...this.props,
      changeMode: this.changeMode,
    });
    let title = 'Login';

    switch (mode) {
      case 'REGISTER':
        title = t('Register');
        break;
      case 'FORGOT_PASSWORD':
        title = t('Forgot Password');
        break;
      case 'CHECK_EMAIL':
        title = t('Check Email');
        break;
      case 'NEW_PASSWORD':
        title = t('New Password');
        break;
      case 'PASSWORD_CHANGED':
        title = t('Password Changed');
        break;
      case 'LOGIN':
      default:
        title = t('Login');
        break;
    }

    if (authenticated) {
      return <Redirect to="/" />;
    }

    return (
      <Page
        title={title}
        hasNavbar={false}
        layoutContentInnerClassName="login-bg stretch"
      >
        <Col align="center" justify="center" style={{ height: '100vh' }}>
          {content}
        </Col>
      </Page>
    );
  }
}
