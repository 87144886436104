import createStyles from '@guestyci/foundation/createStyles';
import Spinner from '@guestyci/foundation/Spinner';
import Row from '@guestyci/foundation/Layout/Row';
import zIndex from '@guestyci/foundation/theme/zIndex';

const useStyles = createStyles(() => ({
  backdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(255, 255, 255, 0.6)',
    zIndex: zIndex.tooltip + 100,
  },
}));

const AppSpinner = ({ isVisible = true }) => {
  const classes = useStyles();

  return (
    isVisible && (
      <Row
        className={classes.backdrop}
        data-qa="app-spinner"
        data-testid="app-spinner"
      >
        <Spinner size={60} className="m-auto" />
      </Row>
    )
  );
};

export default AppSpinner;
