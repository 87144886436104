import { createSelector } from 'reselect';
import get from 'lodash/get';

import {
  getCurrentMonthYearKey,
  getPrevMonthKey,
  getNextMonthKey,
  sliceObject,
  getDateFromKey,
  getPriceStr,
  getPercent,
} from 'utils/common';
import { getDays } from 'utils/calendar';

const getListing = (state, props) => props.listing;

const getMonthKey = createSelector(
  [getListing],
  (listing) => listing.viewCal || getCurrentMonthYearKey()
);

const getCalendar = createSelector(
  [getListing, getMonthKey],
  (listing, monthKey) => get(listing, `calendar.${monthKey}`)
);

export const getPreviousMonth = createSelector(
  [getListing, getMonthKey],
  (listing, monthKey) =>
    get(listing, `calendar.${getPrevMonthKey(monthKey)}`, {})
);

const getNextMonth = createSelector(
  [getListing, getMonthKey],
  (listing, monthKey) =>
    get(listing, `calendar.${getNextMonthKey(monthKey)}`, {})
);

const getLastWeekOfPreviousMonth = createSelector(
  [getPreviousMonth],
  (previousMonth) => {
    const { length } = Object.keys(previousMonth);
    return sliceObject(previousMonth, length - 6, length);
  }
);

const getFirstWeekOfNextMonth = createSelector(
  [getListing, getNextMonth],
  (listing, nextMonth) => sliceObject(nextMonth, 0, 6)
);

const getRealCalendar = createSelector([getCalendar], (calendar) =>
  calendar && !calendar.isFetching ? calendar : undefined
);

export const getExtendedRealCalendar = createSelector(
  [getLastWeekOfPreviousMonth, getRealCalendar, getFirstWeekOfNextMonth],
  (lastWeekOfPreviousMonth, realCalendar, firstWeekOfNextMonth) =>
    realCalendar && {
      ...lastWeekOfPreviousMonth,
      ...realCalendar,
      ...firstWeekOfNextMonth,
    }
);

const getMiniData = createSelector(
  [getListing, getMonthKey],
  (listing, monthKey) => listing.miniData && listing.miniData[monthKey]
);

export const getInitialMonth = createSelector([getMonthKey], (monthKey) =>
  getDateFromKey(monthKey)
);

export const getAccountCurrency = (state) =>
  state.user.account ? state.user.account.currency : undefined;

export const getRevenue = createSelector(
  [getMiniData, getAccountCurrency],
  (miniData, accountCurrency) =>
    miniData && miniData.revenue && miniData.revenue.total !== undefined
      ? getPriceStr(miniData.revenue.total, accountCurrency)
      : '-'
);

export const getBookedNights = createSelector([getMiniData], (miniData) =>
  miniData && miniData.bookedNights && miniData.bookedNights.total !== undefined
    ? miniData.bookedNights.total.toString()
    : '-'
);

export const getRevenueByReservationStatus = createSelector(
  [getMiniData],
  (miniData) =>
    miniData && miniData.revenueByReservationStatus
      ? miniData.revenueByReservationStatus
      : {}
);

export const getRevPal = createSelector(
  [getMiniData, getAccountCurrency],
  (miniData, accountCurrency) =>
    miniData && miniData.revpal !== undefined
      ? getPriceStr(miniData.revpal, accountCurrency)
      : '-'
);

export const getOccupancy = createSelector([getMiniData], (miniData) =>
  miniData && miniData.occupancy !== undefined
    ? getPercent(miniData.occupancy)
    : '-'
);

/** @deprecated */
export const getFrontEndOccupacy = createSelector(
  [getMiniData, getListing, getMonthKey],
  (miniData, listing, monthKey) => {
    if (miniData.isFetching || listing.calendar[monthKey].isFetching)
      return '-';
    const totalDaysOccupied = get(miniData, 'bookedNights.total', 0);
    const monthLength = Object.keys(
      get(listing, `calendar[${monthKey}]`, {})
    ).length;
    const calculatedOccupancy = Math.round(
      (totalDaysOccupied / monthLength) * 100
    );
    return calculatedOccupancy;
  }
);

export const getAccommodationFare = createSelector(
  [getMiniData, getAccountCurrency],
  (miniData, accountCurrency) =>
    miniData && miniData.accommodationFare !== undefined
      ? getPriceStr(miniData.accommodationFare, accountCurrency)
      : '-'
);

export const getAvgNightlyRate = createSelector(
  [getMiniData, getAccountCurrency],
  (miniData, accountCurrency) =>
    miniData && miniData.avgNightlyRate !== undefined
      ? getPriceStr(miniData.avgNightlyRate, accountCurrency)
      : '-'
);

export const getDisabledDays = createSelector(
  [getExtendedRealCalendar],
  (extendedRealCalendar = {}) => [
    ...getDays(extendedRealCalendar, 'status', 'booked'),
    ...getDays(extendedRealCalendar, 'status', 'reserved'),
    ...getDays(extendedRealCalendar, 'status', 'unavailable'),
  ]
);

export const getIsFetchingMiniData = createSelector(
  [getMiniData],
  (miniData) => (miniData ? !!miniData.isFetching : false)
);
