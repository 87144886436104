import { getMeta } from 'ui/ui.services';

export const types = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  UI_ACTION: 'UI_ACTION',
  SET_CURRENT_LOCALE: 'SET_CURRENT_LOCALE',
  DIRTY_ALERT: 'DIRTY_ALERT',
  DIRTY_ANSWER: 'DIRTY_ANSWER',
  LOADER: 'LOADER',
  NAVIGATION: 'NAVIGATION',
  DISPLAY_LOADER: 'DISPLAY_LOADER',
  GET_PORTAL_META_REQUESTED: 'GET_PORTAL_META_REQUESTED',
  GET_PORTAL_META_SUCCESS: 'GET_PORTAL_META_SUCCESS',
  GET_PORTAL_META_FAILURE: 'GET_PORTAL_META_FAILURE',
  DISPLAY_NAVBAR: 'DISPLAY_NAVBAR',
};

export const uiAction = (data) => ({
  type: types.UI_ACTION,
  data,
});

export const setCurrentLocaleAction = (locale) => ({
  type: types.SET_CURRENT_LOCALE,
  locale,
});

export const dirtyAnswerAction = ({ reset }) => ({
  type: types.DIRTY_ANSWER,
  reset,
});

export const dirtyAlertAction = (to) => ({
  type: types.DIRTY_ALERT,
  to,
});

export const navigationAction = (to) => ({
  type: types.NAVIGATION,
  to,
});

export const setHasNavbarAction = (hasNavbar) => ({
  type: types.DISPLAY_NAVBAR,
  hasNavbar,
});

const toggleLoader = (shouldDisplay) => ({
  type: types.DISPLAY_LOADER,
  shouldDisplay,
});

export const showLoader = () => (dispatch) => dispatch(toggleLoader(true));

export const hideLoader = () => (dispatch) => dispatch(toggleLoader(false));

export const getOwnersPortalMetaAction = (host) => ({
  type: types.GET_PORTAL_META_REQUESTED,
  host,
});

export const getOwnersPortalMetaSuccess = (meta) => ({
  type: types.GET_PORTAL_META_SUCCESS,
  meta,
});

export const getOwnersPortalMetaFailure = (error) => ({
  type: types.GET_PORTAL_META_FAILURE,
  error,
});

export const getOwnersPortalMeta = (host) => (dispatch) => {
  dispatch(getOwnersPortalMetaAction(host));

  return getMeta(host)
    .then((meta) => dispatch(getOwnersPortalMetaSuccess(meta)))
    .catch((err) => dispatch(getOwnersPortalMetaFailure(err)));
};
