import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getPortalSettings } from 'user/user.selectors';
import Stats from 'components/Stats';

const mapStateToProps = createStructuredSelector({
  portalSettings: getPortalSettings,
});

export default connect(mapStateToProps)(Stats);
