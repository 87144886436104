import createStyles from '@guestyci/foundation/createStyles';
import { Row } from '@guestyci/foundation/Layout';
import { ReactComponent as BtnGuestyLogo } from '@guestyci/icons/BtnGuestyLogo.svg';
import Icon from '@guestyci/foundation/Icon';
import TextField from '@guestyci/foundation/TextField';
import { white, blueGray400 } from '@guestyci/foundation/theme/colors';
import t from '@guestyci/localize/t.macro';
import zIndex from '@guestyci/foundation/theme/zIndex';

import { useMediaQuery } from 'hooks/useMediaQuery';

const useStyles = createStyles(() => ({
  root: {
    padding: '10px 0px 10px 0px',
    zIndex: zIndex.appBar + 28,
    backgroundColor: 'transparent',
  },
}));

const PoweredBy = ({ isVisible, hasNavbar }) => {
  const classes = useStyles();

  const { isXsScreen } = useMediaQuery();

  const dark = hasNavbar || isXsScreen;

  return isVisible ? (
    <Row
      spacing={2}
      align="center"
      justify="end"
      className={classes.root}
      fullWidth
    >
      <TextField variant="h7" color={dark ? 'secondary' : 'white'}>{`${t(
        'Powered by'
      )}:`}</TextField>
      <Icon
        width={32}
        height={32}
        svg={BtnGuestyLogo}
        fillColor={dark ? blueGray400 : white}
      />
    </Row>
  ) : null;
};

export default PoweredBy;
