import React from 'react';

import FormField from 'components/common/FormField';

const isValid = (value, emptyIsValid) => {
  if (emptyIsValid && !value) return true;
  return value.indexOf(' ') === -1 && value.length >= 6;
};
export default class Password extends React.Component {
  constructor() {
    super();

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onBlur(e) {
    const { onBlur } = this.props;
    const { value } = e.target;
    const valid = isValid(value, true);
    if (onBlur) onBlur(valid, value);
  }

  onChange(e) {
    const { onChange } = this.props;
    const { value } = e.target;
    const valid = isValid(value, false);
    if (onChange) onChange(valid, value);
  }

  render() {
    return (
      <FormField
        {...this.props}
        inputType="password"
        onBlur={this.onBlur}
        onChange={this.onChange}
      />
    );
  }
}
