import mapValues from 'lodash/mapValues';

export const map = {
  dropdown: 'dropdown',
  dropup: 'dropup',
  browse_left: 'browse_left',
  browse_right: 'browse_right',
  check: 'check',
  form_sent_success: 'form_sent_success',
  form_sent_failure: 'form_sent_failure',
  'guesty-logo-gray': 'guesty-logo-gray',
  'guesty-logo-white': 'guesty-logo-white',
  edit: 'edit',
  'calendar-nav-left': 'calendar-nav-left',
  'calendar-nav-right': 'calendar-nav-right',
  arrow: 'arrow',
  'no-properties-filler': 'no-properties-filler',
  'tooltip-icon': 'tooltip-icon',
  placeholder: 'placeholder',
  'plus-in-circle--add': 'plus-in-circle--add',
  'information-light-icon': 'information-light-icon',
};

// eslint-disable-next-line import/no-dynamic-require
const requiredIconMap = mapValues(
  map,
  // eslint-disable-next-line import/no-dynamic-require
  (name) => require(`icons/${name}.svg`)
);
export default requiredIconMap;
