import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Row } from '@guestyci/foundation/Layout';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import FlatButton from '@guestyci/foundation/FlatButton';
import OutlineButton from '@guestyci/foundation/OutlineButton';
import t from '@guestyci/localize/t.macro';
import createStyles from '@guestyci/foundation/createStyles';

import {
  useApproveRequestToBookMutation,
  useDeclineRequestToBookMutation,
} from '../api';
import { setDeclineRequestAction } from '../view/requestToBook.slice';

const useStyles = createStyles({
  button: {
    borderRadius: '200px',
    textTransform: 'uppercase',
  },
});

export const OwnerActions = ({
  reservationId,
  ownerId,
  ownerName,
  isDeclineRequested,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [approve] = useApproveRequestToBookMutation();
  const [decline] = useDeclineRequestToBookMutation({
    reservationId,
    ownerId,
    ownerName,
  });

  const handleApprove = () => {
    approve({ reservationId, ownerId, ownerName });
  };

  const handleDecline = useCallback(() => {
    if (isDeclineRequested) {
      decline({ reservationId, ownerId, ownerName });
    } else {
      dispatch(setDeclineRequestAction(true));
    }
  }, [dispatch, decline, isDeclineRequested]);

  const handleCancel = useCallback(() => {
    dispatch(setDeclineRequestAction(false));
  }, [dispatch]);

  return (
    <Row spacing={6} className="py-4">
      {!isDeclineRequested && (
        <RaisedButton className={classes.button} onClick={handleApprove}>
          {t('Approve')}
        </RaisedButton>
      )}
      <FlatButton
        type="error"
        className={classes.button}
        onClick={handleDecline}
      >
        {t('Decline')}
      </FlatButton>
      {isDeclineRequested && (
        <OutlineButton className={classes.button} onClick={handleCancel}>
          {t('Cancel')}
        </OutlineButton>
      )}
    </Row>
  );
};
