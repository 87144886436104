import { useEffect } from 'react';
import moment from 'moment';

import Dialog, {
  DialogHeader,
  DialogContent,
  DialogFooter,
} from '@guestyci/foundation/Dialog';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import FlatButton from '@guestyci/foundation/FlatButton';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

const CancelReservation = (props) => {
  const { modalProps, cancelUpdate, isOpen, owner } = props;
  const {
    pickerFrom,
    pickerTo,
    listingId,
    getCalendarFromTo,
    cancelReservation,
  } = modalProps;

  useEffect(() => {
    getCalendarFromTo(
      listingId,
      moment(pickerFrom).format('YYYY-MM-DD'),
      moment(pickerTo).format('YYYY-MM-DD'),
      {
        ownersPortalAccommodationFareEnabled:
          owner?.ownersPortalSettings?.accommodationFare,
      }
    );
  }, [
    getCalendarFromTo,
    listingId,
    pickerTo,
    pickerFrom,
    owner?.ownersPortalSettings?.accommodationFare,
  ]);

  const handleCancelReservation = () => {
    cancelUpdate();
    cancelReservation();
  };

  return (
    <Dialog className="p-4" open={isOpen} onClose={cancelUpdate}>
      <DialogHeader>{t('Cancel reservation')}</DialogHeader>
      <DialogContent>
        <TextField>
          {t('Are you sure you want to cancel the reservation?')}
        </TextField>
      </DialogContent>
      <DialogFooter>
        <FlatButton onClick={cancelUpdate}>{t('Keep reservation')}</FlatButton>
        <RaisedButton onClick={handleCancelReservation}>
          {t('Yes, cancel reservation')}
        </RaisedButton>
      </DialogFooter>
    </Dialog>
  );
};

export default CancelReservation;
