import { NavLink } from 'react-router-dom';

import createStyles from '@guestyci/foundation/createStyles';
import { Row } from '@guestyci/foundation/Layout';
import zIndex from '@guestyci/foundation/theme/zIndex';
import Divider from '@guestyci/foundation/Divider';

import { useAppSelector, useAppDispatch } from 'store';
import { dirtyAlertAction, navigationAction } from 'ui/ui.actions';

import { useGetMeAccountBrandToDisplayQuery } from '../api/ownersApi';

import NavBarLogo from './NavBarLogo';
import NavBarAvatar from './NavBarAvatar';
import NavBarMenu from './NavBarMenu';

import '@guestyci/foundation/style/styles.css';

const useStyles = createStyles((theme) => ({
  root: {
    height: 58,
    padding: '0px 20px 0px 20px',
    backgroundColor: theme.palette.background.white,
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: zIndex.appBar + 30,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  },
  divider: {
    height: 26,
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const appDispatch = useAppDispatch();

  const dirty = useAppSelector((state) => state.ui.dirty);

  const { data: accountBrand = {}, isFetching } =
    useGetMeAccountBrandToDisplayQuery();

  if (isFetching) {
    return null;
  }

  const handleNavLinkClick = (e, to) => {
    if (dirty) {
      e.preventDefault();
      appDispatch(dirtyAlertAction(to));
    }

    appDispatch(navigationAction(to));
  };

  return (
    <Row
      spacing={4}
      align="center"
      justify="start"
      className={classes.root}
      fullWidth
    >
      <Row spacing={4} align="center" justify="between" fullWidth>
        <Row spacing={4} align="center" justify="start">
          <NavLink to="/" onClick={(e) => handleNavLinkClick(e, '/')}>
            <NavBarLogo accountBrand={accountBrand} />
          </NavLink>

          <Divider className={classes.divider} orientation="vertical" />

          <NavBarMenu onNavLinkClick={handleNavLinkClick} />
        </Row>

        <NavBarAvatar accountBrand={accountBrand} />
      </Row>
    </Row>
  );
};

export default NavBar;
