import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CalendarStats from 'components/CalendarStats';
import {
  getRevPal,
  getRevenue,
  getBookedNights,
  getAccommodationFare,
  getAvgNightlyRate,
  getAccountCurrency,
  getRevenueByReservationStatus,
  getOccupancy,
  getInitialMonth,
} from 'property/calendar.selectors';
import { getPortalSettings } from 'user/user.selectors';

const mapStateToProps = createStructuredSelector({
  revPal: getRevPal,
  revenue: getRevenue,
  occupancy: getOccupancy,
  bookedNights: getBookedNights,
  accommodationFare: getAccommodationFare,
  avgNightlyRate: getAvgNightlyRate,
  portalSettings: getPortalSettings,
  accountCurrency: getAccountCurrency,
  revenueByReservationStatus: getRevenueByReservationStatus,
  initialMonth: getInitialMonth,
});

const CalendarStatsContainer = connect(mapStateToProps)(CalendarStats);

CalendarStatsContainer.propTypes = {
  listing: PropTypes.shape().isRequired,
};

export default CalendarStatsContainer;
