import cn from 'classnames';

/*
size  - sm / md (default) / lg
btnStyle - full (default) / outline / transparent
shape - rectangle (default) / square / circle / pill
color - primary (default) / info / success / warning / danger / secondary
 */
const Button = ({
  size,
  btnStyle,
  shape,
  color,
  onClick,
  isDisabled,
  children,
  className,
  style,
}) => {
  btnStyle = btnStyle === 'transparent' ? 'link' : btnStyle;

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={cn(className, 'btn', `btn-${color || 'primary'}`, {
        [`btn-${size}`]: size,
        [`btn-${btnStyle}`]: btnStyle,
        [`btn-shape-${shape}`]: shape,
      })}
      disabled={isDisabled}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  );
};

export default Button;
