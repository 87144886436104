import { connect } from 'react-redux';

import BookingRequest from 'pages/BookingRequest';
import { isSmallScreen, getCompanyLogo } from 'ui/ui.selectors';

function mapStateToProps(state) {
  return {
    authenticated: state.user.authenticated,
    isSmallScreen: isSmallScreen(state),
    logo: getCompanyLogo(state),
    ownerName: state.user.fullName,
    ownerId: state.user._id,
  };
}

export default connect(mapStateToProps, null)(BookingRequest);
