import React from 'react';
import cn from 'classnames';

import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import { getMonths } from 'utils/common';

const todayYear = new Date().getFullYear();

const preventClick = (e) => {
  e.stopPropagation();
};
export default class MonthPicker extends React.Component {
  constructor(props) {
    super(props);

    const year = props.month
      ? props.month.getFullYear()
      : new Date().getFullYear();

    this.state = {
      year,
    };

    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
  }

  onPreviousClick() {
    this.setState(({ year }) => ({ year: year - 1 }));
  }

  onNextClick() {
    this.setState(({ year }) => ({ year: year + 1 }));
  }

  onChange(month) {
    this.props.onChange(month, this.state.year);
  }

  isCurrMonth(i) {
    return this.props.month && this.props.month.getMonth() === i;
  }

  render() {
    const { year } = this.state;

    return (
      <div
        aria-hidden="true"
        className="p-3 month-picker"
        onClick={preventClick}
      >
        <div className="text-center separator mb-25 pb-25">
          <Button
            btnStyle="transparent"
            shape="circle"
            onClick={this.onPreviousClick}
          >
            <Icon icon="calendar-nav-left" />
          </Button>
          <span className="font-size-h4 font-color-primary align-middle d-inline-block text-center">
            {year}
          </span>
          <Button
            btnStyle="transparent"
            shape="circle"
            onClick={this.onNextClick}
            isDisabled={year === todayYear}
          >
            <Icon icon="calendar-nav-right" />
          </Button>
        </div>
        <div className="row no-gutters">
          {getMonths().map((m, i) => (
            <div
              aria-hidden="true"
              key={m}
              className={cn('text-center col-3 month-popover--month', {
                'month-picker--curr': this.isCurrMonth(i),
              })}
              onClick={() => this.onChange(i)}
            >
              {m}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
