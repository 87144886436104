/* eslint-disable no-dupe-class-members */
import React from 'react';

import t from '@guestyci/localize/t.macro';

import LoginLayout from 'components/login/LoginLayout';
import Button from 'components/common/Button';

export default class PasswordChanged extends React.Component {
  onClick() {
    this.props.changeMode('LOGIN');
  }

  onClick = this.onClick.bind(this);

  render() {
    const { logo } = this.props;

    return (
      <LoginLayout logo={logo} title={t('Password changed')}>
        <Button size="lg" className="w-100" onClick={this.onClick}>
          {t('Back to log in')}
        </Button>
      </LoginLayout>
    );
  }
}
