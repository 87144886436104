import dio, { DioTypes, setIdentity } from 'utils/dio';

export default {
  onLoginSuccess: (user, mode = { userNameAndPassword: true }) => {
    setIdentity(user);
    dio?.track('login_success', DioTypes.SUBMIT, mode);
  },
  onLogout: () => {
    dio?.track('logout', DioTypes.SUBMIT, {});
    setIdentity({});
  },
};
