import { createApi } from '@reduxjs/toolkit/query/react';

import Resource from '@guestyci/agni';

let cachedApi = null;

const dynamicBaseQuery = async (args, api, extraOptions) => {
  if (!cachedApi) {
    cachedApi = Resource.create({
      domain: 'owners',
      development: true,
    }).api;
  }

  return cachedApi(args, api, extraOptions);
};

export const ownersApi = createApi({
  reducerPath: 'ownersApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: ['me-account-brands-display'],
  endpoints: ({ query }) => ({
    getMeAccountBrandToDisplay: query({
      query: () => ({
        url: '/me/account-brands/display',
      }),
      providesTags: ['account-brands-display'],
    }),
  }),
});

export const { useGetMeAccountBrandToDisplayQuery } = ownersApi;
