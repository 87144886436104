import React from 'react';
import get from 'lodash/get';

const styles = {
  root: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: 'opacity 0.8s ease',
  },
};
const STYLES_ENUM = {
  ROOT: 'root',
  SHOW: 'show',
};

const SHOW_DELAY = 100;

function withTransition(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        stylesName: STYLES_ENUM.ROOT,
      };
    }

    componentDidMount() {
      setTimeout(() => {
        this.showPage();
      }, SHOW_DELAY);
    }

    getStyles() {
      return get(styles, this.state.stylesName, STYLES_ENUM.ROOT);
    }

    showPage() {
      this.setState({ stylesName: STYLES_ENUM.SHOW });
    }

    render() {
      return (
        <section style={this.getStyles()}>
          <WrappedComponent {...this.props} />
        </section>
      );
    }
  };
}

export default withTransition;
