import Dio from '@guestyci/dio';
import Resource from '@guestyci/agni';

import NewRelic from 'utils/newrelic';

// Note: Context and event names are tracked in a spreadsheet in coordination with the BI team.
// Do not change this value without coordinating with the BI team and project managers.
// https://docs.google.com/spreadsheets/d/11lIjW5nAe3MqlSqibSvfV5h7HdF8ppqsCqdXUie13ic/edit#gid=740208631
const CONTEXT = 'owner_portal';

export const DioTypes = {
  PAGEVIEW: 'pageview',
  SCREENVIEW: 'screenview',
  SUBMIT: 'submit',
  CLICK: 'click',
  HOVER: 'hover',
  SCROLL: 'scroll',
  SIDE_EFFECT: 'side-effect',
};

export const init = () => {
  try {
    const { config } = Resource.create({
      domain: '/',
      development: true,
    });

    // Note: Initialize the DIO instance to use the "isPublic" flag.
    // This uses a built-in auth token stored in Dio itself, and doesn't require the JWT used by Guesty Pro
    // (because that can be expired/invalid).

    const dio = Dio.init({
      context: CONTEXT,
      baseUrl: config.API_GATEWAY,
      debug: process.env.NODE_ENV === 'development',
      isPublic: true,
      enrichment: {},
    });

    NewRelic.setCustomAttributes();

    return dio;
  } catch (error) {
    console.error(error);
  }
};

// TODO: should it be singleton? (zbo)
const dio = init();

export const setIdentity = (user = {}) => {
  const {
    _id: userId,
    email,
    fullName,
    account: { _id: accountId } = {},
  } = user;

  dio?.identify({
    accountId,
    userId,
    email,
    fullName,
  });

  NewRelic.setCustomAttributes(user);
};

export default dio;
