import { connect } from 'react-redux';

import { showModal, constants } from 'modal';
import RentalPeriod from 'components/RentalPeriod';

import { updateRentalPeriod } from '../property/properties.actions';

const mapStateToProps = (state) => ({
  list: state.property.rentalPeriods.list,
});

const mapDispatchToProps = (dispatch) => ({
  onShowRentalPeriodModal(props) {
    dispatch(showModal(constants.RENTAL_PERIOD, props));
  },
  updateRentalPeriod: ({ listingId, data }) =>
    dispatch(updateRentalPeriod({ listingId, data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RentalPeriod);
