import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'components/common/Icon';
import iconMap from 'icons/iconMap';
import { getActiveString } from 'utils/common';

const ListingRow = ({ listing }) => {
  const img =
    listing.picture && listing.picture.thumbnail ? (
      <div
        className="property-img-inner"
        style={{
          backgroundImage: `url(${listing.picture.thumbnail}), url(${iconMap.placeholder})`,
        }}
      />
    ) : (
      <Icon className="property-img-inner" icon="placeholder" />
    );

  return (
    <div
      className={cn('row no-gutters align-items-center py-35 px-5 flex-nowrap')}
    >
      <div className="col mr-35 property-img">{img}</div>
      <div
        className="col mr-35 property-auto text-truncate property-title"
        title={listing.title}
      >
        {listing.nickname}
      </div>
      <div
        className="col mr-6 property-auto text-truncate"
        title={listing.address.full}
      >
        {listing.address.full}
      </div>
      <div className="col mr-35 property-md">{listing.bedrooms}</div>
      <div className="col mr-35 property-md text-capitalize">
        {getActiveString(listing.active)}
      </div>
      <div className="col text-right property-sm property-icon">
        <Icon icon="arrow" />
      </div>
    </div>
  );
};

ListingRow.propTypes = {
  listing: PropTypes.shape(),
};

export default ListingRow;
