import { useSelector } from 'react-redux';

import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import Icon from '@guestyci/foundation/Icon';
import { IcoApproved, IcoDeclined, IcoNotice } from '@guestyci/icons';
import { green400, red400, yellow400 } from '@guestyci/foundation/theme/colors';
import t from '@guestyci/localize/t.macro';

import {
  getBookingRequestApproved,
  getBookingRequestApprovedByOwner,
  getBookingRequestPendingOwnerApproval,
  getBookingRequestDeclined,
  getBookingRequestExpired,
  selectIsDeclineRequested,
  selectIsActionApplied,
  getBookingRequestDeclinedByTeam,
} from '../view/requestToBook.slice';

import { ReactComponent as Info } from './info.svg';

export const RequestToBookHeader = ({ logo, classes }) => {
  const isDeclineRequested = useSelector(selectIsDeclineRequested);
  const isExpired = useSelector(getBookingRequestExpired);

  const isActionApplied = useSelector(selectIsActionApplied);
  const isPendingOwnerApproval = useSelector(
    getBookingRequestPendingOwnerApproval
  );
  const isApproved = useSelector(getBookingRequestApproved);
  const isDeclined = useSelector(getBookingRequestDeclined);
  const isApprovedByOwner = useSelector(getBookingRequestApprovedByOwner);
  const isDeclinedByTeam = useSelector(getBookingRequestDeclinedByTeam);
  const hasApprove = (isApproved || isApprovedByOwner) && !isDeclinedByTeam;
  const hasDecline = isDeclined || isDeclineRequested;

  const approvedText = isActionApplied
    ? t('Thank you for approving the booking request')
    : t('You already approved this booking request');
  const declinedText = isActionApplied
    ? t('Booking request declined')
    : t('You already declined this booking request');
  const expiredText = isExpired ? t('Booking request expired') : '';
  const approve = isApprovedByOwner && !isDeclinedByTeam ? approvedText : '';
  const decline = isDeclined ? declinedText : '';
  const declineRequested = isDeclineRequested
    ? t('Decline this booking request?')
    : '';

  return (
    <Row className={classes.header} spacing={6} fullWidth justify="center">
      {isPendingOwnerApproval && !isDeclineRequested && !isExpired && (
        <>
          <Col spacing={2} className={classes.newTitle}>
            {logo ? (
              <img src={logo} className="login-logo mb-52" alt="logo" />
            ) : (
              <div style={{ height: 60 }} />
            )}
            <TextField variant="h1" data-testid="text-h1-pending">
              {t('You have a new booking request')}
            </TextField>
          </Col>
          <Col>
            <Info />
          </Col>
        </>
      )}
      <Col spacing={4} align="center">
        {hasApprove && (
          <Icon svg={IcoApproved} width={60} height={60} fillColor={green400} />
        )}
        {hasDecline && (
          <Icon svg={IcoDeclined} width={60} height={60} fillColor={red400} />
        )}
        {isExpired && (
          <Icon svg={IcoNotice} width={60} height={60} fillColor={yellow400} />
        )}
        <TextField variant="h1" data-testid="text-h1">
          {declineRequested || approve || decline || expiredText}
        </TextField>
      </Col>
    </Row>
  );
};
