import { connect } from 'react-redux';

import { getOwnersPortalRentalPeriodSetting } from 'user/user.selectors';

import { fetchRentalPeriod } from '../property/properties.actions';
import RentalPeriodAvailabilityWrapper from '../components/RentalPeriodAvailabilityWrapper';

const mapStateToProps = (state) => ({
  defaultAvailability: state.property?.rentalPeriods?.defaultAvailability || '',
  isRentalPeriodSettingEnabled: getOwnersPortalRentalPeriodSetting(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchRentalPeriod: (listingId) => dispatch(fetchRentalPeriod(listingId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RentalPeriodAvailabilityWrapper);
