import React from 'react';
import { generate } from 'shortid';

const keyframes = (id, radius) => {
  const offset = Math.round(2 * Math.PI * radius);
  return `@keyframes dash-${id} {
    0% { stroke-dashoffset: ${offset}; }
    50% {
      stroke-dashoffset: ${offset / 4};
      transform:rotate(135deg);
    }
    100% {
      stroke-dashoffset: ${offset};
      transform:rotate(450deg);
    }
  }`;
};

export default class Spinner extends React.Component {
  constructor(props) {
    super(props);
    this.size = this.props.size || (this.props.small ? 32 : 66);
    this.radius = (this.size - 6) / 2;
    this.stroke = this.size > 40 ? 6 : this.size > 20 ? 3 : 2;
    this.duration = this.size > 40 ? '1.4s' : this.size > 20 ? '1.7s' : '2s';
    this.id = generate();
  }

  componentDidMount() {
    const style = document.createElement('style');
    style.textContent = keyframes(this.id, this.radius);
    this.styleEl = document.head.appendChild(style);
  }

  componentWillUnmount() {
    if (this.styleEl) {
      document.head.removeChild(this.styleEl);
      delete this.styleEl;
    }
  }

  render() {
    const { color = '#6ac1ff' } = this.props;
    const { size } = this;
    const width = `${size - 1}px`;
    const cx = size / 2;
    const r = this.radius;
    const strokeWidth = this.stroke;
    const offset = Math.round(Math.PI * 2 * r);
    const { duration } = this;

    return (
      <svg
        className="spinner"
        width={width}
        height={width}
        viewBox={`0 0 ${size} ${size}`}
        xmlns="http://www.w3.org/2000/svg"
        style={{ animationDuration: duration }}
      >
        <circle
          className="spinner-circle"
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={`${offset}px`}
          cx={cx}
          cy={cx}
          r={r}
          style={{
            animationName: `dash-${this.id}`,
            animationDuration: duration,
          }}
        />
      </svg>
    );
  }
}
