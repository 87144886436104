import { useSelector } from 'react-redux';

import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import { BtnPayment, BtnProperty, IcoFilterDate } from '@guestyci/icons';
import t from '@guestyci/localize/t.macro';
import createStyles from '@guestyci/foundation/createStyles';
import { darkBlue10 } from '@guestyci/foundation/theme/colors';

import {
  getCheckInDate,
  getCheckOutDate,
  getETATime,
  getETDTime,
  getListingAddress,
  getListingName,
  getListingTitle,
  getOwnerRevenue,
} from '../view/requestToBook.slice';

import { HeadingWithIcon } from './HeadingWithIcon';

const useStyles = createStyles({
  listingInfo: {
    width: '490px',
    borderBottom: `solid 1px ${darkBlue10}`,
    padding: '20px 0',
  },
});

export const ReservationDetails = () => {
  const classes = useStyles();

  const listingName = useSelector(getListingName);
  const listingTitle = useSelector(getListingTitle);
  const listingAddress = useSelector(getListingAddress);
  const ownerRevenue = useSelector(getOwnerRevenue);
  const checkInDate = useSelector(getCheckInDate);
  const checkOutDate = useSelector(getCheckOutDate);
  const etaTime = useSelector(getETATime);
  const etdTime = useSelector(getETDTime);

  return (
    <Col className={classes.listingInfo} spacing={3}>
      <Col spacing={1}>
        <HeadingWithIcon
          icon={BtnProperty}
          text={listingTitle}
          color="dark"
          bold
        />
        <TextField variant="h4" className="pl-4" data-testid="listing-name">
          {listingName}
        </TextField>
        <TextField
          variant="h4"
          bold
          className="pl-4"
          data-testid="listing-address"
        >
          {listingAddress}
        </TextField>
      </Col>

      <Col spacing={1}>
        <HeadingWithIcon icon={IcoFilterDate} text={`${t('Dates')}:`} />
        <Row spacing={1} className="pl-4">
          <Col>
            <TextField variant="h4" color="dark" bold data-testid="date-in">
              {checkInDate} -
            </TextField>
            <TextField variant="h4">{etaTime}</TextField>
          </Col>
          <Col>
            <TextField variant="h4" color="dark" bold data-testid="date-out">
              {checkOutDate}
            </TextField>
            <TextField variant="h4">{etdTime}</TextField>
          </Col>
        </Row>
      </Col>

      <Col spacing={1}>
        <HeadingWithIcon
          icon={BtnPayment}
          text={`${t('Potential owner revenue')}:`}
        />
        <TextField variant="h4" className="pl-4" bold data-testid="revenue">
          {ownerRevenue}
        </TextField>
      </Col>
    </Col>
  );
};
