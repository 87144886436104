import ButtonWithIcon from './ButtonWithIcon';

const ButtonLoading = (props) => {
  const { loading, noCheck, children } = props;
  const spinner = !!loading;
  const icon = loading || noCheck ? null : 'check';
  return (
    <ButtonWithIcon spinner={spinner} icon={icon} {...props}>
      {children}
    </ButtonWithIcon>
  );
};

export default ButtonLoading;
