import { reducer } from 'utils/common';

import { types } from './ui.actions';

const initialState = {
  isMetaLoading: false,
  isMetaLoaded: false,
  isMetaLoadedError: false,
  meta: null,
  errorMessage: null,
  navItems: null,
  winWidth: null,
  initState: '',
  locale: '',
  email: '',
  name: '',
  shouldDisplayLoader: false,
  currentLocale: null,
};

const handlers = {
  [types.SET_CURRENT_LOCALE](state, action) {
    return {
      ...state,
      currentLocale: action.locale,
    };
  },

  [types.UI_ACTION](state, action) {
    return { ...state, ...action.data };
  },

  [types.DIRTY_ALERT](state, action) {
    return { ...state, warnDirty: action.to };
  },

  [types.DIRTY_ANSWER](state, action) {
    return { ...state, warnDirty: null, dirty: !action.reset };
  },

  [types.DISPLAY_LOADER](state, action) {
    return { ...state, shouldDisplayLoader: action.shouldDisplay };
  },

  [types.DISPLAY_NAVBAR](state, action) {
    return { ...state, hasNavbar: action.hasNavbar };
  },

  [types.GET_PORTAL_META_REQUESTED](state) {
    return {
      ...state,
      isMetaLoading: true,
      isMetaLoaded: false,
      isMetaLoadedError: false,
      meta: null,
      errorMessage: null,
    };
  },

  [types.GET_PORTAL_META_SUCCESS](state, { meta }) {
    return {
      ...state,
      isMetaLoading: false,
      isMetaLoaded: true,
      isMetaLoadedError: false,
      meta,
      errorMessage: null,
    };
  },

  [types.GET_PORTAL_META_FAILURE](state, { error }) {
    return {
      ...state,
      isMetaLoading: false,
      isMetaLoaded: true,
      isMetaLoadedError: true,
      meta: null,
      errorMessage: error,
    };
  },
};

export default reducer(handlers, initialState);
