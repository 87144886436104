const setCustomAttributes = (user = {}) => {
  try {
    const {
      _id: userId,
      email,
      firstName,
      lastName,
      fullName,
      account: { _id: accountId, name: accountName } = {},
    } = user;

    const {
      newrelic,
      location: { hostname },
    } = window;

    if (!newrelic) {
      throw new Error('New Relic is not defined');
    }

    newrelic.setCustomAttribute('PortalURL', hostname);

    if (accountId) {
      newrelic.setCustomAttribute('Account', accountId);
    }

    if (accountName) {
      newrelic.setCustomAttribute('AccountName', accountName);
    }

    if (userId) {
      newrelic.setCustomAttribute('UserId', userId);
    }

    if (email) {
      newrelic.setCustomAttribute('Email', email);
    }

    if (firstName) {
      newrelic.setCustomAttribute('FirstName', firstName);
    }

    if (lastName) {
      newrelic.setCustomAttribute('LastName', lastName);
    }

    if (fullName) {
      newrelic.setCustomAttribute('FullName', fullName);
    }
  } catch (e) {
    console.error('Error setting New Relic custom attributes', e);
  }
};

export default { setCustomAttributes };
