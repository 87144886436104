import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';

import CancelReservation from 'modal/components/CancelReservation';
import { getUser } from 'user/user.selectors';

const mapStateToProps = (state) => ({
  isFetchingCalendars: state.property.isFetchingCalendars,
  owner: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  openReservationModal() {
    dispatch(ActionCreators.undo());
  },
  cancelUpdate() {
    dispatch(ActionCreators.jump(-2));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelReservation);
