import React from 'react';
import PropTypes from 'prop-types';

import { USE_RENTAL_PERIOD_REQUEST_TO_BOOK } from '../constants/featureToggle';

import Spinner from './common/Spinner';

class RentalPeriodAvailabilityWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true, // Fetching by default is enabled
    };
  }

  componentDidMount() {
    const { listingId, fetchRentalPeriod } = this.props;

    fetchRentalPeriod(listingId).finally(() => {
      this.setState({ isFetching: false });
    });
  }

  render() {
    const { isFetching } = this.state;
    const {
      showSpinner,
      children,
      defaultAvailability,
      isRentalPeriodSettingEnabled,
    } = this.props;

    const isAvailabilityBlocked = defaultAvailability === 'BLOCKED';
    const isFtEnabled = window?.featureToggle.isEnabled(
      USE_RENTAL_PERIOD_REQUEST_TO_BOOK
    );

    const isRentalPeriodEnabled =
      isAvailabilityBlocked && isFtEnabled && isRentalPeriodSettingEnabled;

    if (isFetching && showSpinner) {
      return <Spinner />;
    }

    return children({
      isRentalPeriodEnabled,
    });
  }
}

RentalPeriodAvailabilityWrapper.propTypes = {
  listingId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired,
  fetchRentalPeriod: PropTypes.func.isRequired,
  defaultAvailability: PropTypes.string.isRequired,
  isRentalPeriodSettingEnabled: PropTypes.bool.isRequired,
};

export default RentalPeriodAvailabilityWrapper;
