/* global $ */
import React from 'react';
import cn from 'classnames';

import Tooltip from '@guestyci/foundation/Tooltip';
import { BtnHelp } from '@guestyci/icons';

function onFocus(e) {
  $(e.target).closest('.form-field').find('label').addClass('active');
}

function __onBlur(onBlur) {
  return (e) => {
    if (!e.target.value) {
      $(e.target).closest('.form-field').find('label').removeClass('active');
    }
    if (onBlur) onBlur(e);
  };
}

const FormField = ({
  id,
  label,
  className,
  required,
  placeholder,
  multiline,
  onChange,
  onBlur,
  value,
  isLarge = true,
  rows,
  maxLength,
  inputType,
  readonly,
  info,
  error,
  children,
}) => {
  const valueComp = React.createElement(multiline ? 'textarea' : 'input', {
    id,
    defaultValue: value,
    placeholder,
    onChange,
    required,
    className: cn('form-control', { 'form-control-lg': isLarge }),
    onFocus,
    onBlur: __onBlur(onBlur),
    rows,
    maxLength,
    type: inputType,
    readOnly: readonly,
  });

  const isError = typeof error === 'string';

  return (
    <div
      className={cn('form-field', className, 'form-group', {
        'has-danger': isError,
      })}
    >
      {valueComp}
      {children}
      {info && (
        <Tooltip body={info} placement="bottom">
          <BtnHelp
            fillOpacity="0.4"
            height={16}
            width={16}
            className="ml-1 input-info-icon"
          />
        </Tooltip>
      )}
      {isError && <div className="form-field-error">{error}</div>}
      <label className={cn({ active: !!value })} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default FormField;
