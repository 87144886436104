import React from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import { toMomentObject } from 'react-dates';
import PropTypes from 'prop-types';

import Switch from '@guestyci/foundation/Switch';
import StatusIndication from '@guestyci/foundation/StatusIndication';
import t from '@guestyci/localize/t.macro';

import DatePicker from 'components/DatePicker';
import { formatDate, computeBlockedDates } from 'utils/dates';

import 'modal/styles/RentalPeriod.scss';

class CreateRentalPeriod extends React.Component {
  constructor(props) {
    super(props);

    const filteredDates = (props.modalProps?.rentalPeriods || []).filter(
      (period) => period._id !== props.modalProps.rentalPeriodId
    );

    const initialFrom = props.modalProps.from
      ? toMomentObject(props.modalProps.from)
      : null;

    const initialTo = props.modalProps.to
      ? toMomentObject(props.modalProps.to)
      : null;

    this.state = {
      focusedInput: null,
      requestToBook: Boolean(props.modalProps.requestToBook),
      from: initialFrom,
      to: initialTo,
      blockedDates: computeBlockedDates(filteredDates),
      isBlockedDatesInRange: false,
    };
  }

  changeFocusedInput = (focusedInput) => {
    this.setState({ focusedInput });
  };

  toggle = () => {
    this.setState((state) => ({
      ...state,
      requestToBook: !state.requestToBook,
    }));
  };

  close = ({ startDate, endDate }) => {
    this.setState((state) => ({
      ...state,
      from: formatDate(startDate, 'YYYY-MM-DD'),
      to: formatDate(endDate, 'YYYY-MM-DD'),
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { from, to, requestToBook } = this.state;
    const { modalProps, close } = this.props;
    const { rentalPeriodId, add, update, _localGenerated } = modalProps;

    if (rentalPeriodId) {
      update({ from, to, requestToBook, _id: rentalPeriodId, _localGenerated });
    } else {
      add({ from, to, requestToBook, _localGenerated: true });
    }

    close();
  };

  hasErrorHandler = (blockedDatesLength) => {
    this.setState({ isBlockedDatesInRange: Boolean(blockedDatesLength) });
  };

  render() {
    const {
      focusedInput,
      requestToBook,
      from,
      to,
      blockedDates,
      isBlockedDatesInRange,
    } = this.state;
    const { isOpen, close, modalProps } = this.props;
    const { rentalPeriodId, disabled } = modalProps;

    return (
      <Modal
        isOpen={isOpen}
        className={cn('rental-period-modal main')}
        overlayClassName="modal-overlay main"
        ariaHideApp={false}
      >
        <div className="row align-items-center justify-content-between pl-4 m-0 header">
          <span className="header-label">{t('Rental periods')}</span>
          <button
            onClick={close}
            className="px-3 close-button"
            type="button"
            aria-label="Close"
          >
            <span>&times;</span>
          </button>
        </div>

        <form className="container px-3" onSubmit={this.onSubmit}>
          <div className="justify-content-center flex-column d-flex my-3">
            <div className="d-flex">
              <span className="d-block input-container">{t('From')}</span>
              <span className="d-block input-container mr-0">{t('To')}</span>
            </div>
            <DatePicker
              hasError={this.hasErrorHandler}
              preloadNextMonth={() => {}}
              preloadInitialMonth={() => {}}
              startDate={from}
              endDate={to}
              onClose={this.close}
              focusedInput={focusedInput} // we have to pass focusedInput since we are using it in selectors
              onFocusChange={this.changeFocusedInput}
              blockedDays={blockedDates}
              disabled={disabled}
            />
          </div>

          {isBlockedDatesInRange && (
            <StatusIndication
              variant="warning"
              text={t(
                "We can't create rental period for selected dates, as one or more days are already available. Please choose another date range without available dates."
              )}
            />
          )}

          <div className="my-3">
            <Switch checked={requestToBook} onChange={this.toggle}>
              {t('Booking requests only')}
            </Switch>
          </div>

          <div className="justify-content-center my-3">
            <button
              type="submit"
              className="rental-period-button"
              disabled={isBlockedDatesInRange}
            >
              <span className="text-uppercase">
                {rentalPeriodId
                  ? t('Update rental period')
                  : t('Create rental period')}
              </span>
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

CreateRentalPeriod.propTypes = {
  modalProps: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
    requestToBook: PropTypes.bool,
    rentalPeriodId: PropTypes.string,
    disabled: PropTypes.bool,
    _localGenerated: PropTypes.bool,
    add: PropTypes.func,
    update: PropTypes.func,
    rentalPeriods: PropTypes.oneOfType([
      PropTypes.shape({
        from: PropTypes.instanceOf(Date),
        to: PropTypes.instanceOf(Date),
        requestToBook: PropTypes.bool,
        _localGenerated: PropTypes.bool,
      }),
      PropTypes.any,
    ]),
  }).isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default CreateRentalPeriod;
