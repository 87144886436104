import { createApi } from '@reduxjs/toolkit/query/react';

import Resource from '@guestyci/agni';

let cachedApi = null;

const dynamicBaseQuery = async (args, api, extraOptions) => {
  if (!cachedApi) {
    cachedApi = Resource.create({
      domain: 'reservations-fegw',
      development: true,
    }).api;
  }

  return cachedApi(args, api, extraOptions);
};

export const api = createApi({
  baseQuery: dynamicBaseQuery,
  tagTypes: ['RequestToBook'],
  endpoints: ({ query, mutation }) => ({
    getRequestToBook: query({
      query: (reservationId) =>
        `/reservations/booking-request/${reservationId}`,
      providesTags: ['RequestToBook'],
    }),
    approveRequestToBook: mutation({
      query: ({ reservationId, ownerId, ownerName }) => ({
        url: '/request-to-book/owner-approve',
        method: 'POST',
        data: { reservationId, owner: { ownerId, ownerName } },
      }),
      invalidatesTags: ['RequestToBook'],
    }),
    declineRequestToBook: mutation({
      query: ({ reservationId, ownerId, ownerName }) => ({
        url: '/request-to-book/owner-decline',
        method: 'POST',
        data: { reservationId, owner: { ownerId, ownerName } },
      }),
      invalidatesTags: ['RequestToBook'],
    }),
  }),
});

export const {
  useGetRequestToBookQuery,
  useApproveRequestToBookMutation,
  useDeclineRequestToBookMutation,
} = api;
