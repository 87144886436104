import { Col, Row, Section } from '@guestyci/foundation/Layout';
import Tooltip from '@guestyci/foundation/Tooltip';
import TextField from '@guestyci/foundation/TextField';
import Icon from '@guestyci/foundation/Icon';
import { ReactComponent as IcoAddress } from '@guestyci/icons/IcoAddress.svg';
import { ReactComponent as BtnCabs } from '@guestyci/icons/BtnCabs.svg';
import t from '@guestyci/localize/t.macro/t.macro';

import { useAppSelector } from 'store';
import { getCompanyLogo } from 'ui/ui.selectors';
import { truncateWithEllipsis } from 'utils/common';
import iconMap from 'icons/iconMap';
import ConditionalWrapper from 'components/ConditionalWrapper';
import { useMediaQuery } from 'hooks/useMediaQuery';

const NavBarLogo = ({ accountBrand }) => {
  const accountLogoUrl = useAppSelector((state) => getCompanyLogo(state));

  const { isXsScreen, isLgScreen } = useMediaQuery();

  let maxWidth = '250px';

  if (isXsScreen) {
    maxWidth = '80px';
  } else if (!isLgScreen) {
    maxWidth = '160px';
  }

  const logoUrl =
    accountBrand.logoUrl || accountLogoUrl || iconMap['guesty-logo-gray'];

  const body = (
    <Section justify="fill-content">
      <Col spacing={3} fullWidth>
        <TextField>{accountBrand.name || '-'}</TextField>
        {accountBrand.address?.full && (
          <Row align="center" spacing={2}>
            <Icon svg={IcoAddress} />
            <TextField overflowEllipsis>
              {truncateWithEllipsis(accountBrand.address.full, 45)}
            </TextField>
          </Row>
        )}
        {accountBrand.vatNum && (
          <Row align="center" spacing={2}>
            <Icon svg={BtnCabs} />
            <TextField>{`${t('VAT number')}: ${accountBrand.vatNum}`}</TextField>
          </Row>
        )}
      </Col>
    </Section>
  );

  return (
    <Row align="center" justify="start">
      <ConditionalWrapper
        condition={!isXsScreen}
        // eslint-disable-next-line react/no-unstable-nested-components
        wrapper={(children) => (
          <Tooltip body={body} placement="bottom">
            {children}
          </Tooltip>
        )}
      >
        <img
          src={logoUrl}
          alt=""
          style={{
            height: 'auto',
            width: 'auto',
            maxWidth,
            maxHeight: '34px',
          }}
        />
      </ConditionalWrapper>
    </Row>
  );
};

export default NavBarLogo;
