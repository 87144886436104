import values from 'lodash/values';
import get from 'lodash/get';
import moment from 'moment/moment';
import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';

import { getFilter } from 'property/property.utils';

import { getYearsFrom2014 } from '../utils/common';

export const showPieChart = (state) => {
  if (
    !state.property.analytics ||
    !get(state, ['user', 'ownersPortalSettings', 'revenue'])
  )
    return false;

  return getYearsFrom2014().some((year) => {
    const yearData = state.property.analytics[year];
    const pieValues = yearData && values(yearData.revenueByProperty);

    return pieValues && pieValues.length > 1;
  });
};

export const allYearsLoaded = (state) => {
  if (!state.property.analytics) return false;

  return getYearsFrom2014().every((year) => {
    const yearData = state.property.analytics[year];
    return yearData && !yearData.isFetching;
  });
};

/**
 * @returns {Object} calendar of currently selected property
 */
const getCurrentCalendar = (state, props) =>
  state.property.results[props.listingId]?.calendar || {};

const getCurrentFocusedInput = (state, props) => props.focusedInput;

/**
 * @returns {Object} of range [from...to]
 */
const getUpdateFromTo = (state, props) => ({
  from: props.updateFrom,
  to: props.updateTo,
});

/**
 * 1) Get days that are from to be updated range
 * 2) return them as available
 * @returns {Array} of days in range [from...to] from blocked days
 */
const getDaysToBeUpdated = createSelector(
  [getCurrentCalendar, getUpdateFromTo],
  (currentCalendar, { from, to }) =>
    flatten(values(currentCalendar).map(values))
      .filter(
        (day) =>
          from &&
          to &&
          moment(day.date).isBetween(moment(from), moment(to), 'day', '[]')
      )
      .map((day) => ({ ...day, status: 'available' }))
);

/**
 * Replaces blocked days, with the same available days
 * This is required to be able to update reservation
 * @returns {Array}
 */
export const getDaysWithToBeUpdated = createSelector(
  [getCurrentCalendar, getDaysToBeUpdated],
  (currentCalendar, daysToBeUpdated) =>
    flatten(values(currentCalendar).map(values)).map(
      (day) =>
        daysToBeUpdated.find((dayToUpdate) =>
          moment(day.date).isSame(moment(dayToUpdate.date), 'day')
        ) || day
    )
);

/**
 * @returns {Array} of days that should be blocked
 */
export const getBlockedDays = createSelector(
  [getDaysWithToBeUpdated, getCurrentFocusedInput],
  (currentCalendarToBeUpdated, currentFocusedInput) => {
    const filter = getFilter(currentCalendarToBeUpdated, currentFocusedInput);

    return currentCalendarToBeUpdated
      .filter(filter)
      .map((day) => ({ ...day, moment: moment(day.date) }));
  }
);

export const getListings = (state) => values(state.property.results);
const getActiveListings = (state) => state.property.activeListings;

export const getActiveListingIndex = createSelector(
  [getListings, getActiveListings],
  (listings, activeListings) =>
    activeListings
      ? listings
          .map((l, i) => activeListings.indexOf(l._id) > -1 && i)
          .filter((x) => x !== false)
      : []
);

export const getRentalPeriods = (state) => state.property.rentalPeriods;
