import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import { Row } from '@guestyci/foundation/Layout';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import DropdownLabelInput from '@guestyci/foundation/Dropdown/DropdownLabelInput';
import t from '@guestyci/localize/t.macro';

import { ScreenSize, useMediaQuery } from 'hooks/useMediaQuery';
import { useAppSelector } from 'store';

const useStyles = createStyles((theme) => ({
  link: {
    position: 'relative',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.primary,
    height: 54,

    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  },
  active: {
    fontWeight: 'bold',
    color: '#1449FF',
    textDecoration: 'none',
    '&:hover': {
      color: '#1449FF',
    },
  },
  underlined: {
    '&::after': {
      position: 'absolute',
      content: '""',
      bottom: -1,
      right: 0,
      left: 0,
      background: '#1449FF',
      height: 6,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
  },
  dropdownInput: {
    '& > div': {
      color: theme.palette.text.primary,
    },
    height: 54,
    width: 50,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropdownInputActive: {
    '& > div': {
      color: '#1449FF',
    },
  },
  dropdownOption: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.backgroundLight} !important`,
    },
  },
}));

const MenuLink = ({ item, isUnderlined = true, onNavLinkClick }) => {
  const classes = useStyles();

  return (
    <NavLink
      to={item.href}
      className={classes.link}
      activeClassName={cn([
        classes.link,
        classes.active,
        isUnderlined && classes.underlined,
      ])}
      onClick={(e) => onNavLinkClick(e, item.href)}
    >
      {item.getName()}
    </NavLink>
  );
};

const NavBarMenu = ({ onNavLinkClick }) => {
  const classes = useStyles();
  const location = useLocation();

  const navItems = useAppSelector((state) => state.ui.navItems);
  const { screenSize } = useMediaQuery();

  if ([ScreenSize.XS, ScreenSize.SM, ScreenSize.MD].includes(screenSize)) {
    const splitAt =
      // eslint-disable-next-line no-nested-ternary
      screenSize === ScreenSize.MD ? 4 : screenSize === ScreenSize.SM ? 2 : 1;
    const visible = navItems.slice(0, splitAt);
    const rest = navItems.slice(splitAt);

    const isActiveInRest = rest.some(
      (x) => x.href === location.pathname.slice(1)
    );

    return (
      <Row spacing={4}>
        {visible.map((item) => (
          <MenuLink
            key={item.href}
            item={item}
            onNavLinkClick={onNavLinkClick}
          />
        ))}

        {rest.length > 0 && (
          <Dropdown
            input={
              <DropdownLabelInput
                className={cn([
                  classes.dropdownInput,
                  classes.link,
                  ...(isActiveInRest
                    ? [
                        classes.active,
                        classes.dropdownInputActive,
                        classes.underlined,
                      ]
                    : []),
                ])}
              />
            }
            value={t('More')}
            onChange={() => {}}
            className={
              isActiveInRest
                ? cn([classes.link, classes.active, classes.underlined])
                : ''
            }
          >
            {rest.map((item) => (
              <Option
                value={item.href}
                key={item.href}
                className={classes.dropdownOption}
              >
                <MenuLink
                  item={item}
                  isUnderlined={false}
                  onNavLinkClick={onNavLinkClick}
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                />
              </Option>
            ))}
          </Dropdown>
        )}
      </Row>
    );
  }

  return (
    <Row spacing={4}>
      {navItems.map((item) => (
        <MenuLink key={item.href} item={item} onNavLinkClick={onNavLinkClick} />
      ))}
    </Row>
  );
};

export default NavBarMenu;
