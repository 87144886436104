export default {
  en: 'US',
  bg: 'BG',
  zh: 'CN',
  cs: 'CZ',
  nl: 'NL',
  fr: 'FR',
  de: 'DE',
  it: 'IT',
  ja: 'JP',
  pl: 'PL',
  pt: 'PT',
  es: 'ES',
};
