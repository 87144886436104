import { connect } from 'react-redux';

import MyProperties from 'pages/MyProperties';
import {
  getCalendarFromTo,
  getCalendarMonthYear,
  addActiveListing,
  removeActiveListing,
  viewCalendarAction,
  getListings,
  getListingMiniData,
} from 'property/properties.actions';
import { isMediumScreen, isSmallScreen } from 'ui/ui.selectors';
import {
  getListings as getListingsSelector,
  getActiveListingIndex,
} from 'property/property.selectors';
import { getOwnersPortalAccommodationFareSetting } from 'user/user.selectors';

function mapStateToProps(state) {
  return {
    authenticated: state.user.authenticated,
    listings: getListingsSelector(state),
    activeListingIndex: getActiveListingIndex(state),
    isFetching: state.property.isFetching,
    listingsCount: state.property.count,
    isMediumScreen: isMediumScreen(state),
    isSmallScreen: isSmallScreen(state),
    ownersPortalAccommodationFareEnabled:
      getOwnersPortalAccommodationFareSetting(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addActiveListing: (listingId) => dispatch(addActiveListing(listingId)),
    getCalendarMonthYear: (listingId, month, year, options) =>
      dispatch(getCalendarMonthYear(listingId, month, year, options)),
    getCalendarFromTo: (listingId, from, to, options) =>
      dispatch(getCalendarFromTo(listingId, from, to, options)),
    getListings: (data) => dispatch(getListings(data)),
    getMiniData: (listingId, month, year) =>
      dispatch(getListingMiniData(listingId, month, year)),
    removeActiveListing: (listingId) =>
      dispatch(removeActiveListing(listingId)),
    setViewCal: (listingId, month, year) =>
      dispatch(viewCalendarAction(listingId, month, year)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProperties);
