import { connect } from 'react-redux';

import Reservation from 'modal/components/Reservation';
import {
  select,
  getCalendarFromTo,
  getCalendarMonthYear,
  getListingMiniData,
  addReservation,
  updateReservation,
  cancelReservation,
  updateCalendar,
} from 'property/properties.actions';
import { getUser } from 'user/user.selectors';
import { userUpdatedAction } from 'user/user.actions';

const mapDispatchToProps = (dispatch) => ({
  select(props) {
    dispatch(select(props));
  },
  resetSelection() {
    dispatch(select({ from: null, to: null }));
  },
  addReservation(props) {
    dispatch(addReservation(props));
  },
  updateReservation(props) {
    dispatch(updateReservation(props));
  },
  cancelReservation(props) {
    dispatch(cancelReservation(props));
  },
  updateCalendarInStore(props) {
    dispatch(updateCalendar(props));
  },
  getCalendarMonthYear: (listingId, month, year, options) =>
    dispatch(getCalendarMonthYear(listingId, month, year, options)),
  getCalendarFromTo: (listingId, from, to, options) =>
    dispatch(getCalendarFromTo(listingId, from, to, options)),
  getMiniData: (listingId, month, year) =>
    dispatch(getListingMiniData(listingId, month, year)),
  updateOwnerInStore: (owner) => dispatch(userUpdatedAction(owner)),
});

const mapStateToProps = (state) => {
  const {
    property: {
      results,
      activeListings: [listingId],
      selection: { from, to } = {},
    },
  } = state;
  return {
    // uses 'undefined' to handle 'null' in defaultProps
    owner: getUser(state),
    defaultCheckOutTime: results[listingId].defaultCheckOutTime || undefined,
    defaultCheckInTime: results[listingId].defaultCheckInTime || undefined,
    listing: results[listingId],
    listingId,
    from,
    to,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservation);
