import React from 'react';
import { DateUtils } from 'react-day-picker';
import PropTypes from 'prop-types';

import { toISODateString } from 'utils/common';
import { hasAnyDateFromRange, parseISO } from 'utils/calendar';
import tracker from 'utils/dio/my-properties-tracker';
import { get } from 'api/http-client';

const withReservation = (WrappedComponent) => {
  return class extends React.Component {
    // eslint-disable-next-line class-methods-use-this
    isSelectable = ({ available, past }) => available && !past;

    handleDayMouseDown = (day, modifiers) => {
      if (this.isSelectable(modifiers)) {
        this.props.select({ from: day, to: null, isSelecting: true });
      }
    };

    handleDayMouseEnter = (day) => {
      const { from, isSelecting, disabledDays } = this.props;
      if (isSelecting && from) {
        if (!hasAnyDateFromRange(disabledDays, day, from)) {
          this.props.select({ to: this._getSelectionEndDate(from, day) });
        } else {
          this.props.select({ to: null });
        }
      }
    };

    handleDayMouseUp = (day) => {
      if (this.props.isSelecting) {
        const { disabledDays, from, listing, isOwnerReservationV2 } =
          this.props;

        if (!hasAnyDateFromRange(disabledDays, day, from)) {
          // discard selection if there are disabled days in range
          const before = DateUtils.isDayBefore(day, from);
          if (before) {
            // reverse from and to dates if selection is backward
            this.props.select({ from: day, to: from, isSelecting: false });
          } else {
            this.props.select({
              to: this._getSelectionEndDate(from, day),
              isSelecting: false,
            });
          }
          tracker.onAddReservation(listing._id);
          this.props.openReservationModal({ listing, isOwnerReservationV2 });
        } else {
          this.props.select({ to: null, from: null, isSelecting: false });
        }
      }
    };

    openUpdateModal = (day) => {
      const { calendar } = this.props;
      const calObj = calendar[toISODateString(day)];
      const reservationBlockRef = calObj.blockRefs.find(
        (blockRef) =>
          (calObj.reservationId &&
            blockRef.reservation?._id === calObj.reservationId) ||
          (calObj.ownerReservationId &&
            blockRef.reservation?._id === calObj.ownerReservationId)
      );

      const { numberOfAdults, numberOfInfants, numberOfChildren } =
        reservationBlockRef?.reservation?.numberOfGuests || {};

      const isOwnerReservation = reservationBlockRef?.type === 'o';
      const isOwnerReservationV2 =
        isOwnerReservation &&
        reservationBlockRef?.reservation?.ownerResV2 === true;

      if (isOwnerReservation) {
        const { listing, openReservationModal } = this.props;
        const {
          reservation: { checkIn, checkOut, source },
          reservationId,
        } = reservationBlockRef;

        const note =
          calObj.ownersReservation?.note ||
          reservationBlockRef.reservation?.note ||
          reservationBlockRef.reservation?.notes?.other ||
          '';

        if (isOwnerReservationV2 && source === 'owner-guest') {
          get(`/v2/reservations/${reservationId}`)
            .then((reservation) => {
              openReservationModal({
                from: parseISO(checkIn),
                to: parseISO(checkOut),
                numberOfAdults,
                numberOfInfants,
                numberOfChildren,
                note,
                source,
                update: true,
                ownerReservationId: reservationId,
                guest: reservation.guest || {},
                listing,
                isOwnerReservationV2,
              });
            })
            .catch(() => {});
        } else {
          openReservationModal({
            from: parseISO(checkIn),
            to: parseISO(checkOut),
            numberOfAdults,
            numberOfInfants,
            numberOfChildren,
            note,
            source,
            update: true,
            ownerReservationId: reservationId,
            listing,
            isOwnerReservationV2,
          });
        }
      }
    };

    // eslint-disable-next-line class-methods-use-this
    _getSelectionEndDate = (selectionStart, current) => {
      const before = DateUtils.isDayBefore(current, selectionStart);

      if (DateUtils.isSameDay(selectionStart, current) || before) {
        return current;
      }
      const date = new Date(current);
      return new Date(date.setDate(date.getDate() - 1));
    };

    render() {
      const { disabledDays, select } = this.props;
      return (
        <WrappedComponent
          {...this.props}
          withReservation={{
            onDayMouseDown: this.handleDayMouseDown,
            onDayMouseEnter: this.handleDayMouseEnter,
            onDayMouseUp: this.handleDayMouseUp,
            disabledDays,
            onDayClick: this.openUpdateModal,
            select,
          }}
        />
      );
    }
  };
};

withReservation.propTypes = {
  openReservationModal: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  disabledDays: PropTypes.arrayOf(),
  isSelecting: PropTypes.bool,
};

export default withReservation;
