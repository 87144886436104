import { reducer } from 'utils/common';
import { types as uiTypes } from 'ui/ui.actions';

import { types } from './user.actions';

const handlers = {
  [types.USER](state, action) {
    const authenticated = action.user.authenticated !== false;
    return {
      ...state,
      ...action.user,
      authenticated,
      loginError: action.user.loginError,
    };
  },

  [types.PASSWORD_SENT](state, action) {
    return { ...state, passwordSent: !!action.err };
  },

  [types.PASSWORD_RESET](state, action) {
    return { ...state, passwordReset: !!action.err };
  },

  [types.LOGOUT]() {
    // TODO action.err ?
    return { authenticated: false };
  },

  [types.USER_UPDATED](state, action) {
    return { ...state, ...action.user, updated: true };
  },

  [uiTypes.UI_ACTION](state, action) {
    if (action.data.dirty) {
      return {
        ...state,
        updated: false,
      };
    }

    return state;
  },

  [uiTypes.NAVIGATION](state) {
    return { ...state, updated: false };
  },
};

export default reducer(handlers);
