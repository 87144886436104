export const USE_ACCOUNTING_FLOW = 'use-accounting-flow';
export const SHOW_NET_RENTAL_INCOME = 'OP-display-NRI-for-accounting-users';
export const USE_RENTAL_PERIOD_REQUEST_TO_BOOK =
  'DT-rental-period-request-to-book';
export const USE_NEW_OWNER_RESERVATIONS =
  'OP-owner-reservations-to-actual-reservations';
export const USE_NEW_MY_PROPERTIES_PAGE = 'OP-new-my-properties-page';
export const USE_RESERVATION_DRAWER = 'OWN-use-reservation-drawer';
export const SHOW_HELP_CENTER = 'OWN-show-help-center';
export const OWN_HYDRA_AUTH = 'OWN-hydra-auth';
